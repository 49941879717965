import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import TournamentFlowChartExportModal from './tournaments-export.modal'
import ActiveTournaments from './active-tournaments'
import UpcomingTournaments from './upcoming-tournaments'
import ArchivedTournaments from './archived-tournaments'
import { IPageProps } from '../../interfaces/page-data'
import { connect } from 'react-redux'
import UpcomingMakeDrawModal from './tournaments-upcoming-make-draw.modal'
import TournamentsUpcomingEditModal from './tournaments-upcoming-edit.modal'
import MakeDrawNextStageModal from './tournaments-running-draw-next-stage.modal'
import TournamentParticipantsModal from './tournament-participants.modal'
import TournamentRankingModal from './tournament-ranking.modal'
import DEMOAddParticipantsModal from './DEMO-add-participants.modal'

export interface ITournamentIndexProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: ITournamentIndexProps) => {

  return <>
    <Switch>
      {/* global modal catches */}
      <Route key="rEdit" exact path={`/app/tournaments/:type/:id/export`}
             render={() => (<TournamentFlowChartExportModal {...props}  />)}/>
      <Route key="uMakeDraw" exact path={`/app/tournaments/:type/:id/participants`}
             render={() => (<TournamentParticipantsModal {...props}  />)}/>
      <Route key="aRanking" exact path={`/app/tournaments/:type/:id/ranking`}
             render={() => (<TournamentRankingModal {...props}/>)}/>

      {/* running tournaments modals */}
      <Route key="rNextStageDraw" exact path={`/app/tournaments/running/:id/draw-next-stage`}
             render={() => (<MakeDrawNextStageModal {...props}  />)}/>
      {/*
      <Route key='rEdit' exact path={`/app/tournaments/running/:id/edit`}
             render={() => (<TournamentsRunningEditModal {...props}  />)}/>
      */}

      {/* upcoming tournaments modals */}
      <Route key="uCreate" exact path={`/app/tournaments/upcoming/create`}
             render={() => (<TournamentsUpcomingEditModal {...props}  />)}/>
      <Route key="uEdit" exact path={`/app/tournaments/upcoming/:id/edit`}
             render={() => (<TournamentsUpcomingEditModal {...props}  />)}/>
      <Route key="uMakeDraw" exact path={`/app/tournaments/upcoming/:id/draw`}
             render={() => (<UpcomingMakeDrawModal {...props}  />)}/>
{/*
      <Route key="uAddParticipantsDemo" exact path={`/app/tournaments/upcoming/:id/demo-add-participants`}
             render={() => (<DEMOAddParticipantsModal {...props}  />)}/>
*/}

      {/* archived tournaments modals */}
    </Switch>
    <Switch>
      <Route key="basePage" path={`/app/tournaments/running`} render={() => (<ActiveTournaments {...props}  />)}/>
      <Route key="basePage" path={`/app/tournaments/upcoming`} render={() => (<UpcomingTournaments {...props}  />)}/>
      <Route key="basePage" path={`/app/tournaments/archive`} render={() => (<ArchivedTournaments {...props}  />)}/>
    </Switch>
  </>
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
