import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { history } from './redux/store'

import './index.scss'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'

import { bindActionCreators } from 'redux'
import { clearAuthentication } from './shared/reducers/authentication'
import setupAxiosInterceptors from './redux/settings/axios-interceptor'
import initStore from './redux/store'

import { ConfigProvider } from 'antd'
import enGB from 'antd/lib/locale/en_GB';
import moment from 'moment';
import 'moment/locale/en-gb';  // important!
moment.locale('en-gb');  // important!

const store = initStore()

const actions = bindActionCreators({ clearAuthentication }, store.dispatch)
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'))

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConfigProvider locale={enGB}>
      <App/>
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can changes
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
