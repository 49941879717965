import React, { useEffect } from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { Route } from 'react-router'
import { connect } from 'react-redux'

import InvoiceUpdate from './invoice-update'
import InvoicesList from './invoices-list'

export interface IInvoicesIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IInvoicesIndexProps) => {

  const { match } = props

  return <Switch>
    <Route key="iNew" path={`${match.url}/new`} render={() => <InvoiceUpdate {...props} />}/>
    <Route key="iUpdate" path={`${match.url}/:id`} render={() => <InvoiceUpdate {...props} />}/>
    <Route key="iList" path={`${match.url}`} render={() => <InvoicesList {...props} />}/>
  </Switch>
}

export default withRouter(connect()(Routes))