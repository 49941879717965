import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IVenue } from '../../model/venue.model'

export const ACTION_TYPES = {
  SEARCH_VENUES: 'venue/SEARCH_VENUES',
  FETCH_VENUE_LIST: 'venue/FETCH_VENUE_LIST',
  FETCH_VENUE: 'venue/FETCH_VENUE',
  CREATE_VENUE: 'venue/CREATE_VENUE',
  UPDATE_VENUE: 'venue/UPDATE_VENUE',
  DELETE_VENUE: 'venue/DELETE_VENUE',
  SET_BLOB: 'venue/SET_BLOB',
  RESET: 'venue/RESET',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVenue>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type VenueState = Readonly<typeof initialState>;

// Reducer

export default (state: VenueState = initialState, action): VenueState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_VENUES):
    case REQUEST(ACTION_TYPES.FETCH_VENUE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VENUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.CREATE_VENUE):
    case REQUEST(ACTION_TYPES.UPDATE_VENUE):
    case REQUEST(ACTION_TYPES.DELETE_VENUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.SEARCH_VENUES):
    case FAILURE(ACTION_TYPES.FETCH_VENUE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VENUE):
    case FAILURE(ACTION_TYPES.CREATE_VENUE):
    case FAILURE(ACTION_TYPES.UPDATE_VENUE):
    case FAILURE(ACTION_TYPES.DELETE_VENUE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.SEARCH_VENUES):
    case SUCCESS(ACTION_TYPES.FETCH_VENUE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_VENUE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.CREATE_VENUE):
    case SUCCESS(ACTION_TYPES.UPDATE_VENUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.DELETE_VENUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      }
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      }
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
};

const apiUrl = 'api/venues'
const apiSearchUrl = 'api/_search/venues'

// Actions

export const getSearchEntities: ICrudSearchAction<IVenue> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_VENUES,
  payload: axios.get<IVenue>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
})

export const getEntities: ICrudGetAllAction<IVenue> = (page, size, sort, filters?: {}) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  if (filters) {
    if (filters['venueName']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}name.contains=${filters['venueName']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_VENUE_LIST,
    payload: axios.get<IVenue>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<IVenue> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_VENUE,
    payload: axios.get<IVenue>(requestUrl),
  }
}

export const createEntity: ICrudPutAction<IVenue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VENUE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}

export const updateEntity: ICrudPutAction<IVenue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VENUE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  return result
}

export const deleteEntity: ICrudDeleteAction<IVenue> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VENUE,
    payload: axios.delete(requestUrl),
  })
  dispatch(getEntities())
  return result
}

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
})

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})
