import { Moment } from 'moment';
import { WarningColor } from './enumerations/warning-color.model';

export interface IParticipantWarning {
  id?: number;
  warningDate?: string;
  warningColor?: WarningColor;
  text?: any;
  participantId?: number;
}

export const defaultValue: Readonly<IParticipantWarning> = {};
