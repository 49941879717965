import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { connect } from 'react-redux'

import { history, IAppState } from '../../../redux/store'
import { IPaginationBaseState } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'

import { Button, Checkbox, Col, Input, Row, Space, Table } from 'antd'
import { EditTwoTone, SearchOutlined } from '@ant-design/icons'
import { EventStatus } from '../../../shared/model/enumerations/event-status.model'
import { getEntities, getSearchEntities } from '../../../shared/reducers/model/sponsor.reducer'
import { ISponsor } from '../../../shared/model/sponsor.model'

export interface ISponsorsListProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const SponsorsList = (props: ISponsorsListProps) => {

  const { match, loading, onSetPage, entities } = props

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'name',
    order: 'asc',
  })
  const [tableFilters, setTableFilters] = useState({ eventStatus: [EventStatus.ACTIVE, EventStatus.CREATED, EventStatus.RUNNING] })

  const pageData: IPageData = {
    title: 'Sponsors',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/'
      },
      {
        title: 'Management',
      },
      {
        title: 'Sponsors',
      },
    ],
  }

  useEffect(() => {
    onSetPage(pageData)
    fetchSponsors()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage(pageData)
    fetchSponsors()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    fetchSponsors()
  }, [paginationState, tableFilters])

  const fetchSponsors = () => {
    // console.log('load sponsors', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters(filters)
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Sponsor</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table<ISponsor> id='sponsorsTable' rowKey='id' loading={loading}
                         size='small'
                         onChange={handleTableChanges}
                         showSorterTooltip
                         dataSource={entities.flat()}
                         pagination={{
                           defaultPageSize: paginationState.itemsPerPage,
                           total: props.totalItems,
                           pageSize: paginationState.itemsPerPage,
                         }}
        >
          <Table.Column<ISponsor> key='sponsorName' dataIndex='name' title='Sponsor-Name'
                                  {...getColumnSearchProps('sponsorName')}
          />
          <Table.Column<ISponsor> key='sponsorLemma' dataIndex='lemma' title='Sponsor-Lemma'
                                  {...getColumnSearchProps('sponsorLemma')}
          />
          <Table.Column<ISponsor> key='sponsorCountry' dataIndex='countryName' title='Country'/>
          <Table.Column<ISponsor> key='sponsorTelephone' dataIndex='telephone' title='Telephone'/>
          <Table.Column<ISponsor> key='sponsorWebsite' dataIndex='website' title='Website'/>
          <Table.Column<ISponsor> key='sponsorEmail' dataIndex='email' title='Email'/>
          <Table.Column<ISponsor> key='sponsorMainSponsor' dataIndex='mainSponsor' title='Main-Sponsor'
                                  width='100px'
                                  align='center'
                                  render={(value) => <Checkbox disabled checked={value === true}/>}
          />
          <Table.Column<ISponsor> key='sponsorPremiumSponsor' dataIndex='premiumSponsor' title='Premium-Sponsor'
                                  width='100px'
                                  align='center'
                                  render={(value) => <Checkbox disabled checked={value === true}/>}
          />
          <Table.Column<ISponsor> key='sponsorActions' dataIndex='id' title=''
                                  width='100px'
                                  align='right'
                                  render={(value) => <Space>
                                    <EditTwoTone onClick={() => history.push(`${match.url}/${value}`)}/>
                                  </Space>}
          />
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ sponsor }: IAppState) => ({
  entities: sponsor.entities,
  loading: sponsor.loading,
  totalItems: sponsor.totalItems,
})

const mapDispatchToProps = {
  getEntities,
  getSearchEntities,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SponsorsList))