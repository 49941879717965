import { IEvent } from '../../shared/model/event.model'
import { Col, Form, Input, message, Modal, Row, Select, Switch } from 'antd'
import { IAppState } from '../../redux/store'
import { getEntities as loadSponsor } from '../../shared/reducers/model/sponsor.reducer'
import {
  createEntity,
  getEntity as loadSponsorForEvent,
  updateEntity,
  reset
} from '../../shared/reducers/model/sponsor-for-event.reducer'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { ISponsorForEvent } from '../../shared/model/sponsor-for-event.model'
import { SponsorLevel } from '../../shared/model/enumerations/sponsor-level.model'

export interface IEventSponsorsAddProps extends StateProps, DispatchProps {
  visible: boolean,
  event: IEvent,
  onClose: any,
  updateId: number
}

const EventSponsorsAddModal: React.FC<IEventSponsorsAddProps> = (props: IEventSponsorsAddProps) => {

  const [form] = Form.useForm()
  const { event, visible, onClose, entity, updateId, updateSuccess } = props
  const [isSubmitting, setSubmitting] = useState(false)

  const getSponsors = () => {
    // @ts-ignore
    props.loadSponsor(0, 999, 'lemma', event?.id)
  }

  useEffect(() => {
    if (typeof updateId === 'number' && updateId > 0)
      props.loadSponsorForEvent(updateId)
  }, [updateId])

  useEffect(() => {
    if (visible && event && event.id)
      getSponsors()
  }, [visible, event])

  useEffect(() => {
    if (entity && entity.id == updateId)
      form.setFieldsValue(entity)
  }, [props.entity])

  useEffect(() => {
    if (updateSuccess) {
      message.success('Sponsor for event successful saved')
      props.onClose()
    }
  }, [updateSuccess])

  const [availableSponsors, setAvailableSponsors] = useState([])
  useEffect(() => {
    if (!props.sponsorsLoading) {
      setAvailableSponsors(props.sponsors.map(sponsor => ({
        value: sponsor.id,
        label: sponsor.lemma,
      })))
    }
  }, [props.sponsorsLoading])

  const handleSaveSponsorForEventSubmit = () => {
    form.validateFields()
      .then(values => {
        setSubmitting(true)
        values = {
          ...entity,
          ...values,
        }
        if (updateId === entity.id) {
          // console.log('updating sponsor for event')
          props.updateEntity(values)
        } else {
          // console.log('create sponsor for event')
          props.createEntity(values)
        }
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
      })
    setTimeout(() => {
      setSubmitting(false)
    })
  }

  return <Modal visible={visible}
                width={700}
                title={`Add Sponsor to Event: ${event?.name}`}
                destroyOnClose
                onCancel={onClose}
                okText={'Save Sponsor Settings'}
                onOk={handleSaveSponsorForEventSubmit}
                okButtonProps={{ disabled: isSubmitting }}
  >
    <Form<ISponsorForEvent> form={form} id='addSponsorToEvent' layout={'horizontal'} autoComplete={'off'}
                            labelCol={{ span: 16 }} wrapperCol={{ span: 8 }}>
      <Form.Item name={'eventId'} hidden initialValue={event?.id}>
        <Input/>
      </Form.Item>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item<ISponsorForEvent> name='etmWebsite' label={'Show on ET-Men Website'} valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
          <Form.Item<ISponsorForEvent> name='etwWebsite' label={'Show on ET-Women Website'} valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
          <Form.Item<ISponsorForEvent> name='ecWebsite' label={'Show on EC Website'} valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<ISponsorForEvent> name='invitation' label={'Show on Invitation'} valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
          <Form.Item<ISponsorForEvent> name='eventBanner' label={'Show on Event-Banner'} valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
          <Form.Item<ISponsorForEvent> name='flowchart' label={'Show on Flowcharts'} valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item<ISponsorForEvent> name='sponsorLevel' label={'Sponsoring Level'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select>
              {Object.keys(SponsorLevel).map(level => (
                <Select.Option key={`SL${level}`} value={level.toString()}>{level.toString()}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item<ISponsorForEvent> name='sponsorId' label={'Sponsor'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp='label'
              loading={props.sponsorsLoading}
              options={availableSponsors}
            />
          </Form.Item>
          <Form.Item<ISponsorForEvent> name='localSponsor' label={'Local event sponsor'} valuePropName={'checked'} labelCol={{span: 8}} wrapperCol={{ span: 16 }}>
            <Switch/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Modal>
}

const mapStateToProps = ({ sponsor, sponsorForEvent }: IAppState) => ({
  sponsors: sponsor.entities,
  sponsorsLoading: sponsor.loading,
  sponsorsTotal: sponsor.totalItems,
  entity: sponsorForEvent.entity,
  updateSuccess: sponsorForEvent.updateSuccess,
})

const mapDispatchToProps = {
  loadSponsor,
  createEntity,
  updateEntity,
  reset,
  loadSponsorForEvent,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorsAddModal)
