import { IChartTemplateTimeSessionMatches } from './chart-template-time-session-matches.model';

export interface IChartTemplateTimeSession {
  id?: number;
  name?: string;
  numberOfParticipants?: number;
  numberOfTables?: number;
  sessionStart?: string;
  sessionDuration?: number;
  matches?: IChartTemplateTimeSessionMatches[];
}

export const defaultValue: Readonly<IChartTemplateTimeSession> = {};
