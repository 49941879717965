import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../../redux/store'
import { Form, Modal, Table } from 'antd'
import { getEntities } from '../../../shared/reducers/model/match-progress-entry.reducer'

import { TextFormat } from 'react-jhipster'
import { APP_LOCAL_DATETIME_WITH_SECONDS_FORMAT_EU } from '../../../redux/settings/constants'

import './match-history.scss'

export interface IMatchHistoryModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

const ShowHistoryModal: React.FC<IMatchHistoryModalProps> = (props: IMatchHistoryModalProps) => {

  const [visible, showModal] = useState(false)
  const [submitLoading, setLoadingSubmit] = useState(false)

  const matchId = Number(props.match.params.id)
  const matchType = props.match.params.type
  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form

  useEffect(() => {
    // console.log('default effect', matchId, props.loading)
    if (matchId && !props.loading) {
      // @ts-ignore
      props.getEntities(0, 99, 'entryDate', { matchId: matchId })
    }
    /*
            return () => {
                showModal(false)
            }
    */
  }, [])

  useEffect(() => {
    // console.log('loading effect', props.loading)
    if (!props.loading && props.entities) {
      showModal(true)
    }
  }, [props.loading])

  const handleOnClose = () => {
    showModal(false)
    props.history.push(`/app/matches/${matchType}`)
  }

  return <Modal
    width={900}
    title={`History for match ${matchId}`} // ${match?.label}
    visible={visible}
    destroyOnClose={true}
    okButtonProps={{ disabled: true, hidden: true }}
    onCancel={handleOnClose}
    cancelText='Close'
  >
    <Table id='historyTable' key='historyTable' dataSource={props.entities.flat()} pagination={false} size={'small'}
           rowKey={'id'}>
      <Table.Column key={'historyDateKeyd'} dataIndex={'entryDate'} title={'Date'} render={(value) => (
        <TextFormat type={'date'} value={value} format={APP_LOCAL_DATETIME_WITH_SECONDS_FORMAT_EU}/>
      )}
      />
      <Table.Column key={'aScoreKey'} dataIndex={'scoreA'} title={'Player A Score'}/>
      <Table.Column key={'bScoreKey'} dataIndex={'scoreB'} title={'Player B Score'}/>
      <Table.Column key={'progressTypeKey'} dataIndex={'progressType'} title={'Type'}/>
      <Table.Column key={'targetKey'} dataIndex={'target'} title={'Target'}/>
      <Table.Column key={'scoreChangeKey'} dataIndex={'scoreChange'} title={'Score Change'}/>
    </Table>
  </Modal>
}

const mapStateToProps = ({ matchProgressEntry }: IAppState) => ({
  entities: matchProgressEntry.entities,
  loading: matchProgressEntry.loading,
})

const mapDispatchToProps = {
  getEntities,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowHistoryModal))
