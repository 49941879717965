import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IPageData, IPageProps } from '../../interfaces/page-data'
import { connect } from 'react-redux'

import { IAppState } from '../../redux/store'
import { getEntities, getSearchEntities } from '../../shared/reducers/model/event.reducer'
import { getEntities as getSeasons } from '../../shared/reducers/model/season.reducer'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../utils/pagination.constants'
import { Button, Col, Input, Row, Space, Table } from 'antd'
import { IEvent } from '../../shared/model/event.model'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../redux/settings/constants'
import { EventStatus } from '../../shared/model/enumerations/event-status.model'
import { SearchOutlined } from '@ant-design/icons'

export interface IEventsArchiveProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const EventsArchive = (props: IEventsArchiveProps) => {

  const { match, loading, onSetPage, entities } = props

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'eventStartDate',
    order: 'desc',
  })
  const [tableFilters, setTableFilters] = useState({ eventStatus: [EventStatus.APPROVED, EventStatus.CANCELED] })
  const [seasonFilters, setSeasonFilter] = useState([])

  const eventStatusFilters = Object.keys(EventStatus).map(key => ({
    text: key,
    value: key,
  }))

  const pageData: IPageData = {
    title: 'Events - Archive',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Events',
        route: '/app/events/active',
      },
      {
        title: 'Archive',
      },
    ],
  }

  useEffect(() => {
    onSetPage(pageData)
    loadSeasonFilters()
    fetchEvents()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage(pageData)
    loadSeasonFilters()
    fetchEvents()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    fetchEvents()
  }, [paginationState, tableFilters])

  useEffect(() => {
    if (!props.seasonsLoading && props.seasons && props.seasons.length > 0)
      setSeasonFilter(props.seasons.map(season => ({
        text: season.name,
        value: season.id,
      })))
  }, [props.seasonsLoading])

  const fetchEvents = () => {
    // console.log('load events', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const loadSeasonFilters = () => {
    props.getSeasons(0, 99, 'seasonYear,desc')
  }

  let searchInput

  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
{/*
      <Col span={24}>
        <Link to={`${match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Event</Button>
        </Link>
      </Col>
*/}
      <Col span={24}>
        <Table<IEvent> id='eventsTable' rowKey='id' loading={loading}
                       size='small'
                       onChange={handleTableChanges}
                       showSorterTooltip
                       dataSource={entities.flat()}
                       pagination={{
                         defaultPageSize: paginationState.itemsPerPage,
                         total: props.totalItems,
                         pageSize: paginationState.itemsPerPage,
                       }}
        >
          <Table.Column<IEvent> key='eventName' dataIndex='name' title='Event-Name'
                                {...getColumnSearchProps('eventName')}
          />
          <Table.Column<IEvent> key='eventShortName' dataIndex='shortName' title='Short-Name'/>
          <Table.Column<IEvent> key='eventStatus' dataIndex='eventStatus' title='Status'
                                width='150px'
            /*
                                            filters={eventStatusFilters}
                                            defaultFilteredValue={[EventStatus.ACTIVE, EventStatus.CREATED, EventStatus.RUNNING]}
            */
          />
          <Table.Column<IEvent> key='eventStartDate' dataIndex='eventStartDate' title='Start-Date'
                                sorter defaultSortOrder='descend'
                                width='150px'
                                render={(value) => <TextFormat value={value} type={'date'}
                                                               format={APP_LOCAL_DATE_FORMAT_EU}/>}
          />
          <Table.Column<IEvent> key='eventRegistrationStartDate' dataIndex='registrationStartDate'
                                title='Registration-Start'
                                width='150px'
                                render={(value) => <TextFormat value={value} type={'date'}
                                                               format={APP_LOCAL_DATE_FORMAT_EU}/>}
          />
          <Table.Column<IEvent> key='eventSeasonName' dataIndex='seasonId' title='Season'
                                filters={seasonFilters}
                                render={(value, row) => row.seasonName}
          />
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ event, season }: IAppState) => ({
  entities: event.entities,
  loading: event.loading,
  totalItems: event.totalItems,
  seasons: season.entities,
  seasonsLoading: season.loading,
})

const mapDispatchToProps = {
  getEntities,
  getSearchEntities,
  getSeasons,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsArchive))