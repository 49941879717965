import { Button, Form, Input, message, Modal, Upload } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { IAppState } from '../../redux/store'
import { getEntity, setBlob, deleteEntity, reset, createEntity, updateEntity } from '../../shared/reducers/model/event-poster.reducer'
import { connect } from 'react-redux'
import './event-poster.upload.scss'

export interface IEventPosterUploadModalProps extends StateProps, DispatchProps {
  visible: boolean,
  eventPosterId: number
  onOk: Function,
  onCancel: Function,
}

const EventPosterUploadModal: React.FC<IEventPosterUploadModalProps> = (props: IEventPosterUploadModalProps) => {

  const [submitLoading, setLoadingSubmit] = useState(false)

  const [form] = Form.useForm()

/*
  const handleDeletePoster = (e) => {
    e.stopPropagation()
    props.deleteEntity(props.entity.id)
    reset()
  }
*/

  const handleOnCancel = () => {
    props.reset()
    if (props.onCancel)
      props.onCancel()
  }

  const handleOnOk = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...props.entity,
          ...values,
        }
        if (props.entity.id) {
          props.updateEntity(entityToSubmit)
        } else {
          props.createEntity(entityToSubmit)
        }
        /*
                setTimeout(() => {
                  setLoadingSubmit(false)
                  if (props.onOk)
                    props.onOk(props.entity.id)
                }, 250)
        */
      })
    setTimeout(() => {
      setLoadingSubmit(false)
    }, 250)
  }

  const clearBlob = name => () => {
    props.setBlob(name, undefined, undefined)
  }

  const beforeUpload = (file) => {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg'
    if (!isImage) {
      message.error('You can only upload JPG/PNG/SVG file!')
      return
    }
    const isFileSizeOk = file.size / 1024 < 512
    if (!isFileSizeOk) {
      message.error('Image must smaller then 512 kB!')
      return
    }

    const fileReader: FileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = e => {
      // @ts-ignore
      const base64Data = e.target['result'].substr(e.target['result'].indexOf('base64,') + 'base64,'.length)
      props.setBlob('poster', base64Data, file.type)
    }
    return false
  }

  useEffect(() => {
    if (props.updateSuccess) {
      setLoadingSubmit(false)
      if (props.onOk)
        props.onOk(props.entity.id)
    }
  }, [props.updateSuccess])

  useEffect(() => {
    if (props.visible && props.eventPosterId)
      props.getEntity(props.eventPosterId)
    if (!props.visible)
      props.reset()
  }, [props.visible])

  return <Modal visible={props.visible} width={600} bodyStyle={{ height: 370 }} title={'Event Poster'} destroyOnClose
                onCancel={handleOnCancel} onOk={handleOnOk}
                okText={'Upload'} okButtonProps={{ loading: submitLoading }}
  >
    <Form form={form} id={'uploadEventPoster'} layout={'vertical'}>
      <Form.Item label={'Poster'} valuePropName={'file'}>
        <Upload
          listType="picture-card"
          className="poster-uploader"
          showUploadList={false}
          accept="image/*"
          multiple={false}
          beforeUpload={(file) => beforeUpload(file)}
        >
          {props.entity.poster ? (
            <>
              {/*<Button shape="circle" icon={<DeleteOutlined/>} className="remove-poster-btn" onClick={handleDeletePoster}/>*/}
              <img src={`data:${props.entity.posterContentType};base64,${props.entity.poster}`} alt="poster" style={{ maxWidth: 300, maxHeight: 300 }}/>
            </>
          ) : (
            <div>
              <PlusOutlined/>
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
    </Form>
  </Modal>
}

const mapStateToProps = ({ eventPoster: { entity, loading, updating, updateSuccess } }: IAppState) => ({
  entity, loading, updating, updateSuccess,
})

const mapDispatchToProps = {
  getEntity,
  deleteEntity,
  createEntity,
  updateEntity,
  setBlob,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(EventPosterUploadModal)
