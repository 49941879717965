import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../../redux/store'
import {
  Form,
  Input,
  Modal, Select,
  Typography,
} from 'antd'

import { getEntity, reset } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'

import './match-history.scss'
import axios from 'axios'

export interface IMatchEditModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const ShowEditMatchModal: React.FC<IMatchEditModalProps> = (props: IMatchEditModalProps) => {

  const matchId = Number(props.match.params.id)

  const [visible, showModal] = useState(false)

  const [submitLoading, setLoadingSubmit] = useState(false)

  const [assignTableForm] = Form.useForm()

  useEffect(() => {
    // console.log('default effect', matchId, props.tournamentMatchLoading)
    if (matchId && !props.tournamentMatchLoading)
      props.getEntity(matchId)
  }, [])

  useEffect(() => {
    // console.log('loading effect', props.tournamentMatchLoading)
    if (!props.tournamentMatchLoading && props.tournamentMatch && props.tournamentMatch.id) {
      getFreeBilliardTables()
    }
  }, [props.tournamentMatchLoading, props.tournamentMatch])

  const [billiardTableOptions, setBilliardTableOptions] = useState([])

  const getFreeBilliardTables = async () => {
    await axios
      .get(`/api/management/upcoming-matches/free-tables/${props.tournamentMatch.tournamentId}`)
      .then(({ data }) => {
        setBilliardTableOptions(data.map(table => ({
          label: table.name,
          value: table.id,
        })))
      })
  }

  useEffect(() => {
    if (billiardTableOptions)
      if (billiardTableOptions.length > 0) {
        assignTableForm.setFieldsValue({
          matchId: props.tournamentMatch.id,
          tableId: billiardTableOptions[0].value,
        })
        showModal(true)
      } else if (props.tournamentMatch.id) {
        Modal.info({ content: 'No free tables available' })
      }
  }, [billiardTableOptions])

  const onSubmit = () => {
    setLoadingSubmit(true)
    setTimeout(() => setLoadingSubmit(false), 1000)
    assignTableForm.validateFields()
      .then(values => {
        axios
          .post(`/api/management/upcoming-matches/assign-table`, null, {
            params: {
              matchId: Number(values.matchId),
              tableId: Number(values.tableId),
            },
          })
          .then(() => {
              setLoadingSubmit(false)
              showModal(false)
              props.history.goBack()
            },
          )
        props.reset()
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.goBack()
    props.reset()
  }

  const onReset = () => {
    assignTableForm.resetFields()
  }

  return <Modal title={`Assign table to match ${props.tournamentMatch?.label}`}
                forceRender
                visible={visible}
                closable={false}
                width={500}
                okText="Assign"
                okType="primary"
                onOk={onSubmit}
                onCancel={onCancel}
                confirmLoading={submitLoading}
                afterClose={() => {
                  assignTableForm.resetFields()
                }}
  >
    <Form form={assignTableForm} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} layout="horizontal">
      <Form.Item hidden={true} name="matchId">
        <Input type={'hidden'}/>
      </Form.Item>
      <Typography.Paragraph className={'text-center text-nowrap'} style={{ fontSize: 16, marginTop: 12 }}>
        <Typography.Text strong>
          {props.tournamentMatch ? `${props.tournamentMatch.participantADisplayName} - ${props.tournamentMatch.participantBDisplayName}` : ''}
        </Typography.Text>
      </Typography.Paragraph>
      <Form.Item label="Table" name="tableId" required={true}
                 extra={'Only free tables are displayed'}
      >
        <Select defaultActiveFirstOption={true}
                notFoundContent={'no free tables'}
                optionFilterProp="label"
                options={billiardTableOptions}
        />
      </Form.Item>
    </Form>
  </Modal>
}

const mapStateToProps = ({ tournamentMatchExtended }: IAppState) => ({
  tournamentMatch: tournamentMatchExtended.entity,
  tournamentMatchLoading: tournamentMatchExtended.loading,
})

const mapDispatchToProps = {
  getEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowEditMatchModal))
