import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../../interfaces/page-data'
import { IAppState } from '../../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getEntities as getWebsiteContentAreas } from '../../../../shared/reducers/model/website-content-area.reducer'
import { getEntities as getTags } from '../../../../shared/reducers/model/tag.reducer'
import {
  createEntity,
  getEntity,
  reset,
  setBlob,
  updateEntity,
} from '../../../../shared/reducers/model/website-content.reducer'

import { Button, Checkbox, Col, DatePicker, Form, Input, message, Row, Select, Space } from 'antd'
import moment from 'moment'
import ReactQuill from 'react-quill'

export interface IWebsiteContentUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const WebsiteContentUpdate = (props: IWebsiteContentUpdateProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)

  const { websiteContentEntity, onSetPage, getPageData, account } = props
  const [availableWebsiteContentAreas, setAvailableWebsiteContentAreas] = useState([])
  const [availableTags, setAvailableTags] = useState([])
  const [submitLoading, setLoadingSubmit] = useState(false)

  const pageData: IPageData = {
    title: `Website Content - ${!isNew ? websiteContentEntity.title : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/'
      },
      {
        title: 'Website',
        route: 'management/website'
      },
      {
        title: 'Website Content',
        route: 'management/website/content',
      },
      {
        title: !isNew ? websiteContentEntity.title : 'Create new Website Content',
      },
    ],
  }

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    props.getWebsiteContentAreas(0, 99, 'name')
    props.getTags(0, 999, 'name')
    onSetPage(pageData)
    return () => {
      props.reset()
    }
  }, [])

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  useEffect(() => {
    if (!props.loading && !isNew) {
      let formValues = {
        ...websiteContentEntity,
        publicationDate: moment(websiteContentEntity.publicationDate, 'YYYY-MM-DD'),
      }
      setFieldsValue(formValues)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/app/public/website/content')
    }
  }, [props.updateSuccess])

  useEffect(() => {
    if (!props.websiteContentAreasLoading) {
      setAvailableWebsiteContentAreas(props.websiteContentAreas.map(area => ({
        value: area.id,
        label: area.name,
      })))
    }

  }, [props.websiteContentAreasLoading])

  useEffect(() => {
    if (!props.tagsLoading) {
      setAvailableTags(props.tags.map(tag => ({
        value: tag.id,
        label: tag.name,
      })))
    }

  }, [props.tagsLoading])

  const onReset = () => {
    if (!isNew)
      setFieldsValue(websiteContentEntity)
    else
      form.resetFields()
  }

  const onTextChange = (value) => {
    setFieldsValue({ text: value })
  }

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entity = {
          ...websiteContentEntity,
          ...values,
          publicationDate: values.publicationDate.format('YYYY-MM-DD'),
        }
        if (isNew) {
          props.createEntity(entity)
        } else {
          props.updateEntity(entity)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  return (
    <Form form={form} id={'updateWebsiteContent'} layout={'horizontal'} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name='publicationDate' label='Date' labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                     initialValue={moment()}
                     rules={[{ required: true, message: 'Please enter a date' }]}
          >
            <DatePicker showToday/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='published' label='Published' valuePropName='checked'
                     help='This Entity is only visible, if published is checked'>
            <Checkbox/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='areaId' label='Website Area'
                     rules={[{
                       required: true,
                       message: 'Please select an area where the content should be displayed',
                     }]}
          >
            <Select
              showSearch
              placeholder="Select a Website Area"
              optionFilterProp='label'
              loading={props.websiteContentAreasLoading}
              options={availableWebsiteContentAreas}
              filterOption={(input, option) =>
                option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='title' label='Title'
                     rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='text' hidden>
            <Input type='text'/>
          </Form.Item>
          <Form.Item name='text' label='Main Article' rules={[{ required: true, message: 'Main Article is required' }]}>
            <ReactQuill id='textEditor' theme={'snow'} value={websiteContentEntity?.text}
                        onChange={onTextChange} modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'link'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'blockquote'],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'align': [] }],
                ['image']
              ],
            }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='tags' label='Tags'>
            <Select
              mode="multiple"
              showSearch
              allowClear
              optionFilterProp='label'
              loading={props.tagsLoading}
              options={availableTags}
              filterOption={(input, option) =>
                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
{/*
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save and new
            </Button>
*/}
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Link to={'../content'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )

}

const mapStateToProps = (storeState: IAppState) => ({
  websiteContentAreas: storeState.websiteContentArea.entities,
  websiteContentAreasLoading: storeState.websiteContentArea.loading,
  tags: storeState.tag.entities,
  tagsLoading: storeState.tag.loading,
  websiteContentEntity: storeState.websiteContent.entity,
  loading: storeState.websiteContent.loading,
  updating: storeState.websiteContent.updating,
  updateSuccess: storeState.websiteContent.updateSuccess,
  account: storeState.authentication.account,
})

const mapDispatchToProps = {
  getWebsiteContentAreas,
  getTags,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteContentUpdate))
