import { IBoardMemberType } from './board-member-type.model';

export interface IBoardMember {
  id?: number;
  firstName?: string;
  lastName?: string;
  position?: string;
  street?: string;
  postcode?: string;
  city?: string;
  state?: string;
  telephone?: string;
  telefax?: string;
  mobile?: string;
  website?: string;
  email?: string;
  countryName?: string;
  countryId?: number;
  memberTypes?: IBoardMemberType[];
}

export const defaultValue: Readonly<IBoardMember> = {};
