import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IPageProps } from '../../../interfaces/page-data'
import UsersList from './user-management'
import UserEdit from './user-management-update'

export interface IUserManagementProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IUserManagementProps) => {
  return <>
    <Switch>
      <Route key={'editUser'} path={`${props.match.url}/new`} exact render={() => <UserEdit {...props}/>}/>
      <Route key={'editUser'} path={`${props.match.url}/:login`} exact render={() => <UserEdit {...props}/>}/>
      <Route key='listUsers' path={`${props.match.url}`} render={() => <UsersList {...props}/>}/>
    </Switch>
  </>
}

export default withRouter(connect()(Routes))