import { WarningColor } from './enumerations/warning-color.model';

export interface IWarningTemplates {
  id?: number;
  name?: string;
  text?: any;
  color?: WarningColor;
}

export const defaultValue: Readonly<IWarningTemplates> = {};
