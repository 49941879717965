import { Moment } from 'moment';
import { IInvoiceItem } from './invoice-item.model';
import { InvoiceStatus } from './enumerations/invoice-status.model';
import { InvoicePaymentType } from './enumerations/invoice-payment-type.model';

export interface IInvoice {
  id?: number;
  invoiceNumber?: string;
  status?: InvoiceStatus;
  paymentType?: InvoicePaymentType;
  paymentNotice?: string;
  paymentTransferNumber?: string;
  paymentCancelReason?: string;
  paymentLastModificationDate?: string;
  total?: number;
  creationDate?: string;
  purpose?: string;
  items?: IInvoiceItem[];
  participantId?: number;
  playerProfileDisplayName?: string;
  playerProfileId?: number;
}

export const defaultValue: Readonly<IInvoice> = {};
