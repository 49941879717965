export interface IPlayerOverallRanking {
  id?: number;
  place?: number;
  overallPoints?: number;
  averagePlace?: number;
  strikeResult?: number;
  tournament01Points?: number;
  tournament01Place?: number;
  tournament02Points?: number;
  tournament02Place?: number;
  tournament03Points?: number;
  tournament03Place?: number;
  tournament04Points?: number;
  tournament04Place?: number;
  tournament05Points?: number;
  tournament05Place?: number;
  tournament06Points?: number;
  tournament06Place?: number;
  tournament07Points?: number;
  tournament07Place?: number;
  tournament08Points?: number;
  tournament08Place?: number;
  tournament09Points?: number;
  tournament09Place?: number;
  tournament10Points?: number;
  tournament10Place?: number;
  playerProfileDisplayName?: string;
  playerProfileId?: number;
  overallRankingId?: number;
}

export const defaultValue: Readonly<IPlayerOverallRanking> = {};
