import axios from 'axios'
import { ICrudGetAllExtendedAction } from '../../../../utils/table-utils'

import { IChartTemplateTimeSession } from '../../../model/chart-template-time-session.model'
import { ACTION_TYPES, apiUrl } from '../chart-template-time-session.reducer'
import { IParticipant } from '../../../model/participant.model'
import { ICrudGetAllAction } from 'react-jhipster'

export const getPossibleTimeTables: ICrudGetAllExtendedAction<IChartTemplateTimeSession> = (filters: {}) => {
  let requestUrl = `${apiUrl}?extended=false&enabled.equals=true&sort=numberOfParticipants,numberOfTables,sessionDuration,asc&page=0&size=50`
  // console.log(filters)
  if (filters) {
    if (filters['numberOfParticipants'] !== undefined && filters['numberOfPlaceholders'] !== undefined) {
      const sum = filters['numberOfParticipants'] + filters['numberOfPlaceholders']
      requestUrl += `&numberOfParticipants.greaterThanOrEqual=${sum}`
    }
    if (filters['numberOfTables']) {
      requestUrl += `&numberOfTables.greaterThanOrEqual=${filters['numberOfTables']}`
    }
    if (filters['eventType']) {
      requestUrl += `&eventType.equals=${filters['eventType']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_CHARTTEMPLATETIMESESSION_LIST,
    payload: axios.get<IChartTemplateTimeSession>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntities: ICrudGetAllAction<IChartTemplateTimeSession> = (page, size, sort, filters?: {}) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  if (filters) {
    if (filters['name']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}name.contains=${filters['name']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_CHARTTEMPLATETIMESESSION_LIST,
    payload: axios.get<IChartTemplateTimeSession>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}
