import axios from 'axios'
import { MNG_API_URL, WEB_API_URL } from '../redux/settings/constants'

export const webApiClient = axios.create({
  baseURL: WEB_API_URL,
})

export const mngApiClient = axios.create({
  baseURL: MNG_API_URL,
})
