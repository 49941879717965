import {
  SET_SETTINGS,
  RESET_SETTINGS,
  UPDATE_SETTINGS,
  TOGGLE_SIDEBAR,
} from './types'

import { IAppSettings } from '../../interfaces/settings'
import { SETTINGS } from './DefaultSettings'

const initialState: IAppSettings = SETTINGS

export type SettingsReducer = Readonly<typeof initialState>;

export default (state: SettingsReducer = initialState, action): SettingsReducer => {
  switch (action.type) {
    case SET_SETTINGS:
      return { ...action.payload }
    case UPDATE_SETTINGS:
      return { ...state, ...action.payload }
    case RESET_SETTINGS:
      return { ...initialState }
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarOpened: !state.sidebarOpened }
    default:
      return { ...state }
  }
};
