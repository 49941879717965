import { ICalendarItem } from './calendar-item.model';

export interface IDiscipline {
  id?: number;
  name?: string;
  mnemonic?: string;
  enabled?: boolean;
  rules?: any;
  calendarItems?: ICalendarItem[];
}

export const defaultValue: Readonly<IDiscipline> = {
  enabled: false,
};
