import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../../redux/store'
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Input, Modal, Radio, Row, Select, Space, Tooltip } from 'antd'
import axios from 'axios'

import { getEntity, reset, updateEntity } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'
import { ITournamentMatchExtended } from '../../../shared/model/extended/tournament-match.extended.model'

import './match-history.scss'
import moment from 'moment'
import { defaultValue } from '../../../shared/model/extended/tournament.extended.model'
import { IParticipant } from '../../../shared/model/participant.model'

export interface IMatchEditModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

const ShowEditMatchModal: React.FC<IMatchEditModalProps> = (props: IMatchEditModalProps) => {

  const matchId = Number(props.match.params.id)
  const matchType = props.match.params.type

  const [visible, showModal] = useState(false)

  const [submitLoading, setLoadingSubmit] = useState(false)
  const [playerModificationNeedsConfirmation, setPlayerModificationNeedsConfirmation] = useState(false)
  const [matchCanApproved, setMatchCanApproved] = useState(false)
  const [matchCanTimeout, setMatchCanTimeout] = useState(false)
  const [aTimeoutOvertime, setATimeoutOvertime] = useState(false)
  const [bTimeoutOvertime, setBTimeoutOvertime] = useState(false)
  const [nextToBreakRequired, setNextToBreakRequired] = useState(false)
  const [teamPlayersA, setTeamPlayersA] = useState([])
  const [teamPlayersB, setTeamPlayersB] = useState([])

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const validateMatchStatus = () => {
    let fieldsValue = form.getFieldsValue(['matchStatus', 'aTimeoutUntil', 'bTimeoutUntil', 'aScore', 'bScore'])
    let canTimeout = fieldsValue.aTimeoutsRemaining > 0 || fieldsValue.bTimeoutsRemaining > 0
    setMatchCanTimeout(canTimeout)
    let canApprove = Number(fieldsValue.aScore) === props.tournamentMatch?.raceTo || Number(fieldsValue.bScore) === props.tournamentMatch?.raceTo
    setMatchCanApproved(canApprove)
    setTimeout(() => form.validateFields(), 200)
  }

  const getTeamParticipants = (parentTeamMatchId: number, teamSide: string) => {
    axios.get<IParticipant[]>(`api/participants/team-participants`, {
      params: {
        extended: true,
        teamSide,
        parentTeamMatchId,
        shootOutMatch: props.tournamentMatch.disciplineMnemonic==='SO',
      },
    })
      .then(({ data }) => {
        console.log(teamSide, data)
        if ('A' === teamSide)
          setTeamPlayersA(data.map(p => ({
            value: p.id,
            label: p.displayName,
          })))
        if ('B' === teamSide)
          setTeamPlayersB(data.map(p => ({
            value: p.id,
            label: p.displayName,
          })))
      })
  }

  useEffect(() => {
    // console.log('default effect', matchId, props.loading)
    if (matchId && !props.loading)
      props.getEntity(matchId)
  }, [])

  useEffect(() => {
    // console.log('loading effect', props.loading)
    if (!props.loading && props.tournamentMatch) {
      let formValues = {
        ...props.tournamentMatch,
        matchScheduledTime: moment(props.tournamentMatch.matchScheduledTime),
      }
      setFieldsValue(formValues)
      if (props.tournamentMatch.teamParentMatchId) {
        getTeamParticipants(props.tournamentMatch.teamParentMatchId, 'A')
        getTeamParticipants(props.tournamentMatch.teamParentMatchId, 'B')
      }
      showModal(true)
    }
  }, [props.loading, props.tournamentMatch])

  const onStatusChange = selectedValue => {
    setNextToBreakRequired(selectedValue !== 'READY')
    validateMatchStatus()
  }

  const onPlayerUpdateChance = evt => {
    if (evt.target.value !== '')
      setPlayerModificationNeedsConfirmation(true)
    else {
      let fieldsValue = form.getFieldsValue(['participantAUpdate', 'participantBUpdate'])
      setPlayerModificationNeedsConfirmation(fieldsValue.participantAUpdate !== '' || fieldsValue.participantBUpdate !== '')
    }
  }

  const onSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...defaultValue,
          ...props.tournamentMatch,
          ...values,
          matchScheduledTime: values.matchScheduledTime.utc(true),
        }
        axios
          .post(`/api/management/announced-matches/save-changes`, { ...entityToSubmit, matchId: props.tournamentMatch.id }, {
            params: {
              matchId: props.tournamentMatch.id,
            },
          })
          .then(() => {
              setLoadingSubmit(false)
              showModal(false)
              props.history.push(`/app/matches/upcoming`)
              props.reset()
            },
          )
        // TODO: change from specific request to global working save request
        /*
        // @ts-ignore
        props.updateEntity({ ...props.tournamentMatch, ...values }, 'updateMatch')
        setTimeout(() => {
          setLoadingSubmit(false)
          showModal(false)
          props.history.push(`/app/matches/upcoming`)
          props.reset()
        }, 1000)
        */
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        setTimeout(() => {
          setLoadingSubmit(false)
        }, 1000)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.push(`/app/matches/upcoming`)
    props.reset()
  }

  const onReset = () => {
    form.resetFields()
    setPlayerModificationNeedsConfirmation(false)
  }

  return <Modal
    width={'50%'}
    title={`Edit match ${props.tournamentMatch?.label}`}
    visible={visible}
    destroyOnClose={true}
    okText="Save"
    onOk={onSubmit}
    onCancel={onCancel}
    cancelText="Cancel"
    footer={(<Space>
        <Button key="cancel" onClick={onCancel}>Cancel</Button>
        <Tooltip key="resetTT" title="Reset form to the original values">
          <Button key="reset" htmlType="reset" form="matchForm" onClick={onReset}>Reset Form</Button>
        </Tooltip>
        <Button key="submit" htmlType="submit" form="matchForm" type="primary" onClick={onSubmit}
                loading={submitLoading}>
          Save
        </Button>
      </Space>
    )}
  >
    <Form<ITournamentMatchExtended> layout={'horizontal'} form={form} preserve={false} id="matchForm">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          {props.tournamentMatch.teamParentMatchId == null ?
            <Form.Item label="Player A" name="participantADisplayName"
                       initialValue={props.tournamentMatch?.participantADisplayName}
                       noStyle>
              <Input disabled style={{
                fontSize: 'large',
                fontWeight: 'bolder',
                color: 'black',
                textAlign: 'center',
              }}/>
            </Form.Item>
            :
            <Form.Item name="participantAId"
                       initialValue={props.tournamentMatch?.participantAId}
                       wrapperCol={{ span: 24 }}
            >
              <Select style={{
                fontSize: 'large',
                fontWeight: 'bolder',
                color: 'black',
                textAlign: 'center',
              }}
                      options={teamPlayersA}
              />
            </Form.Item>
          }
        </Col>
        <Col span={12}>
          {props.tournamentMatch.teamParentMatchId == null ?
            <Form.Item label="Player B" name="participantBDisplayName"
                       initialValue={props.tournamentMatch?.participantBDisplayName}
                       noStyle>
              <Input disabled style={{
                fontSize: 'large',
                fontWeight: 'bolder',
                color: 'black',
                textAlign: 'center',
              }}/>
            </Form.Item>
            :
            <Form.Item name="participantBId"
                       initialValue={props.tournamentMatch?.participantBId}
                       wrapperCol={{ span: 24 }}
            >
              <Select style={{
                fontSize: 'large',
                fontWeight: 'bolder',
                color: 'black',
                textAlign: 'center',
              }}
                      options={teamPlayersB}
              />
            </Form.Item>
          }
        </Col>
        <Col span={12} className={'mt-3'}>
          <Form.Item name="raceTo" label="Race-To" rules={[
            { required: true },
          ]}>
            <Input type={'number'} min={1}/>
          </Form.Item>
        </Col>
        <Col span={12} className={'mt-3'}>
          <Form.Item name="matchScheduledTime" label="Scheduled-time" rules={[
            { required: true },
          ]}>
            <DatePicker showTime={{ format: 'HH_mm', minuteStep: 15 }} format="YYYY-MM-DD HH:mm"/>
          </Form.Item>
        </Col>
      </Row>
      <Collapse>
        <Collapse.Panel header="Player Modifications" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={10} offset={2}>
              <Form.Item name="participantAUpdate" initialValue="">
                <Radio.Group onChange={onPlayerUpdateChance}>
                  <Radio style={radioStyle} value="">no changes</Radio>
                  {/*<Radio style={radioStyle} value='walkover'>change player to walk-over</Radio>*/}
                  <Radio style={radioStyle} value="forfeit">forfeit</Radio>
                  <Radio style={radioStyle} value="disqualified">disqualify player</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item name="participantBUpdate" initialValue="">
                <Radio.Group onChange={onPlayerUpdateChance}>
                  <Radio style={radioStyle} value="">no changes</Radio>
                  {/*<Radio style={radioStyle} value='walkover'>change player to walk-over</Radio>*/}
                  <Radio style={radioStyle} value="forfeit">forfeit</Radio>
                  <Radio style={radioStyle} value="disqualified">disqualify player</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      {playerModificationNeedsConfirmation === true ? (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={16} offset={4}
               style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Form.Item name="playerChangeConfirmation" valuePropName="checked" rules={[{
              required: playerModificationNeedsConfirmation,
              message: 'Please confirm to save the changes',
            }]}>
              <Checkbox style={{ backgroundColor: 'yellow', padding: '5px 10px' }}>I confirm that the changes should be made.</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      ) : null}
    </Form>
  </Modal>
}

const mapStateToProps = ({ tournamentMatchExtended }: IAppState) => ({
  tournamentMatch: tournamentMatchExtended.entity,
  loading: tournamentMatchExtended.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowEditMatchModal))
