import { IPageProps } from '../../../interfaces/page-data'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import React from 'react'
import TimeSessionList from './time-sessions.list'
import TimeSessionUpdate from './time-session.update'
import { connect } from 'react-redux'

export interface ITimeSessionsProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: ITimeSessionsProps) => <Switch>
  <Route key={'create'} exact path={'/app/management/time-sessions/new'}
         render={() => <TimeSessionUpdate {...props} />}/>
  <Route key={'edit'} exact path={'/app/management/time-sessions/:id'}
         render={() => <TimeSessionUpdate {...props}/>}/>
  <Route key={'list'} path={'/app/management/time-sessions'}
         render={() => <TimeSessionList {...props}/>}/>
</Switch>

export default withRouter(connect()(Routes))