import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { IPageProps } from '../../../interfaces/page-data'
import VenuesList from './venues'
import VenueUpdate from './venue-update'

export interface IVenuesIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IVenuesIndexProps) => {

  return <>
    <Switch>
      <Route key='vNew' path={'/app/management/venues/new'} render={() => (<VenueUpdate {...props}/>)}/>
      <Route key='vUpdate' path={`/app/management/venues/:id`} render={() => (<VenueUpdate {...props}/>)}/>
      <Route key='sList' path={'/app/management/venues'} render={() => (<VenuesList {...props}/>)}/>
    </Switch>
  </>
}
export default withRouter(connect()(Routes))
