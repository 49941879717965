import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tooltip } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleTwoTone, FilePdfOutlined, QuestionCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { IAppState } from '../../../redux/store'
import { getEntities, getEntity, getPdf, reset, updateEntity } from '../../../shared/reducers/model/invoice.reducer'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { IInvoice } from '../../../shared/model/invoice.model'
import { APP_LOCAL_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'
import { InvoiceStatus } from '../../../shared/model/enumerations/invoice-status.model'
import { InvoicePaymentType } from '../../../shared/model/enumerations/invoice-payment-type.model'
import moment from 'moment'
import { convertDateTimeToServer } from '../../../utils/date-utils'
import { mergeEventStores } from '@fullcalendar/core'

export interface IInvoiceListProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const InvoiceList = (props: IInvoiceListProps) => {

  const { match, loading, onSetPage, entities } = props

  const pageData: IPageData = {
    title: 'Invoices',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Invoices',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'creationDate',
    order: 'desc',
  })
  const [tableFilters, setTableFilters] = useState<Object>({})

  const invoiceStatusFilter = Object.keys(InvoiceStatus).map(key => ({
    value: key,
    text: InvoiceStatus[key],
  }))

  const invoicePaymentTypeFilter = Object.keys(InvoicePaymentType).map(key => ({
    value: key,
    text: InvoicePaymentType[key],
  }))

  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false)

  const doMarkAsPaid = (id) => {
    props.getEntity(id)
    setShowMarkAsPaidModal(true)
    setMarkAsPaid(true)
  }

  const fetchInvoices = () => {
    console.log('fetch invoices', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const fetchInvoicePdf = (id: number, invoiceNumber: string) => {
    getPdf(id, invoiceNumber)
  }

  const [markPaidForm] = Form.useForm()
  const [markAsPaidProcess, setMarkAsPaidProcess] = useState(false)
  const [showMarkAsPaidModalOkButtonLoading, setShowMarkAsPaidModalOkButtonLoading] = useState(false)
  const [markAsPaid, setMarkAsPaid] = useState(false)
  const [markAsCancelled, setMarkAsCancelled] = useState(false)
  useEffect(() => {
    if (markAsPaidProcess && props.entity && props.entity.id > 0) {
      props.updateEntity({
        ...props.entity,
        status: InvoiceStatus.PAID,
      })
    }
  }, [props.entity, markAsPaidProcess])

  useEffect(() => {
    if (props.updateSuccess) {
      message.success('Invoice marked as paid/cancelled.')
      setMarkAsPaidProcess(false)
      setTimeout(fetchInvoices, 500)
    }
  }, [props.updateSuccess])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    console.log('handleSearch', tableFilters)
    if (selectedKeys[0] === '')
      delete tableFilters[dataIndex]
    else
      tableFilters[dataIndex] = selectedKeys[0]
    console.log('handleSearch', tableFilters)
    setTableFilters(tableFilters)
    console.log('handleSearch', tableFilters)
  }

  const handleReset = (clearFields, dataIndex) => {
    clearFields()
    console.log('handleReset', tableFilters)
    delete tableFilters[dataIndex]
    console.log('handleReset', tableFilters)
    setTableFilters(tableFilters)
    console.log('handleReset', tableFilters)
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleTableChanges = (pagination, filters, sorter) => {
    console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    console.log('handleTableChanges > tableFilters:', tableFilters)
    console.log('handleTableChanges > filters:', filters)
    setTableFilters({...tableFilters, ...filters})
  }

  const onFinishMarkAsPaid = (values) => {
    console.log('values:', values)
    markPaidForm.validateFields()
      .then(() => {
        setShowMarkAsPaidModalOkButtonLoading(true)
        props.updateEntity({
          ...props.entity,
          ...values,
          paymentLastModificationDate: convertDateTimeToServer(moment()),
          status: markAsPaid ? InvoiceStatus.PAID : markAsCancelled ? InvoiceStatus.CANCELLED : InvoiceStatus.CREATED,
        })
        setTimeout(() => {
          markPaidForm.resetFields()
          setShowMarkAsPaidModal(false)
          setShowMarkAsPaidModalOkButtonLoading(false)
        }, 2000)
      })
  }

  useEffect(() => {
    // console.log('invoices list default hook')
    onSetPage(pageData)
    fetchInvoices()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage(pageData)
    fetchInvoices()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    setTimeout(fetchInvoices, 100)
  }, [paginationState, tableFilters])

  return <>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Invoice</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table<IInvoice> id="invoiceTable" rowKey="id" loading={loading}
                         size="small"
                         onChange={handleTableChanges}
                         showSorterTooltip
                         dataSource={entities.flat()}
                         pagination={{
                           defaultPageSize: paginationState.itemsPerPage,
                           total: props.totalItems,
                         }}
        >
          <Table.Column<IInvoice> key="status" dataIndex="status" title="Status"
                                  align={'center'}
                                  width={80}
                                  filters={invoiceStatusFilter}
                                  render={(value, row) => {
                                    switch (value) {
                                      case InvoiceStatus.PAID:
                                        return <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{ fontSize: '1.5rem' }}/>
                                      case InvoiceStatus.CANCELLED:
                                        return <CloseCircleTwoTone twoToneColor={'#c41a1a'} style={{ fontSize: '1.5rem' }}/>
                                      case InvoiceStatus.NONE:
                                        return <ExclamationCircleTwoTone twoToneColor={'#ff0000'} style={{ fontSize: '1.5rem' }}/>
                                      default:
                                        return <Button
                                          ghost
                                          onClick={() => doMarkAsPaid(row.id)}
                                          shape={'circle'}
                                          icon={<QuestionCircleTwoTone twoToneColor={'#c4721a'} style={{ fontSize: '1.5rem' }}/>}
                                        />
                                      /*
                                                                            return <Popconfirm title={'Mark Invoice is paid?'}
                                                                                               onConfirm={() => setInvoiceAsPaid(row.id)}
                                                                                               okText={'Yes'} cancelText={'No'}
                                                                                               icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                                                                            >
                                                                              <QuestionCircleTwoTone twoToneColor={'#c4721a'} style={{ fontSize: '1.5rem' }}/>
                                                                            </Popconfirm>
                                      */
                                      // return <QuestionCircleTwoTone twoToneColor={'#c4721a'} style={{ fontSize: '1.5rem' }}/>
                                    }
                                  }}
          />
          <Table.Column<IInvoice> key="invoiceNumber" dataIndex="invoiceNumber" title="Invoice number"
                                  sorter
                                  {...getColumnSearchProps('invoiceNumber')}
          />
          <Table.Column<IInvoice> key="creationDate" dataIndex="creationDate" title="Date"
                                  sorter defaultSortOrder="descend"
                                  align={'center'}
                                  width={130}
                                  render={(value) => <TextFormat value={value} type="date" format={APP_LOCAL_DATETIME_FORMAT_EU} blankOnInvalid/>}
          />
          <Table.Column<IInvoice> key="playerProfileDisplayName" dataIndex="playerProfileId" title="Player"
                                  {...getColumnSearchProps('playerProfileDisplayName')}
                                  render={(value, row) => row.playerProfileDisplayName}
          />
          <Table.Column<IInvoice> key="purpose" dataIndex="purpose" title="Purpose"
                                  {...getColumnSearchProps('purpose')}
          />
          <Table.Column<IInvoice> key="paymentType" dataIndex="paymentType" title="Type"
                                  filters={invoicePaymentTypeFilter}
                                  render={(value) => InvoicePaymentType[value]}
          />
          <Table.Column<IInvoice> key="total" dataIndex="total" title="Total" align={'right'}
                                  render={(value) => `EUR ${value}`}
          />
          <Table.Column<IInvoice> key="invoiceActions" dataIndex="id" title=""
                                  width="100px"
                                  align="right"
                                  render={(value, row) => <Space>
                                    <Tooltip title={'Download Invoice'}>
                                      <FilePdfOutlined onClick={() => fetchInvoicePdf(value, row.invoiceNumber)}/>
                                    </Tooltip>
                                    {/*<EditTwoTone onClick={() => history.push(`${match.url}/${value}`)}/>*/}
                                  </Space>}
          />
        </Table>
      </Col>
    </Row>
    <Modal visible={showMarkAsPaidModal}
           title={'Mark invoice as paid'}
           footer={[
             <Button key="abort" onClick={() => {
               props.reset()
               markPaidForm.resetFields()
               fetchInvoices()
               setShowMarkAsPaidModal(false)
             }}>Cancel</Button>,
             <Button key="cancelled" danger type={'primary'} htmlType={'submit'} name={'submitType'} value={'cancelled'}
                     form={'mark-as-paid-form'} loading={showMarkAsPaidModalOkButtonLoading}
                     onClick={() => {
                       setMarkAsCancelled(true)
                       setMarkAsPaid(false)
                     }}
             >
               Mark as cancelled
             </Button>,
             <Button key="paid" type={'primary'} htmlType={'submit'} name={'submitType'} value={'paid'} form={'mark-as-paid-form'}
                     loading={showMarkAsPaidModalOkButtonLoading}
                     onClick={() => {
                       setMarkAsPaid(true)
                       setMarkAsCancelled(false)
                     }}
             >
               Mark as paid
             </Button>,
           ]}
    >
      <Form form={markPaidForm} id={'mark-as-paid-form'} layout={'vertical'} onFinish={onFinishMarkAsPaid}>
        <Form.Item name={'paymentType'} label={'Payment type'}
                   rules={[{ required: markAsPaid, message: 'Required for paid invoices!' }]}
        >
          <Select options={Object.keys(InvoicePaymentType).map(key => ({
            text: InvoicePaymentType[key],
            value: key,
          }))}/>
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const mapStateToProps = ({ invoice: { entity, entities, loading, totalItems, updating, updateSuccess } }: IAppState) => ({
  entity,
  entities,
  loading,
  totalItems,
  updating,
  updateSuccess,
})

const mapDispatchToProps = {
  getEntity,
  getEntities,
  getPdf,
  updateEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceList))
