import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from '../../../utils/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from '../action-type.util';

import { IBoardMemberType, defaultValue } from '../../model/board-member-type.model';

export const ACTION_TYPES = {
  FETCH_BOARDMEMBERTYPE_LIST: 'boardMemberType/FETCH_BOARDMEMBERTYPE_LIST',
  FETCH_BOARDMEMBERTYPE: 'boardMemberType/FETCH_BOARDMEMBERTYPE',
  CREATE_BOARDMEMBERTYPE: 'boardMemberType/CREATE_BOARDMEMBERTYPE',
  UPDATE_BOARDMEMBERTYPE: 'boardMemberType/UPDATE_BOARDMEMBERTYPE',
  DELETE_BOARDMEMBERTYPE: 'boardMemberType/DELETE_BOARDMEMBERTYPE',
  RESET: 'boardMemberType/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBoardMemberType>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type BoardMemberTypeState = Readonly<typeof initialState>;

// Reducer

export default (state: BoardMemberTypeState = initialState, action): BoardMemberTypeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BOARDMEMBERTYPE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BOARDMEMBERTYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_BOARDMEMBERTYPE):
    case REQUEST(ACTION_TYPES.UPDATE_BOARDMEMBERTYPE):
    case REQUEST(ACTION_TYPES.DELETE_BOARDMEMBERTYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_BOARDMEMBERTYPE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BOARDMEMBERTYPE):
    case FAILURE(ACTION_TYPES.CREATE_BOARDMEMBERTYPE):
    case FAILURE(ACTION_TYPES.UPDATE_BOARDMEMBERTYPE):
    case FAILURE(ACTION_TYPES.DELETE_BOARDMEMBERTYPE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_BOARDMEMBERTYPE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_BOARDMEMBERTYPE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_BOARDMEMBERTYPE):
    case SUCCESS(ACTION_TYPES.UPDATE_BOARDMEMBERTYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_BOARDMEMBERTYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/board-member-types';

// Actions

export const getEntities: ICrudGetAllAction<IBoardMemberType> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_BOARDMEMBERTYPE_LIST,
    payload: axios.get<IBoardMemberType>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IBoardMemberType> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BOARDMEMBERTYPE,
    payload: axios.get<IBoardMemberType>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IBoardMemberType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BOARDMEMBERTYPE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IBoardMemberType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BOARDMEMBERTYPE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IBoardMemberType> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BOARDMEMBERTYPE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
