import { Moment } from 'moment';

export interface IPersonalBalanceHistory {
  id?: number;
  amount?: number;
  notice?: string;
  entryDate?: string;
  playerProfileDisplayName?: string;
  playerProfileId?: number;
}

export const defaultValue: Readonly<IPersonalBalanceHistory> = {};
