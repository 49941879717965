import { Moment } from 'moment';
import { IInvoice } from './invoice.model';
import { IParticipantWarning } from './participant-warning.model';
import { WarningColor } from './enumerations/warning-color.model';

export interface IParticipant {
  id?: number;
  displayName?: string;
  registrationDate?: string;
  registrationApproved?: boolean;
  registrationApprovedDate?: string;
  registrationApprovedBy?: string;
  hotelBooking?: boolean;
  hotelBookingApproved?: boolean;
  hotelBookingApprovedDate?: string;
  hotelBookingApprovedBy?: string;
  seedingNumber?: number;
  playing?: boolean;
  blocked?: boolean;
  place?: number;
  points?: number;
  racksRate?: number;
  qualifierForStage?: number;
  currentWarningStatus?: WarningColor;
  disqualified?: boolean;
  prizeMoney?: number;
  seedingPriority?: number;
  invoices?: IInvoice[];
  warnings?: IParticipantWarning[];
  hotelsName?: string;
  hotelsId?: number;
  lastOpponentId?: number;
  playerProfileDisplayName?: string;
  playerProfileId?: number;
  tournamentName?: string;
  tournamentId?: number;
  teamParticipantId?: number;
}

export const defaultValue: Readonly<IParticipant> = {
  registrationApproved: false,
  hotelBooking: false,
  hotelBookingApproved: false,
  playing: false,
  blocked: false,
  disqualified: false,
  seedingPriority: 999
};
