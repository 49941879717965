import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IPlayerOverallRanking } from '../../model/player-overall-ranking.model'

export const ACTION_TYPES = {
  SEARCH_PLAYEROVERALLRANKINGS: 'playerOverallRanking/SEARCH_PLAYEROVERALLRANKINGS',
  FETCH_PLAYEROVERALLRANKING_LIST: 'playerOverallRanking/FETCH_PLAYEROVERALLRANKING_LIST',
  FETCH_PLAYEROVERALLRANKING: 'playerOverallRanking/FETCH_PLAYEROVERALLRANKING',
  CREATE_PLAYEROVERALLRANKING: 'playerOverallRanking/CREATE_PLAYEROVERALLRANKING',
  UPDATE_PLAYEROVERALLRANKING: 'playerOverallRanking/UPDATE_PLAYEROVERALLRANKING',
  DELETE_PLAYEROVERALLRANKING: 'playerOverallRanking/DELETE_PLAYEROVERALLRANKING',
  RESET: 'playerOverallRanking/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPlayerOverallRanking>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type PlayerOverallRankingState = Readonly<typeof initialState>;

// Reducer

export default (state: PlayerOverallRankingState = initialState, action): PlayerOverallRankingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_PLAYEROVERALLRANKINGS):
    case REQUEST(ACTION_TYPES.FETCH_PLAYEROVERALLRANKING_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PLAYEROVERALLRANKING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PLAYEROVERALLRANKING):
    case REQUEST(ACTION_TYPES.UPDATE_PLAYEROVERALLRANKING):
    case REQUEST(ACTION_TYPES.DELETE_PLAYEROVERALLRANKING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_PLAYEROVERALLRANKINGS):
    case FAILURE(ACTION_TYPES.FETCH_PLAYEROVERALLRANKING_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PLAYEROVERALLRANKING):
    case FAILURE(ACTION_TYPES.CREATE_PLAYEROVERALLRANKING):
    case FAILURE(ACTION_TYPES.UPDATE_PLAYEROVERALLRANKING):
    case FAILURE(ACTION_TYPES.DELETE_PLAYEROVERALLRANKING):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_PLAYEROVERALLRANKINGS):
    case SUCCESS(ACTION_TYPES.FETCH_PLAYEROVERALLRANKING_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_PLAYEROVERALLRANKING):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PLAYEROVERALLRANKING):
    case SUCCESS(ACTION_TYPES.UPDATE_PLAYEROVERALLRANKING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PLAYEROVERALLRANKING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/player-overall-rankings';
const apiSearchUrl = 'api/_search/player-overall-rankings';

// Actions

export const getSearchEntities: ICrudSearchAction<IPlayerOverallRanking> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_PLAYEROVERALLRANKINGS,
  payload: axios.get<IPlayerOverallRanking>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IPlayerOverallRanking> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PLAYEROVERALLRANKING_LIST,
    payload: axios.get<IPlayerOverallRanking>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IPlayerOverallRanking> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PLAYEROVERALLRANKING,
    payload: axios.get<IPlayerOverallRanking>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IPlayerOverallRanking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PLAYEROVERALLRANKING,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IPlayerOverallRanking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYEROVERALLRANKING,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPlayerOverallRanking> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PLAYEROVERALLRANKING,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getEntitiesByOverallRanking: ICrudGetAllAction<IPlayerOverallRanking> = (page, size, sort, overallRankingId?: number | undefined) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}overallRankingId.equals=${overallRankingId}`
  return {
    type: ACTION_TYPES.FETCH_PLAYEROVERALLRANKING_LIST,
    payload: axios.get<IPlayerOverallRanking>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  }
}
