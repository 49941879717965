import axios from 'axios'
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster'

import { cleanEntity } from '../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../../shared/reducers/action-type.util'

import { defaultValue, ITournament } from '../../shared/model/tournament.model'

export const ACTION_TYPES = {
  FETCH_ARCHIVEDTOURNAMENT_LIST: 'tournamentMatch/FETCH_ARCHIVEDTOURNAMENT_LIST',
  FETCH_ARCHIVEDTOURNAMENT: 'tournamentMatch/FETCH_ARCHIVEDTOURNAMENT',
  UPDATE_ARCHIVEDTOURNAMENT: 'tournamentMatch/UPDATE_ARCHIVEDTOURNAMENT',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITournament>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type TournamentsArchivedState = Readonly<typeof initialState>;

// Reducer

export default (state: TournamentsArchivedState = initialState, action): TournamentsArchivedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.UPDATE_ARCHIVEDTOURNAMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT):
    case FAILURE(ACTION_TYPES.UPDATE_ARCHIVEDTOURNAMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.UPDATE_ARCHIVEDTOURNAMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    default:
      return state
  }
};

const apiUrl = '/api/management/archived-tournaments'

// Actions
export const getEntities: ICrudGetAllAction<ITournament> = (page, size, sort, round?: number) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${round ? sort ? `&round=${round}` : `?round=${round}` : ''}`
  return {
    type: ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT_LIST,
    payload: axios.get<ITournament>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<ITournament> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_ARCHIVEDTOURNAMENT,
    payload: axios.get<ITournament>(requestUrl),
  }
}

export const updateEntity: ICrudPutAction<ITournament> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ARCHIVEDTOURNAMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}
