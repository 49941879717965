import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { IPageProps } from '../../../../interfaces/page-data'
import { connect } from 'react-redux'

import WebsiteCalendar from './calendar-item'
import WebsiteCalendarUpdate from './calendar-item-update'
import WebsiteCalendarDeleteDialog from './calendar-item-delete-dialog'

export interface IWebsiteCalendarIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IWebsiteCalendarIndexProps) => {

  return <>
    <Switch>
      <Route key='nCreate' exact path={`/app/public/website/calendar/new`}
             render={() => (<WebsiteCalendarUpdate {...props}  />)}/>
      <Route key='nEdit' exact path={`/app/public/website/calendar/:id`}
             render={() => (<WebsiteCalendarUpdate {...props}  />)}/>
      <Route key='basePage' path={`/app/public/website/calendar`} render={() => (<WebsiteCalendar {...props}  />)}/>
    </Switch>
    <Route key='deleteDialog' exact path={`/app/public/website/calendar/:id/delete`}
           component={WebsiteCalendarDeleteDialog}/>
  </>
}

export default withRouter(connect()(Routes))
