import { IEvent } from './event.model';
import { ITournament } from './tournament.model';
import { VenueType } from './enumerations/venue-type.model';

export interface IVenue {
  id?: number;
  name?: string;
  description?: any;
  street?: string;
  postcode?: string;
  city?: string;
  state?: string;
  type?: VenueType;
  countryName?: string;
  countryId?: number;
  events?: IEvent[];
  tournaments?: ITournament[];
}

export const defaultValue: Readonly<IVenue> = {};
