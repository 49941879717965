import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { createEntity, getEntity, reset, setBlob, updateEntity } from '../../../shared/reducers/model/venue.reducer'

import { Button, Col, Divider, Form, Input, message, Row, Select, Space, Switch, Upload } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getEntities as getCountries } from '../../../shared/reducers/model/country.reducer'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import './venue-update.scss'
import { HotelType } from '../../../shared/model/enumerations/hotel-type.model'
import { translate } from 'react-jhipster'
import { VenueType } from '../../../shared/model/enumerations/venue-type.model'

export interface IVenueUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const VenueUpdate = (props: IVenueUpdateProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const { entity, onSetPage, getPageData } = props
  const [submitLoading, setLoadingSubmit] = useState(false)

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const pageData: IPageData = {
    title: `Venue - ${!isNew ? `${entity.name} [${entity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Venues',
        route: '/app/management/venues',
      },
      {
        title: !isNew ? `Venue: ${entity.name}` : 'Create new Venue',
      },
    ],
  }

  useEffect(() => {
    onSetPage(pageData)
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    props.getCountries(0, 999, 'name')
    // Next Line is preventing Chrome and Firefox autofill/autocomplete feature nightmare
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopDamnAutocomplete')
      //you can put any value but NOT "off" or "false" because they DO NOT works
    })
    return () => {
      props.reset()
    }
  }, [])

  useEffect(() => {
    if (!props.loading && !isNew && entity?.id) {
      let formValues = {
        ...entity,
      }
      setFieldsValue(formValues)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/app/management/venues')
    }
  }, [props.updateSuccess])

  const [availableCountries, setAvailableCountries] = useState([])
  useEffect(() => {
    if (!props.countriesLoading) {
      setAvailableCountries(props.countries.map(country => ({
        value: country.id,
        label: country.name,
      })))
    }
  }, [props.countriesLoading])

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...entity,
          ...values,
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...entity,
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleOnEditorChange = (value, delta, source, editor) => {
    // console.log(value, delta, source, editor)
    setFieldsValue({ description: value })
  }
  return (
    <Form form={form} id={'updateVenue'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
          autoComplete={'off'}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleFormOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name='name' label={'Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 80 }]}>
            <Input autoComplete={'name'}/>
          </Form.Item>
          <Form.Item name="type" label={'Type'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Select
              options={Object.keys(VenueType).map(key => ({
                text: translate(`bitsbutlerApp.VenueType.${VenueType[key]}`),
                value: key,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='street' label={'Street'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name='postcode' label={'Postcode'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name='city' label={'City'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name='state' label={'State'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
          <Form.Item name='countryId' label='Country' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              optionFilterProp='label'
              loading={props.countriesLoading}
              options={availableCountries}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='description' hidden>
            <Input type='text'/>
          </Form.Item>
          <Form.Item name='description' label='Description' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <ReactQuill id='descriptionEditor' theme={'snow'} value={entity.description}
                        onChange={handleOnEditorChange}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Link to={'../venues'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entity: storeState.venue.entity,
  loading: storeState.venue.loading,
  updating: storeState.venue.updating,
  updateSuccess: storeState.venue.updateSuccess,
  countries: storeState.country.entities,
  countriesLoading: storeState.country.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setBlob,
  getCountries,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VenueUpdate))