import { IMailTemplateAttachment } from './mail-template-attachment.model';

export interface IMailTemplate {
  id?: number;
  name?: string;
  subject?: string;
  mailText?: string;
  attachments?: IMailTemplateAttachment[];
}

export const defaultValue: Readonly<IMailTemplate> = {};
