import { IBoardMember } from './board-member.model';

export interface IBoardMemberType {
  id?: number;
  name?: string;
  sequence?: number;
  boardMembers?: IBoardMember[];
}

export const defaultValue: Readonly<IBoardMemberType> = {};
