import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { createEntity, getEntity, reset, setBlob, updateEntity } from '../../../shared/reducers/model/sponsor.reducer'

import { Button, Col, Divider, Form, Input, message, Row, Select, Space, Switch, Upload } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getEntities as getCountries } from '../../../shared/reducers/model/country.reducer'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import './sponsor-update.scss'

export interface ISponsorUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const SponsorUpdate = (props: ISponsorUpdateProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const { entity, onSetPage, getPageData } = props
  const [submitLoading, setLoadingSubmit] = useState(false)

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const pageData: IPageData = {
    title: `Event - ${!isNew ? `${entity.name} [${entity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Sponsors',
        route: '/app/management/sponsors',
      },
      {
        title: !isNew ? `Sponsor: ${entity.name}` : 'Create new Sponsor',
      },
    ],
  }

  useEffect(() => {
    onSetPage(pageData)
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    props.getCountries(0, 999, 'name')
    // Next Line is preventing Chrome and Firefox autofill/autocomplete feature nightmare
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopDamnAutocomplete')
      //you can put any value but NOT "off" or "false" because they DO NOT works
    })
    return () => {
      props.reset()
    }
  }, [])

  useEffect(() => {
    if (!props.loading && !isNew && entity?.id) {
      let formValues = {
        ...entity,
      }
      setFieldsValue(formValues)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/app/management/sponsors')
    }
  }, [props.updateSuccess])

  const [availableCountries, setAvailableCountries] = useState([])
  useEffect(() => {
    if (!props.countriesLoading) {
      setAvailableCountries(props.countries.map(country => ({
        value: country.id,
        label: country.name,
      })))
    }
  }, [props.countriesLoading])

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...entity,
          ...values,
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...entity,
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleOnEditorChange = (value, delta, source, editor) => {
    // console.log(value, delta, source, editor)
    setFieldsValue({ description: value })
  }

  function beforeUpload(fieldName: string, file) {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg'
    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG/PNG/SVG file!')
    }
    const fileReader: FileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = e => {
      // @ts-ignore
      const base64Data = e.target['result'].substr(e.target['result'].indexOf('base64,') + 'base64,'.length)
      props.setBlob(fieldName, base64Data, file.type)
    }
    return false
  }

  const handleDeleteLogo = (event, fieldName: string) => {
    event.stopPropagation()
    props.setBlob(fieldName, undefined, undefined)
  }

  return (
    <Form form={form} id={'updateSponsor'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
          autoComplete={'off'}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleFormOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name='name' label={'Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 80 }]}>
            <Input autoComplete={'name'}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='lemma' label={'Lemma'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     help='Short Name of the sponsor - Used in select boxes in other forms'
                     rules={[{ required: true, max: 30 }]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='description' hidden>
            <Input type='text'/>
          </Form.Item>
          <Form.Item name='description' label='Description' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <ReactQuill id='descriptionEditor' theme={'snow'} value={entity.description}
                        onChange={handleOnEditorChange}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='telephone' label={'Telephone'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='street' label={'Street'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='telefax' label={'Telefax'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='postcode' label={'Postcode'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='website' label={'Website'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='city' label={'City'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='email' label={'Email'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='state' label={'State'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12} offset={12}>
          <Form.Item name='countryId' label='Country' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp='label'
              loading={props.countriesLoading}
              options={availableCountries}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name='mainSponsor' label='Main Sponsor' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} valuePropName='checked'>
            <Switch/>
          </Form.Item>
          <Form.Item name='premiumSponsor' label='Premium Sponsor' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} valuePropName='checked'>
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Main Logo">
            <Form.Item name="file_mainLogoImg" valuePropName="file" noStyle>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept='image/*'
                multiple={false}
                beforeUpload={file => beforeUpload('mainLogoImg', file)}
              >
                {entity.mainLogoImg ? (
                  <>
                    <Button shape='circle' icon={<DeleteOutlined/>} className='remove-logo-btn'
                            onClick={event => handleDeleteLogo(event, 'mainLogoImg')}/>
                    <img src={`data:${entity.mainLogoImgContentType};base64,${entity.mainLogoImg}`} alt="avatar"/>
                  </>
                ) : (
                  <div>
                    <PlusOutlined/>
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Form.Item>
          <Form.Item label="Medium sized Logo">
            <Form.Item name="file_mediumLogoImg" valuePropName="file" noStyle>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept='image/*'
                multiple={false}
                beforeUpload={file => beforeUpload('mediumLogoImg', file)}
              >
                {entity.mediumLogoImg ? (
                  <>
                    <Button shape='circle' icon={<DeleteOutlined/>} className='remove-logo-btn'
                            onClick={event => handleDeleteLogo(event, 'mediumLogoImg')}/>
                    <img src={`data:${entity.mediumLogoImgContentType};base64,${entity.mediumLogoImg}`} alt="avatar"/>
                  </>
                ) : (
                  <div>
                    <PlusOutlined/>
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Form.Item>
          <Form.Item label="Small sized Logo">
            <Form.Item name="file_smallLogoImg" valuePropName="file" noStyle>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept='image/*'
                multiple={false}
                beforeUpload={file => beforeUpload('smallLogoImg', file)}
              >
                {entity.smallLogoImg ? (
                  <>
                    <Button shape='circle' icon={<DeleteOutlined/>} className='remove-logo-btn'
                            onClick={event => handleDeleteLogo(event, 'smallLogoImg')}/>
                    <img src={`data:${entity.smallLogoImgContentType};base64,${entity.smallLogoImg}`} alt="avatar"/>
                  </>
                ) : (
                  <div>
                    <PlusOutlined/>
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Link to={'../sponsors'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entity: storeState.sponsor.entity,
  loading: storeState.sponsor.loading,
  updating: storeState.sponsor.updating,
  updateSuccess: storeState.sponsor.updateSuccess,
  countries: storeState.country.entities,
  countriesLoading: storeState.country.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setBlob,
  getCountries,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SponsorUpdate))
