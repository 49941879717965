import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from '../../../utils/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from '../action-type.util';

import { IInvoiceTemplate, defaultValue } from '../../model/invoice-template.model';

export const ACTION_TYPES = {
  FETCH_INVOICETEMPLATE_LIST: 'invoiceTemplate/FETCH_INVOICETEMPLATE_LIST',
  FETCH_INVOICETEMPLATE: 'invoiceTemplate/FETCH_INVOICETEMPLATE',
  CREATE_INVOICETEMPLATE: 'invoiceTemplate/CREATE_INVOICETEMPLATE',
  UPDATE_INVOICETEMPLATE: 'invoiceTemplate/UPDATE_INVOICETEMPLATE',
  DELETE_INVOICETEMPLATE: 'invoiceTemplate/DELETE_INVOICETEMPLATE',
  RESET: 'invoiceTemplate/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoiceTemplate>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InvoiceTemplateState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceTemplateState = initialState, action): InvoiceTemplateState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICETEMPLATE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INVOICETEMPLATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_INVOICETEMPLATE):
    case REQUEST(ACTION_TYPES.UPDATE_INVOICETEMPLATE):
    case REQUEST(ACTION_TYPES.DELETE_INVOICETEMPLATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICETEMPLATE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INVOICETEMPLATE):
    case FAILURE(ACTION_TYPES.CREATE_INVOICETEMPLATE):
    case FAILURE(ACTION_TYPES.UPDATE_INVOICETEMPLATE):
    case FAILURE(ACTION_TYPES.DELETE_INVOICETEMPLATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICETEMPLATE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICETEMPLATE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVOICETEMPLATE):
    case SUCCESS(ACTION_TYPES.UPDATE_INVOICETEMPLATE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_INVOICETEMPLATE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-templates';

// Actions

export const getEntities: ICrudGetAllAction<IInvoiceTemplate> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICETEMPLATE_LIST,
    payload: axios.get<IInvoiceTemplate>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IInvoiceTemplate> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICETEMPLATE,
    payload: axios.get<IInvoiceTemplate>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IInvoiceTemplate> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICETEMPLATE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IInvoiceTemplate> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INVOICETEMPLATE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IInvoiceTemplate> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INVOICETEMPLATE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
