import axios from 'axios'
import { Storage } from 'react-jhipster'
import { BACKEND_API_URL } from './constants'

const TIMEOUT = 1 * 60 * 1000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = BACKEND_API_URL
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
  const onResponseSuccess = response => response
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0)
    if (status === 403 || status === 401) {
      onUnauthenticated()
    }
    return Promise.reject(err)
  }
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setupAxiosInterceptors
