import { Moment } from 'moment';
import { IPlayerOverallRanking } from './player-overall-ranking.model';
import { ITournament } from './tournament.model';
import { RankingCategory } from './enumerations/ranking-category.model';

export interface IOverallRanking {
  id?: number;
  dateOfGeneration?: string;
  rankingCategory?: RankingCategory;
  playerRankings?: IPlayerOverallRanking[];
  tournaments?: ITournament[];
}

export const defaultValue: Readonly<IOverallRanking> = {};
