import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../../interfaces/page-data'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from '../../../../shared/reducers/model/extended/news-post.extended.reducer'

import {
  deleteEntityForNewsPost as deleteAttachment,
  getEntitiesForNewsPost as getAttachments,
} from '../../../../shared/reducers/model/news-attachment.reducer'

import { getEntities as getTags } from '../../../../shared/reducers/model/tag.reducer'

import axios from 'axios'
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space, Spin,
  Switch,
  Tooltip,
} from 'antd'
import ReactQuill, { Quill } from 'react-quill'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module-react'

import './website-news-posts-edit.scss'
import moment from 'moment'
import { IAppState } from '../../../../redux/store'
import { connect } from 'react-redux'
import Paragraph from 'antd/es/typography/Paragraph'
import { DeleteOutlined } from '@ant-design/icons'
import { mapIdList } from '../../../../utils/entity-utils'

Quill.register({ 'modules/imageResize': ImageResize, 'modules/imageDrop': ImageDrop })

export interface IWebsiteNewsPostEditProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const WebsiteNewsPostEdit = (props: IWebsiteNewsPostEditProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const id = props.match.params.id

  const { attachments } = props

  useEffect(() => {
    onSetPage(pageData)
    if (!isNew) {
      props.getEntity(id)
      loadAttachments(id)
    }
    getAvailableAuthors()
    loadTags()
    return () => {
      props.reset()
    }
  }, [])

  useEffect(() => {
    if (!isNew) {
      // console.log('reload after location change')
      onSetPage({
        ...pageData,
        loaded: true,
      })
      loadAttachments(id)
    }
    loadTags()
  }, [props.location])

  const { newsPostEntity, onSetPage, getPageData, account } = props

  const pageData: IPageData = {
    title: `Website News - ${!isNew ? newsPostEntity.subject : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Website',
        route: 'management/website',
      },
      {
        title: 'News',
        route: 'management/website/news',
      },
      {
        title: !isNew ? newsPostEntity.subject : 'Create new News-Post',
      },
    ],
  }

  useEffect(() => {
    if (!isNew && newsPostEntity && newsPostEntity.id) {
      let formValues = {
        ...newsPostEntity,
        tags: newsPostEntity.tags.map(value => value.id),
        postDate: moment(newsPostEntity.postDate, 'YYYY-MM-DD'),
      }
      setFieldsValue(formValues)
    }
    onSetPage({
      ...pageData,
      loaded: true,
    })
  }, [newsPostEntity])

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const { introduction, text } = newsPostEntity

  const onIntroductionChange = (value, delta, source, editor) => {
    setFieldsValue({ introduction: value })
  }
  const onTextChange = (value, delta, source, editor) => {
    setFieldsValue({ text: value })
  }

  const onReset = () => {
    if (!isNew)
      setFieldsValue(newsPostEntity)
    else
      form.resetFields()
  }

  const [availableBackendUsers, setAvailableAuthors] = useState([])
  const [availableAuthorsLoading, setAvailableAuthorsLoading] = useState(false)

  const getAvailableAuthors = async () => {
    setAvailableAuthorsLoading(true)
    await axios
      .get(`/api/users/available-authors`)
      .then(({ data }) => {
        setAvailableAuthors(data.map(author => ({
          label: author.firstName + ' ' + author.lastName,
          value: author.id,
        })))
        setAvailableAuthorsLoading(false)
      })
  }

  const [imageSpinning, setImageSpinning] = useState({})

  const loadAttachments = (newsPostId) => {
    // @ts-ignore
    props.getAttachments(0, 99, 'name', newsPostId)
  }

  const [submitLoading, setLoadingSubmit] = useState(false)

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entity = {
          ...newsPostEntity,
          ...values,
          tags: values.tags ? mapIdList(values.tags) : null,
          postDate: values.postDate.format('YYYY-MM-DD'),
        }
        if (isNew) {
          props.createEntity(entity)
        } else {
          props.updateEntity(entity)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const [availableTags, setAvailableTags] = useState([])
  const loadTags = () => {
    props.getTags(0, 999, 'name')
  }
  useEffect(() => {
    if (!props.tagsLoading)
      setAvailableTags(props.tags.map(tag => ({
        label: tag.name, value: tag.id,
      })))
  }, [props.tagsLoading, props.tags])

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleDeleteAttachment = (id) => {
    setImageSpinning({
      ...imageSpinning,
      [id]: true,
    })
    props.deleteAttachment(id)
    setTimeout(() => {
      loadAttachments(newsPostEntity?.id)
      setImageSpinning({
        ...imageSpinning,
        [id]: false,
      })
    }, 500)
  }

  useEffect(() => {
    if (props.updateSuccess && isNew) {
      props.history.push(`/app/public/website/news/${newsPostEntity.id}`)
    }
  }, [props.updateSuccess])

  return (
    <Form form={form} id="editNewsPost" layout={'horizontal'} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="userId" label="Author" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                     initialValue={account.id}
                     rules={[{ required: true, message: 'Please select a author' }]}
          >
            <Select placeholder="select author"
              // showSearch
                    autoClearSearchValue
                    optionFilterProp="label"
                    loading={availableAuthorsLoading}
                    options={availableBackendUsers}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="postDate" label="Date" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                     initialValue={moment()}
                     rules={[{ required: true, message: 'Please enter a date' }]}
          >
            <DatePicker showToday/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'published'} valuePropName={'checked'} label={'Published'}>
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="subject" label="Title"
                     rules={[{ required: true, max: 200, message: 'Subject is required' }]}>
            <Input type={'text'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="introduction" hidden>
            <Input type="text"/>
          </Form.Item>
          <Form.Item name="introduction" label="Introduction">
            <ReactQuill id="introductionEditor" theme={'snow'} modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'link'],
              ],
            }}
                        value={introduction}
                        onChange={onIntroductionChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="text" hidden>
            <Input type="text"/>
          </Form.Item>
          <Form.Item name="text" label="Main Article" rules={[{ required: true, message: 'Main Article is required' }]}>
            <ReactQuill id="textEditor" theme={'snow'} value={text} onChange={onTextChange} modules={{
              toolbar: [
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'align': [] }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
              imageResize: {
                parchment: Quill.import('parchment'),
              },
              imageDrop: true,
            }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={'tags'} label={'Tags/Keywords'}
                     rules={[{ required: true, message: 'One or more tag required' }]}>
            <Select mode="tags" style={{ width: '100%' }} placeholder="Tags for News" options={availableTags}
                    loading={props.tagsLoading}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align={'top'} justify={'center'}>
        {attachments && attachments.length > 0 ?
          attachments.map(attachment => (
            <Col flex={'300px'} key={`attachmentCol${attachment.id}`} id={`a${attachment.id}`}>
              <Spin spinning={imageSpinning[attachment.id] === true}>
                <Card
                  key={`attachmentCard${attachment.id}`}
                  hoverable
                  style={{ width: 240, borderColor: 'lightgrey', marginBottom: 8 }}
                  cover={<img src={`data:${attachment.attachmentContentType};base64,${attachment.attachment}`}
                              alt="avatar"/>}
                  actions={[
                    <Popconfirm title={'Delete this Attachment?'} onConfirm={() => handleDeleteAttachment(attachment.id)}
                                okText={'YES'} okButtonProps={{ danger: true }} cancelText={'NO'}>
                      <DeleteOutlined/>
                    </Popconfirm>,
                  ]}
                >
                  <Card.Meta style={{ textAlign: 'center' }} title={attachment.name}
                             description={<Paragraph>Cover-Image: <Checkbox disabled
                                                                            checked={attachment.coverImage}/></Paragraph>}/>
                </Card>
              </Spin>
            </Col>
          )) : (
            <Col span={24} style={{ textAlign: 'center' }}>No Attachments available</Col>
          )
        }
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Space>
            {isNew ? (
              <Tooltip title={'First save a new website news, before you can add a cover image'}>
                <Button disabled={true}>
                  Upload Attachment
                </Button>
              </Tooltip>
            ) : (
              <Button onClick={() => props.history.push(`${props.match.url}/upload`)}>
                Upload Attachment
              </Button>
            )}
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Link to={'../news'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  newsPostEntity: storeState.newsPost.entity,
  attachments: storeState.newsAttachment.entities,
  attachmentsLoading: storeState.newsAttachment.loading,
  updating: storeState.newsPost.updating,
  updateSuccess: storeState.newsPost.updateSuccess,
  backendUsersLoading: storeState.userManagement.loading,
  backendUsers: storeState.userManagement.users,
  tags: storeState.tag.entities,
  tagsLoading: storeState.tag.loading,
  account: storeState.authentication.account,
})

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
  getTags,
  getAttachments,
  deleteAttachment,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteNewsPostEdit))
