import { connect } from 'react-redux'
import { IAppState } from '../../../redux/store'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { Link, RouteComponentProps } from 'react-router-dom'

import { getRoles, getUsers, deleteUser } from '../../../shared/reducers/model/user-management.reducer'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Input, message, Modal, Row, Space, Table, Tooltip } from 'antd'
import { IPaginationBaseState } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { IUser } from '../../../shared/model/user.model'
import { AUTHORITIES_LABELS } from '../../../redux/settings/constants'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'

export interface IUsersListProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{}> {
}

const UsersList = (props: IUsersListProps) => {

  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'User Management',
    loaded: false,
    breadcrumbs: [
      {
        title: 'Administration',
      },
      {
        title: 'Users',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'lastName',
    order: 'asc',
  })

  const [tableFilters, setTableFilters] = useState({
    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_TOURNAMENT', 'ROLE_PHOTOGRAPHER', 'ROLE_PRESS', 'ROLE_USER', 'ROLE_WEBSITE']
  })

  useEffect(() => {
    onSetPage(pageData)
    loadEntities()
    loadRoleFilters()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
    loadRoleFilters()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      loadEntities()
    }
  }, [props.updateSuccess])

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const loadEntities = () => {
    // @ts-ignore
    props.getUsers(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    if (selectedKeys[0] === '')
      delete tableFilters[dataIndex]
    else
      tableFilters[dataIndex] = selectedKeys[0]
    setTableFilters({
      ...tableFilters,
    })
  }

  const handleReset = (clearFields, dataIndex) => {
    delete tableFilters[dataIndex]
    clearFields()
  }

  const handleDeleteNews = (entity) => {
    const confirmDeletion = Modal.confirm({
      title: `Delete ${entity.lastName}, ${entity.firstName}`,
      content: (<>
        <p>Are you sure you want to delete the following user login?</p>
        <h6>{entity.lastName}, {entity.firstName}</h6>
      </>),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => {
        props.deleteUser(entity.login)
        setTimeout(() => {
          message.info('User login deleted')
          confirmDeletion.destroy()
          loadEntities()
        }, 1000)
      },
      onCancel: () => {
        confirmDeletion.destroy()
      },
    })
  }

  const handleEditEntity = (id) => {
    props.history.push(`${props.match.url}/${id}`)
  }

  const [roleFilters, setRoleFilters] = useState([])
  const loadRoleFilters = async () => {
    props.getRoles()
    /*
            const roles = []
            for (let key of Object.keys(AUTHORITIES_LABELS)) {
                if (key === 'KOZOOM') continue
                roles.push({ text: AUTHORITIES_LABELS[key], value: key })
            }
            setRoleFilters(roles)
    */
  }
  useEffect(() => {
    setRoleFilters(props.roles.map(role => ({
      text: AUTHORITIES_LABELS[role.substr(5)],
      value: role,
    })))
  }, [props.roles])

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${props.match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new User</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table<IUser> id='mngPlayerProfilesTable'
                      rowKey='id'
                      size='small'
                      dataSource={props.users.flat()}
                      loading={props.loading}
                      onChange={handleTableChange}
                      pagination={{
                        defaultPageSize: paginationState.itemsPerPage,
                        total: props.totalItems,
                        pageSize: paginationState.itemsPerPage,
                      }}
        >
          <Table.Column<IUser> key='id' dataIndex='id' title='ID' sorter/>
          <Table.Column<IUser> key='activated' dataIndex='activated' title='Active'
                               filters={[
                                 { text: 'Enabled', value: true },
                                 { text: 'Disabled', value: false },
                               ]}
                               render={(value) => <Checkbox disabled checked={value === true}/>}
          />
          <Table.Column<IUser> key='lastName' dataIndex='lastName' title='Name'
                               {...getColumnSearchProps('lastName')}
                               sorter defaultSortOrder='ascend'
                               render={(value, row) => {
                                 if (row.lastName?.trim().length > 0) {
                                   if (row.firstName?.trim().length > 0) {
                                     return (<span>{row.lastName}, {row.firstName}</span>)
                                   }
                                   return (<span>{row.lastName}</span>)
                                 } else {
                                   if (row.firstName?.trim().length > 0) {
                                     return (<span>{row.firstName}</span>)
                                   } else {
                                     return null
                                   }
                                 }
                               }}
          />
          <Table.Column<IUser> key='login' dataIndex='login' title='Login'/>
          <Table.Column<IUser> key='email' dataIndex='email' title='Email'/>
          <Table.Column<IUser> key='authorities' dataIndex='authorities' title='Authorities'
                               filters={roleFilters}
                               defaultFilteredValue={['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_TOURNAMENT', 'ROLE_PHOTOGRAPHER', 'ROLE_PRESS', 'ROLE_USER', 'ROLE_WEBSITE']}
                               render={(value) => {
                                 return value.map(auth => AUTHORITIES_LABELS[auth.substr(5)]).join(', ')
                               }}
          />
          <Table.Column key={'actions'} dataIndex={'login'} title={''}
                        render={(value, row) => (
                          <Space>
                            {console.log(value, row)}
                            <Tooltip title={'Edit Userlogin'} placement={'left'}>
                              <EditOutlined onClick={() => handleEditEntity(value)}
                                            style={{ fontSize: 20 }}
                              />
                            </Tooltip>
                            <Tooltip title={'Delete News Post'} placement={'left'}>
                              <DeleteOutlined onClick={() => handleDeleteNews(row)}
                                              style={{ fontSize: 20, color: 'red' }}
                                              disabled={true}
                              />
                            </Tooltip>
                          </Space>
                        )}
          /> </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ authentication, userManagement }: IAppState) => ({
  users: userManagement.users,
  loading: userManagement.loading,
  totalItems: userManagement.totalItems,
  updateSuccess: userManagement.updateSuccess,
  roles: userManagement.authorities,
  account: authentication.account,
})

const mapDispatchToProps = {
  getUsers,
  getRoles,
  deleteUser,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)