import { TableStatus } from './enumerations/table-status.model'
import { TableType } from './enumerations/table-type.model'

export interface IBilliardTable {
  id?: number;
  name?: string;
  displayNumber?: string;
  tableStatus?: TableStatus;
  tableType?: TableType;
  priority?: number;
  eventName?: string;
  eventId?: number;
  tournamentMatchId?: number;
  tournamentCategoryId?: number;
  tournamentCategoryMnemonic?: string;
}

export const defaultValue: Readonly<IBilliardTable> = {}
