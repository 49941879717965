import React, { useEffect, useState } from 'react'
import { IAppState } from '../../redux/store'
import { connect } from 'react-redux'
import { Alert, Button, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Table, Tooltip } from 'antd'
import { DeleteOutlined, QuestionCircleOutlined, SearchOutlined, UsergroupAddOutlined } from '@ant-design/icons/lib'
import axios from 'axios'
import Text from 'antd/es/typography/Text'
import { APP_DATETIME_FORMAT_EU } from '../../redux/settings/constants'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { TournamentStatus } from '../../shared/model/enumerations/tournament-status.model'
import { IPageProps } from '../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IParticipant } from '../../shared/model/participant.model'
import { getEntities as getParticipants } from '../../shared/reducers/model/participant.reducer'
import { getEntity as getTournament } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { IPlayerProfile } from '../../shared/model/player-profile.model'
import { useForm } from 'antd/es/form/Form'

export interface ITournamentParticipantsModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

const TournamentParticipantsModal: React.FC<ITournamentParticipantsModalProps> = (props: ITournamentParticipantsModalProps) => {

  const tournamentId = Number(props.match.params.id)

  const [visible, showModal] = useState(false)
  const [addParticipantModalVisible, showAddParticipantModal] = useState(false)
  const [seedingPriorityModalVisible, setSeedingPriorityModalVisible] = useState(false)

  const [addParticipantSubmitButtonDisabled, disableAddParticipantSubmitButton] = useState(false)

  const [availablePlayerProfiles, setAvailablePlayerProfiles] = useState([])
  const [availablePlayerProfilesLoading, setAvailablePlayerProfilesLoading] = useState(false)
  const [submitLoading, setLoadingSubmit] = useState(false)
  const [registrationButtonLoading, setRegistrationButtonLoading] = useState({})
  const [hotelButtonLoading, setHotelButtonLoading] = useState({})
  const [deleteButtonLoading, setDeleteButtonLoading] = useState({})

  const [selectedTeamId, setSelectedTeamId] = useState<number>()

  const [form] = Form.useForm<IPlayerProfile>()
  const [formSeedingPriority] = Form.useForm<IParticipant>()

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: 10,
    activePage: 1,
    sort: 'displayName',
    order: 'asc',
  })

  const [tableFilters, setTableFilters] = useState({
    tournamentId,
  })

  useEffect(() => {
    if (tournamentId && !props.tournamentLoading)
      props.getTournament(tournamentId)
  }, [])

  useEffect(() => {
    if (!props.tournamentLoading && props.tournament) {
      loadParticipants()
      showModal(true)
    }
  }, [props.tournamentLoading, props.tournament])

  useEffect(() => {
    console.log('selectedTeamId:', selectedTeamId)
    if (addParticipantModalVisible)
      if (selectedTeamId)
        loadAvailablePlayerProfiles(selectedTeamId)
      else
        loadAvailablePlayerProfiles(undefined)
  }, [addParticipantModalVisible])

  useEffect(() => {
    loadParticipants()
  }, [paginationState, tableFilters])

  const onSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        // props.updateEntity({ ...props.tournament, ...values })
        setTimeout(() => {
          setLoadingSubmit(false)
          showModal(false)
          props.history.push(`/app/tournaments/upcoming`)
        }, 1000)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        setTimeout(() => {
          setLoadingSubmit(false)
        }, 1000)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.push(`/app/tournaments/upcoming`)
  }

  const onReset = () => {
    form.resetFields()
  }

  const loadParticipants = () => {
    // @ts-ignore
    props.getParticipants(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const loadAvailablePlayerProfiles = async (teamId: number | undefined) => {
    setAvailablePlayerProfilesLoading(true)
    await axios
      .get(`/api/management/tournaments/${tournamentId}/participants/available-players${teamId > 0 ? `?teamId=${teamId}` : ''}`)
      .then(({ data }) => {
        setAvailablePlayerProfiles(data.map(playerProfile => ({
          label: `${playerProfile.displayName} (${playerProfile.playerCategoryName})`,
          value: playerProfile.id,
        })))
        setAvailablePlayerProfilesLoading(false)
      })
  }

  const handleAddParticipantSubmit = () => {
    form.validateFields()
      .then(values => {
        // console.log(values)
        disableAddParticipantSubmitButton(true)
        axios
          .put(`/api/management/tournaments/${props.tournament.id}/participants/available-players`, { id: Number(values.id) })
          .then(() => {
            setTimeout(() => {
              showAddParticipantModal(false)
              disableAddParticipantSubmitButton(false)
              loadParticipants()
            }, 500)
          })
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
      })
  }

  const handleRemoveParticipant = (participantId) => {
    setDeleteButtonLoading({ [participantId]: true })
    axios
      .delete(`/api/participants/${participantId}`)
      .then(resp => {
        setTimeout(() => {
          loadParticipants()
          setDeleteButtonLoading({ [participantId]: false })
        }, 500)
      })
  }

  const toggleRegistration = (participantId, currentValue) => {
    if (currentValue !== true) {
      setRegistrationButtonLoading({ [participantId]: true })
      axios
        .post(`/api/management/tournaments/${props.tournament.id}/participants/${participantId}/approve-registration`)
        .then(resp => {
          setTimeout(() => {
            loadParticipants()
            setRegistrationButtonLoading({ [participantId]: false })
          }, 500)
        })
    }
  }

  const toggleHotel = (participantId, currentValue) => {
    if (currentValue !== true) {
      setHotelButtonLoading({ [participantId]: true })
      axios
        .post(`/api/management/tournaments/${props.tournament.id}/participants/${participantId}/approve-hotel`)
        .then(resp => {
          setTimeout(() => {
            loadParticipants()
            setHotelButtonLoading({ [participantId]: false })
          }, 500)
        })
    }
  }

  const handleSeedingPrioritySubmit = () => {
    formSeedingPriority.validateFields()
      .then(values => {
        console.log(values)
        axios
          .post(`/api/management/tournaments/${props.tournament.id}/participants/${values.id}/change-seeding-priority`, {
            ...values,
          })
          .then(resp => {
            setTimeout(() => {
              setSeedingPriorityModalVisible(false)
              loadParticipants()
            }, 500)
          })

      })
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  return <>
    <Modal
      width={'80%'}
      title={`Participants list from tournament: ${props.tournament?.name}`}
      visible={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={(
        <Space>
          <Button key="addParticipant" onClick={() => showAddParticipantModal(true)}>Add Participant</Button>
          <Button key="closeModal" type="primary" onClick={onCancel}>Close</Button>
        </Space>
      )}
    >
      <Text strong>{`Currently ${props.participantsTotalItems} participants registered`}</Text>
      <Table key="participantsTable" id="participantTable"
             rowKey="id"
             size="small"
             dataSource={props.participants.flat()}
             loading={props.participantsLoading}
             onChange={handleTableChanges}
             pagination={{
               defaultPageSize: 10,
               total: props.participantsTotalItems,
               pageSize: paginationState.itemsPerPage,
               hideOnSinglePage: false,
               position: ['topRight', 'bottomRight'],
               showSizeChanger: false,
             }}
      >
        <Table.Column key={'index'} dataIndex={'id'} title={''} width={50} align={'right'}
                      render={(value, row, index) => (paginationState.activePage - 1) * paginationState.itemsPerPage + props.participants.indexOf(row) + 1}/>
        <Table.Column key={'displayName'} dataIndex={'displayName'} title={'Name'}
                      sorter
                      defaultSortOrder={'ascend'}
                      {...getColumnSearchProps('displayName')}
        />
        <Table.Column key={'registrationDate'} dataIndex={'registrationDate'} title={'Registration'}
                      width={140}
                      align={'center'}
                      sorter
                      render={(value) => <TextFormat type="date" value={value} format={APP_DATETIME_FORMAT_EU}/>}
        />
        <Table.Column<IParticipant> key={'registrationApproved'} dataIndex={'registrationApproved'} title={'Status'}
                                    width={180}
                                    align={'center'}
                                    filterMultiple={false}
                                    filters={[
                                      { text: 'Approved', value: true },
                                      { text: 'Not approved', value: false },
                                    ]}
                                    render={(value, row) =>
                                      <Button danger={value !== true} color={value === true ? 'green' : undefined}
                                        // disabled={value === true}
                                              block
                                              loading={registrationButtonLoading[row.id]}
                                              onClick={() => toggleRegistration(row.id, value)}>{value ? 'Approved' : 'Not approved'}</Button>
                                    }
        />
        <Table.Column<IParticipant> key={'hotelBookingApproved'} dataIndex={'hotelBookingApproved'} title={'Hotel'}
                                    width={180}
                                    align={'center'}
                                    filterMultiple={false}
                                    filters={[
                                      { text: 'Booked', value: true },
                                      { text: 'Not booked', value: false },
                                    ]}
                                    render={(value, row) =>
                                      <Button danger={value !== true} color={value === true ? 'green' : undefined}
                                        // disabled={value === true}
                                              block
                                              loading={hotelButtonLoading[row.id]}
                                              onClick={() => toggleHotel(row.id, value)}>{value ? 'Booked' : 'Not booked'}</Button>
                                    }
        />
        <Table.Column<IParticipant> key={'seedingPriority'} dataIndex={'seedingPriority'} title={'Prio. Seeding'}
                                    width={180}
                                    align={'center'}
                                    sorter={true}
                                    render={(value, row) =>
                                      <Button block onClick={() => {
                                        formSeedingPriority.setFieldsValue(row)
                                        setSeedingPriorityModalVisible(true)
                                      }}>{value}</Button>
                                    }
        />
        <Table.Column<IParticipant> key={'actions'} dataIndex={'id'} title={''} width={100} align={'right'} render={(value, row) => (
          <Space>
            <Popconfirm title="Remove from participants list?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                        onConfirm={() => handleRemoveParticipant(value)}>
              <Button danger icon={<DeleteOutlined/>}
                      disabled={props.tournament?.tournamentStatus !== TournamentStatus.CREATED}
                      loading={deleteButtonLoading[value]}
              />
            </Popconfirm>
            {props.tournament.disciplineId === 11 ?
              <Tooltip title="Add player to this team" placement={'left'}>
                <Button icon={<UsergroupAddOutlined/>} onClick={() => {
                  setSelectedTeamId(value)
                  showAddParticipantModal(true)
                }}/>
              </Tooltip>
              : null
            }
          </Space>
        )}/>
      </Table>
    </Modal>
    <Modal visible={addParticipantModalVisible}
           title="Add participant to tournament"
           destroyOnClose={true}
           onCancel={() => {
             showAddParticipantModal(false)
             setSelectedTeamId(null)
           }}
           okText="add participant"
           onOk={handleAddParticipantSubmit}
           okButtonProps={{ disabled: addParticipantSubmitButtonDisabled }}
    >
      <Form form={form} id="addParticipantForm">
        <Form.Item name="id" rules={[
          { required: true, message: 'Please select a player to add' },
        ]}>
          <Select placeholder="select Player"
                  showSearch
                  autoClearSearchValue
                  optionFilterProp="label"
                  loading={availablePlayerProfilesLoading}
                  options={availablePlayerProfiles}
                  notFoundContent={'no more players available'}
          />
        </Form.Item>
      </Form>
    </Modal>
    <Modal visible={seedingPriorityModalVisible}
           title="Change additional seeding priority"
           destroyOnClose={true}
           onCancel={() => {
             setSeedingPriorityModalVisible(false)
           }}
           okText="Save"
           onOk={handleSeedingPrioritySubmit}
    >
      <Alert message={'Additional Information'} description={<>
        <p>
          Seeding high priority
        </p>
        <p>
          Use this for higher seeding priority, before the normal seeding from ranking.<br/>
          Default is 999.
        </p>
        <p>
          The Seeding is done in this way:<br/>
          Place the participants with a specific prio seeding, then by Ranking, then by won/lose ratio.<br/>
          Participant with same prio seeding, ordered by selected ranking
        </p>
        <p>
          Example to use it for the matchroom ranking:<br/>
          Enter the place of the player in the matchroom ranking, but only for these participants that seeded first by matchroom ranking, leave the rest on 999 to order them by
          epbf ranking
        </p>
      </>} type={'info'} showIcon className={'mb-4'}/>
      <Form form={formSeedingPriority} id="formSeedingPriority">
        <Form.Item name={'id'} hidden><Input/></Form.Item>
        <Form.Item name="seedingPriority" label={'High Prio Seeding'} help={'Participant with numbers lower 999 seeded first, in ascend order'} rules={[
          { required: true, message: 'Required. For reset to default enter 999' },
        ]}>
          <InputNumber/>
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const mapStateToProps = ({ participant, tournamentExtended }: IAppState) => ({
  participantsLoading: participant.loading,
  participants: participant.entities,
  participantsTotalItems: participant.totalItems,
  tournament: tournamentExtended.entity,
  tournamentLoading: tournamentExtended.loading,
})

const mapDispatchToProps = {
  getParticipants,
  getTournament,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TournamentParticipantsModal))
