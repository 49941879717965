import { NewsAttachmentType } from './enumerations/news-attachment-type.model';

export interface INewsAttachment {
  id?: number;
  name?: string;
  attachmentContentType?: string;
  attachment?: any;
  coverImage?: boolean;
  attachmentType?: NewsAttachmentType;
  newsPostId?: number;
}

export const defaultValue: Readonly<INewsAttachment> = {
  coverImage: false,
};
