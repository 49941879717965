import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../../interfaces/page-data'
import { IAppState } from '../../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  deleteEntity,
  getEntities,
  getSearchEntities,
  reset,
} from '../../../../shared/reducers/model/extended/news-post.extended.reducer'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE } from '../../../../utils/pagination.constants'
import { Button, Checkbox, Col, Input, message, Modal, Row, Space, Table, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'

import './website-news-posts.scss'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../../../redux/settings/constants'

export interface IWebsiteNewsPostsProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const WebsiteNewsPosts = (props: IWebsiteNewsPostsProps) => {

  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'Website News',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Website',
        route: 'management/website',
      },
      {
        title: 'Website News',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'postDate',
    order: 'desc',
  })

  const [tableFilters, setTableFilters] = useState({})

  useEffect(() => {
    onSetPage(pageData)
    loadEntities()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess)
      loadEntities()
  }, [props.updateSuccess])

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const loadEntities = () => {
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    if (selectedKeys[0] === '')
      delete tableFilters[dataIndex]
    else
      tableFilters[dataIndex] = selectedKeys[0]
    setTableFilters({
      ...tableFilters,
    })
  }

  const handleReset = (clearFields, dataIndex) => {
    delete tableFilters[dataIndex]
    clearFields()
  }

  const handleDeleteNews = (newsPost) => {
    const confirmDeletion = Modal.confirm({
      title: `Delete ${newsPost.subject}?`,
      content: (<>
        <p>Are you sure you want to delete the following news?</p>
        <h6>{newsPost.subject}</h6>
      </>),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => {
        props.deleteEntity(newsPost.id)
        setTimeout(() => {
          message.info('News Post deleted')
          confirmDeletion.destroy()
        }, 1000)
      },
      onCancel: () => {
        confirmDeletion.destroy()
      },
    })
  }

  const handleEditEntity = (id) => {
    props.history.push(`${props.match.url}/${id}`)
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${props.match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new News-Post</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table id='mngWebsiteNewsPostsTable'
               rowKey='id'
               size='small'
               dataSource={props.entities.flat()}
               loading={props.loading}
               onChange={handleTableChange}
               pagination={{
                 defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                 total: props.totalItems,
                 pageSize: paginationState.itemsPerPage,
               }}
        >
          <Table.Column key={'subject'} dataIndex={'subject'} title={'Subject'}
                        {...getColumnSearchProps('subject')}
          />
          <Table.Column key={'published'} dataIndex={'published'} title={'Published'}
                        width={85}
                        align={'center'}
                        render={(value) => <Checkbox disabled checked={value !== null}/>}
          />
          <Table.Column key={'postDate'} dataIndex={'postDate'} title={'Date'}
                        width={85}
                        sorter
                        defaultSortOrder={'descend'}
                        render={(value) => <TextFormat value={value} type={'date'} format={APP_LOCAL_DATE_FORMAT_EU}/>}
          />
          <Table.Column key='author' dataIndex={'authorName'} title={'Author'}
                        width={250}
          />
          <Table.Column key={'actions'} dataIndex={'id'} title={''}
                        render={(value, row) => (
                          <Space>
                            <Tooltip title={'Edit News Post'} placement={'left'}>
                              <EditOutlined onClick={() => handleEditEntity(value)}
                                            style={{ fontSize: 20 }}
                              />
                            </Tooltip>
                            <Tooltip title={'Delete News Post'} placement={'left'}>
                              <DeleteOutlined onClick={() => handleDeleteNews(row)}
                                              style={{ fontSize: 20, color: 'red' }}
                              />
                            </Tooltip>
                          </Space>
                        )}
          />
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ newsPost }: IAppState) => ({
  loading: newsPost.loading,
  entities: newsPost.entities,
  totalItems: newsPost.totalItems,
  updateSuccess: newsPost.updateSuccess,
})

const mapDispatchToProps = {
  getEntities,
  getSearchEntities,
  reset,
  deleteEntity,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteNewsPosts))
