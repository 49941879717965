import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { IPageProps } from '../../../interfaces/page-data'

import OverviewTables from './tables'
import OverviewsTablesAssignMatchModal from './tables-assign-match.modal'

export interface IBilliardTablesIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IBilliardTablesIndexProps) => {

  return <>
    <Switch>
      <Route key='tAssignMatch' exact path={`${props.match.url}/:id/assignMatch`}
             render={() => (<OverviewsTablesAssignMatchModal {...props}  />)}/>
    </Switch>
    <Route key='basePage' path={`${props.match.url}`} render={() => (<OverviewTables {...props}  />)}/>
  </>
}

export default withRouter(connect()(Routes))
