import { IBilliardTable } from './billiard-table.model'
import { ITournament } from './tournament.model'
import { IHotel } from './hotel.model'
import { IVenue } from './venue.model'
import { IUser } from './user.model'
import { EventStatus } from './enumerations/event-status.model'
import { EventType } from './enumerations/event-type.model'

export interface IEvent {
  id?: number;
  name?: string;
  shortName?: string;
  description?: any;
  eventStartDate?: string;
  eventEndDate?: string;
  registrationStartDate?: string;
  registrationEndDate?: string;
  eventStatus?: EventStatus;
  numberOfTables?: number;
  oldId?: string;
  oldEventNr?: string;
  eventType?: EventType;
  tables?: IBilliardTable[];
  tournaments?: ITournament[];
  hotels?: IHotel[];
  venues?: IVenue[];
  seasonName?: string;
  seasonId?: number;
  users?: IUser[];
  eventPosterId?: number;
}

export const defaultValue: Readonly<IEvent> = {}
