import React, { useEffect, useState } from 'react'
import { history, IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { APP_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'

import { Col, Form, message, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import { PlayCircleTwoTone, PlusSquareTwoTone, ReloadOutlined } from '@ant-design/icons'
import Button from 'antd/es/button'

import { ITournamentMatchExtended } from '../../../shared/model/extended/tournament-match.extended.model'
import { getEntities } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'
import { getEntities as getTournaments } from '../../tournament/tournaments-running.reducer'
import { MatchStatus } from '../../../shared/model/enumerations/match-status.model'
import axios from 'axios'
import { TournamentStatus } from '../../../shared/model/enumerations/tournament-status.model'

export interface IMatchesUpcomingProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const MatchesUpcoming = (props: IMatchesUpcomingProps) => {

  const pageData: IPageData = {
    title: 'Upcoming Matches',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Matches',
      },
      {
        title: 'Upcoming',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'matchScheduledTime',
    order: 'asc',
  })

  const [tableFilters, setTableFilters] = useState({
    matchStatus: [MatchStatus.SCHEDULED, MatchStatus.CREATED],
    tournamentStatus: [TournamentStatus.RUNNING, TournamentStatus.GENERATED],
  })

  const [numberOfTeamMatches, setNumberOfTeamMatches] = useState(0)

  const { onSetPage } = props
  useEffect(() => {
    onSetPage(pageData)
    loadEntities()
    loadTournamentFilters()
    loadRoundFilters()
    loadSessionFilters()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
    loadTournamentFilters()
    loadRoundFilters()
    loadSessionFilters()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const [tournamentFilters, setTournamentFilters] = useState([])
  useEffect(() => {
    if (!props.tournamentsLoading && props.tournaments && props.tournaments.length > 0)
      setTournamentFilters(props.tournaments.map(tournament => ({
        text: tournament.name,
        value: tournament.id,
      })))
  }, [props.tournamentsLoading])

  const [roundFilters, setRoundFilters] = useState([])
  const loadRoundFilters = async () => {
    delete tableFilters['roundId']
    await axios
      .get(`/api/management/tournaments/open-rounds?key=upcoming`)
      .then(({ data }) => {
          setRoundFilters(data.map(round => ({
            label: round.label,
            value: round.id,
          })))
        },
      )
  }

  const [sessionFilters, setSessionFilters] = useState([])
  const loadSessionFilters = async () => {
    delete tableFilters['sessionId']
    await axios
      .get(`/api/management/tournaments/open-sessions?key=upcoming`)
      .then(({ data }) => {
          setSessionFilters(data.map(session => ({
            label: <TextFormat type="date" format={APP_DATETIME_FORMAT_EU} value={session}/>,
            value: session,
          })))
        },
      )
  }

  const loadEntities = () => {
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
    setConfirmAssignShotClockLoading(false)
  }

  const loadTournamentFilters = () => {
    props.getTournaments(0, 25, 'name,desc')
  }

  const [selectedMatches, setSelectedMatches] = useState([])

  const handleSelectChange = (selectedRowKeys) => {
    setSelectedMatches(selectedRowKeys)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  const showAssignTable = (matchId: number) => {
    history.push(`${props.match.url}/${matchId}/assignTable`)
  }

  const startTeamMainMatch = (matchId: number) => {
    axios
      .post(`/api/management/announced-matches/startTeamMainMatch?matchId=${matchId}`)
      .then(loadEntities)
      .catch(reason => {
        message.error(`There was an error starting the team main match: ${reason}`)
      })
  }

  const [confirmAssignShotClockLoading, setConfirmAssignShotClockLoading] = useState(false)

  const handleRoundFilterChange = (selectedRoundId) => {
    // console.log('Round Filter Event', selectedRoundId)
    if (typeof selectedRoundId === 'number') {
      tableFilters['roundId'] = selectedRoundId
      setTableFilters({
        ...tableFilters,
      })
    } else {
      delete tableFilters['roundId']
      setTableFilters({ ...tableFilters })
    }
    loadEntities()
  }

  const handleSessionFilterChange = (selectedSession) => {
    // console.log('Session Filter Event', selectedSession)
    if (selectedSession !== undefined) {
      tableFilters['matchScheduledTime'] = selectedSession
      setTableFilters({
        ...tableFilters,
      })
    } else {
      delete tableFilters['matchScheduledTime']
      setTableFilters({ ...tableFilters })
    }
    loadEntities()
  }

  const [qualifierForm] = Form.useForm()
  const handleQualifier = (matchId: number, aOrB: string) => {
    history.push(`${props.match.url}/${matchId}/${aOrB}/editQualifierMatch`)
  }
  const showEditMatchModal = (matchId: number) => {
    history.push(`${props.match.url}/${matchId}/editMatch`)
  }

  useEffect(() => {
    if (props.entities && props.entities.length > 0)
      setNumberOfTeamMatches(props.entities.flat().filter(value => value.disciplineMnemonic === 'Team').length)
  }, [props.entities])

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24} style={{ textAlign: 'right', marginBottom: 8 }}>
        <Space>
          <Select allowClear
                  placeholder="Round Filter"
                  style={{ width: 200 }}
                  notFoundContent={'no more rounds to play'}
                  options={roundFilters}
                  onChange={handleRoundFilterChange}
                  defaultValue={null}
          />
          <Select allowClear
                  placeholder="Session Filter"
                  style={{ width: 200 }}
                  notFoundContent={'no more sessions to play'}
                  options={sessionFilters}
                  onChange={handleSessionFilterChange}
                  defaultValue={null}
          />
          <Tooltip placement="top" title="Refresh List">
            <Button icon={<ReloadOutlined style={{ fontSize: 24 }}/>} type="text" onClick={loadEntities}/>
          </Tooltip>
        </Space>
      </Col>
      <Col span={24}>
        <Table<ITournamentMatchExtended> id={'tournamentMatchesTable'} rowKey={'id'} loading={props.loading}
                                         size={'small'}
                                         showSorterTooltip
                                         dataSource={props.entities.flat()}
                                         rowSelection={{
                                           selectedRowKeys: selectedMatches,
                                           onChange: handleSelectChange,
                                         }}
                                         onChange={handleTableChange}
                                         pagination={{
                                           position: ['topRight', 'bottomRight'],
                                           defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                                           total: props.totalItems,
                                           pageSize: paginationState.itemsPerPage,
                                           showTotal: total => `Total ${total - numberOfTeamMatches} matches`,
                                         }}
        >
          <Table.Column<ITournamentMatchExtended> dataIndex={'label'} sorter title={'Label'} align={'center'}
                                                  render={(value, row) => (
                                                    <Tooltip title={'edit match'}
                                                             placement={'rightTop'}>
                                                      <Button type={'link'}
                                                              onClick={event => showEditMatchModal(row.id)}>
                                                        {row.roundMnemonic}-{value}
                                                      </Button>
                                                    </Tooltip>

                                                  )}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'matchScheduledTime'} sorter
                                                  title={'Scheduled Time'}
                                                  align={'center'}
                                                  defaultSortOrder={'ascend'}
                                                  render={(value) => (
                                                    <TextFormat type={'date'} value={value}
                                                                format={APP_DATETIME_FORMAT_EU}
                                                                blankOnInvalid/>
                                                  )}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'disciplineMnemonic'} title={'D'} align={'center'}
                                                  render={(value, row) => (
                                                    <Tooltip title={row.disciplineName}>{row.disciplineMnemonic}</Tooltip>
                                                  )}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'raceTo'} title={'Race To'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'participantADisplayName'} title={'Player A'} render={(value, row) =>
            row.aPlaceholder ? (
              <Button size="small" type="primary" block onClick={() => handleQualifier(row.id, 'a')}>Qualifier</Button>
            ) : value ? (
              <span><img style={{ height: '18px' }} src={'data:' + row.participantAFlagContentType + ';base64,' + row.participantAFlagImg}/> {value}</span>
            ) : 'waiting for player'
          }/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'aScore'} title={'Score A'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'bScore'} title={'Score B'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'participantBDisplayName'} title={'Player B'} render={(value, row) =>
            row.bPlaceholder ? (
              <Button size="small" type="primary" block onClick={() => handleQualifier(row.id, 'b')}>Qualifier</Button>
            ) : value ? (
              <span><img style={{ height: '18px' }} src={'data:' + row.participantBFlagContentType + ';base64,' + row.participantBFlagImg}/> {value}</span>
            ) : 'waiting for player'
          }/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'tournamentId'} title={'Tournament'}
                                                  filters={tournamentFilters}
                                                  render={(value, row) => row.tournamentMnemonic}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'id'} title={''}
                                                  render={(value, row) => {
                                                    return <Space>
                                                      {row.matchStatus === MatchStatus.SCHEDULED && row.participantAId && row.participantBId ?
                                                        (row.disciplineMnemonic === 'Team') ?
                                                          row.teamParentMatchId === null ?
                                                            <Tooltip title={'start team match'} placement={'leftTop'}>
                                                              <Popconfirm title={'Start Main Team Match? Status will change to "Running"'} onConfirm={() => {
                                                                startTeamMainMatch(value)
                                                              }}>
                                                                <PlayCircleTwoTone twoToneColor="#52c41a"
                                                                                   style={{
                                                                                     fontSize: 24,
                                                                                     paddingRight: 5,
                                                                                   }}
                                                                />
                                                              </Popconfirm>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title={'assign table'} placement={'leftTop'}>
                                                              <PlusSquareTwoTone twoToneColor="#52c41a"
                                                                                 style={{
                                                                                   fontSize: 24,
                                                                                   paddingRight: 5,
                                                                                 }}
                                                                                 onClick={() => showAssignTable(value)}
                                                              />
                                                            </Tooltip>
                                                          :
                                                          <Tooltip title={'assign table'} placement={'leftTop'}>
                                                            <PlusSquareTwoTone twoToneColor="#52c41a"
                                                                               style={{
                                                                                 fontSize: 24,
                                                                                 paddingRight: 5,
                                                                               }}
                                                                               onClick={() => showAssignTable(value)}
                                                            />
                                                          </Tooltip>
                                                        : null}
                                                    </Space>
                                                  }}/>
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ authentication, tournamentMatchExtended, tournamentsRunning }: IAppState) => ({
  loading: tournamentMatchExtended.loading,
  entities: tournamentMatchExtended.entities,
  totalItems: tournamentMatchExtended.totalItems,
  tournaments: tournamentsRunning.entities,
  tournamentsLoading: tournamentsRunning.loading,
  account: authentication.account,
})

const mapDispatchToProps = {
  getEntities,
  getTournaments,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MatchesUpcoming)