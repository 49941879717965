import { defaultValue as parentDefaultValue, ITournament } from '../tournament.model'
import { TournamentStatus } from '../enumerations/tournament-status.model'

export interface ITournamentExtended extends ITournament {
  numberOfParticipants?: number;
  readyForNextStageDraw?: boolean;
  readyToFinish?: boolean;
  maxParticipants?: number;
  minParticipants?: number;
}

export const defaultValue: Readonly<ITournamentExtended> = {
  ...parentDefaultValue,
  tournamentStatus: TournamentStatus.CREATED,
  readyForNextStageDraw: false,
  readyToFinish: false,
  entryFee1: 150,
  entryFee2: 0,
  entryFee3: 0,
  entryFeeReduced: 100,
  entryFeeReduced2: 0,
  maxParticipants: 0,
  numberOfTeamMatches: 0,
}
