import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { createEntity, getEntity, reset, setBlob, updateEntity } from '../../../shared/reducers/model/hotel.reducer'

import { Button, Col, Divider, Form, Input, message, Row, Select, Space, Switch, Upload } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getEntities as getCountries } from '../../../shared/reducers/model/country.reducer'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import './hotel-update.scss'
import { HotelType } from '../../../shared/model/enumerations/hotel-type.model'
import { EventStatus } from '../../../shared/model/enumerations/event-status.model'
import { translate } from 'react-jhipster'

export interface IHotelUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const HotelUpdate = (props: IHotelUpdateProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const { entity, onSetPage, getPageData } = props
  const [submitLoading, setLoadingSubmit] = useState(false)

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const pageData: IPageData = {
    title: `Hotel - ${!isNew ? `${entity.name} [${entity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Hotels',
        route: '/app/management/hotels',
      },
      {
        title: !isNew ? `Hotel: ${entity.name}` : 'Create new Hotel',
      },
    ],
  }

  useEffect(() => {
    onSetPage(pageData)
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    props.getCountries(0, 999, 'name')
    // Next Line is preventing Chrome and Firefox autofill/autocomplete feature nightmare
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopDamnAutocomplete')
      //you can put any value but NOT "off" or "false" because they DO NOT works
    })
    return () => {
      props.reset()
    }
  }, [])

  useEffect(() => {
    if (!props.loading && !isNew && entity?.id) {
      let formValues = {
        ...entity,
      }
      setFieldsValue(formValues)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/app/management/hotels')
    }
  }, [props.updateSuccess])

  const [availableCountries, setAvailableCountries] = useState([])
  useEffect(() => {
    if (!props.countriesLoading) {
      setAvailableCountries(props.countries.map(country => ({
        value: country.id,
        label: country.name,
      })))
    }
  }, [props.countriesLoading])

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...entity,
          ...values,
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...entity,
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleOnEditorChange = (value, delta, source, editor) => {
    // console.log(value, delta, source, editor)
    setFieldsValue({ rates: value })
  }
  return (
    <Form form={form} id={'updateHotel'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
          autoComplete={'off'}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleFormOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="name" label={'Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 80 }]}>
            <Input autoComplete={'name'}/>
          </Form.Item>
          <Form.Item name="shortName" label={'Short-Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 80 }]}>
            <Input autoComplete={'shortName'}/>
          </Form.Item>
          <Form.Item name="type" label={'Type'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Select
              options={Object.keys(HotelType).map(key => ({
                text: translate(`bitsbutlerApp.HotelType.${HotelType[key]}`),
                value: key,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="street" label={'Street'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
          <Form.Item name="postcode" label={'Postcode'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
          <Form.Item name="city" label={'City'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 80 }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="countryId" label="Country" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.countriesLoading}
              options={availableCountries}
            />
          </Form.Item>
        </Col>
        <Divider/>
        <Col span={12}>
          <Form.Item name="website" label={'Website'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
          <Form.Item name="email" label={'Email'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone1" label={'Phone 1'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
          <Form.Item name="phone2" label={'Phone 2'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
          <Form.Item name="fax" label={'Fax'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Divider/>
        <Col span={8}>
          <Form.Item name="priceSingle" label={'Single room'} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="priceSingle" label={'Double room'} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="priceSingle" label={'Triple room'} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Divider/>
        <Col span={12}>
          <Form.Item name="airport" label={'Airport'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="rates" hidden>
            <Input type="text"/>
          </Form.Item>
          <Form.Item name="rates" label="Rates" labelCol={{ span: 4 }}>
            <ReactQuill id="ratesEditor" theme={'snow'} value={entity.rates}
                        onChange={handleOnEditorChange}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Link to={'../hotels'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entity: storeState.hotel.entity,
  loading: storeState.hotel.loading,
  updating: storeState.hotel.updating,
  updateSuccess: storeState.hotel.updateSuccess,
  countries: storeState.country.entities,
  countriesLoading: storeState.country.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setBlob,
  getCountries,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HotelUpdate))