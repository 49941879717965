import { applyMiddleware, combineReducers, compose, createStore } from 'redux'

import { createHashHistory } from 'history'
import settingsReducer, { SettingsReducer } from './settings/reducer'
import pageDataReducer, { PageDataReducer } from './pages/reducer'
import { connectRouter, RouterState } from 'connected-react-router'
import authentication, { AuthenticationState } from '../shared/reducers/authentication'
// import locale, { LocaleState } from '../shared/reducers/locale'
import applicationProfile, { ApplicationProfileState } from '../shared/reducers/application-profile'
import promiseMiddleware from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'

import billiardTable, { BilliardTableState } from '../shared/reducers/model/billiard-table.reducer'
import billiardTableExtended, { BilliardTableExtendedState } from '../shared/reducers/model/extended/billiard-table.extended.reducer'
import boardMember, { BoardMemberState } from '../shared/reducers/model/board-member.reducer'
import boardMemberType, { BoardMemberTypeState } from '../shared/reducers/model/board-member-type.reducer'
import calendarItem, { CalendarItemState } from '../shared/reducers/model/calendar-item.reducer'
import chartTemplate, { ChartTemplateState } from '../shared/reducers/model/chart-template.reducer'
import chartTemplateMatch, { ChartTemplateMatchState } from '../shared/reducers/model/chart-template-match.reducer'
import chartTemplateRound, { ChartTemplateRoundState } from '../shared/reducers/model/chart-template-round.reducer'
import chartTemplateTimeSession, { ChartTemplateTimeSessionState } from '../shared/reducers/model/chart-template-time-session.reducer'
import chartTemplateTimeSessionMatches, { ChartTemplateTimeSessionMatchesState } from '../shared/reducers/model/chart-template-time-session-matches.reducer'
import continent, { ContinentState } from '../shared/reducers/model/continent.reducer'
import country, { CountryState } from '../shared/reducers/model/country.reducer'
import discipline, { DisciplineState } from '../shared/reducers/model/discipline.reducer'
import document, { DocumentState } from '../shared/reducers/model/document.reducer'
import event, { EventState } from '../shared/reducers/model/event.reducer'
import eventPoster, { EventPosterState } from '../shared/reducers/model/event-poster.reducer'
import federation, { FederationState } from '../shared/reducers/model/federation.reducer'
import hotel, { HotelState } from '../shared/reducers/model/hotel.reducer'
import invoice, { InvoiceState } from '../shared/reducers/model/invoice.reducer'
import invoiceItem, { InvoiceItemState } from '../shared/reducers/model/invoice-item.reducer'
import invoiceItemTemplate, { InvoiceItemTemplateState } from '../shared/reducers/model/invoice-item-template.reducer'
import invoiceTemplate, { InvoiceTemplateState } from '../shared/reducers/model/invoice-template.reducer'
import mailTemplate, { MailTemplateState } from '../shared/reducers/model/mail-template.reducer'
import mailTemplateAttachment, { MailTemplateAttachmentState } from '../shared/reducers/model/mail-template-attachment.reducer'
import matchProgressEntry, { MatchProgressEntryState } from '../shared/reducers/model/match-progress-entry.reducer'
import nationality, { NationalityState } from '../shared/reducers/model/nationality.reducer'
import newsAttachment, { NewsAttachmentState } from '../shared/reducers/model/news-attachment.reducer'
import newsPost, { NewsPostState } from '../shared/reducers/model/news-post.reducer'
import notification, { NotificationState } from '../shared/reducers/model/notification.reducer'
import overallRanking, { OverallRankingState } from '../shared/reducers/model/overall-ranking.reducer'
import participant, { ParticipantState } from '../shared/reducers/model/participant.reducer'
import participantWarning, { ParticipantWarningState } from '../shared/reducers/model/participant-warning.reducer'
import personalBalanceHistory, { PersonalBalanceHistoryState } from '../shared/reducers/model/personal-balance-history.reducer'
import playerProfile, { PlayerProfileState } from '../shared/reducers/model/player-profile.reducer'
import playerCategory, { PlayerCategoryState } from '../shared/reducers/model/player-category.reducer'
import playerOverallRanking, { PlayerOverallRankingState } from '../shared/reducers/model/player-overall-ranking.reducer'
import ranking, { RankingState } from '../shared/reducers/model/ranking.reducer'
import rankingBreakdown, { RankingBreakdownState } from '../shared/reducers/model/ranking-breakdown.reducer'
import season, { SeasonState } from '../shared/reducers/model/season.reducer'
import sponsor, { SponsorState } from '../shared/reducers/model/sponsor.reducer'
import sponsorForEvent, { SponsorForEventState } from '../shared/reducers/model/sponsor-for-event.reducer'
import tag, { TagState } from '../shared/reducers/model/tag.reducer'
import tournamentExtended, { TournamentExtendedState } from '../shared/reducers/model/extended/tournament.extended.reducer'
import tournamentCategory, { TournamentCategoryState } from '../shared/reducers/model/tournament-category.reducer'
import tournamentMatch, { TournamentMatchState } from '../shared/reducers/model/tournament-match.reducer'
import tournamentMatchExtended, { TournamentMatchExtendedState } from '../shared/reducers/model/extended/tournament-match.extended.reducer'
import userManagement, { UserManagementState } from '../shared/reducers/model/user-management.reducer'
import venue, { VenueState } from '../shared/reducers/model/venue.reducer'
import warningTemplates, { WarningTemplatesState } from '../shared/reducers/model/warning-templates.reducer'
import websiteContent, { WebsiteContentState } from '../shared/reducers/model/website-content.reducer'
import websiteContentArea, { WebsiteContentAreaState } from '../shared/reducers/model/website-content-area.reducer'
import matchesAnnounced, { MatchesAnnouncedState } from '../pages/matches/matches/matches-announced-match.reducer'
import matchesUpcoming, { MatchesUpcomingState } from '../pages/matches/matches/matches-upcoming-match.reducer'
import tournamentsRunning, { TournamentsRunningState } from '../pages/tournament/tournaments-running.reducer'
import tournamentsUpcoming, { TournamentsUpcomingState } from '../pages/tournament/tournaments-upcoming.reducer'
import tournamentsArchived, { TournamentsArchivedState } from '../pages/tournament/tournaments-archived.reducer'
import tournamentParticipants, { TournamentParticipantState } from '../pages/tournament/tournament-participants.reducer'

export interface IAppState {
  readonly authentication: AuthenticationState;
  // readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly router: RouterState;
  readonly settings: SettingsReducer;
  readonly pageData: PageDataReducer;

  /* ENTITIES */
  readonly billiardTable: BilliardTableState;
  readonly billiardTableExtended: BilliardTableExtendedState
  readonly boardMember: BoardMemberState;
  readonly boardMemberType: BoardMemberTypeState;
  readonly calendarItem: CalendarItemState;
  readonly chartTemplate: ChartTemplateState;
  readonly chartTemplateMatch: ChartTemplateMatchState;
  readonly chartTemplateRound: ChartTemplateRoundState;
  readonly chartTemplateTimeSession: ChartTemplateTimeSessionState;
  readonly chartTemplateTimeSessionMatches: ChartTemplateTimeSessionMatchesState;
  readonly continent: ContinentState;
  readonly country: CountryState;
  readonly discipline: DisciplineState;
  readonly document: DocumentState;
  readonly event: EventState;
  readonly eventPoster: EventPosterState;
  readonly federation: FederationState;
  readonly hotel: HotelState;
  readonly invoice: InvoiceState;
  readonly invoiceItem: InvoiceItemState;
  readonly invoiceItemTemplate: InvoiceItemTemplateState;
  readonly invoiceTemplate: InvoiceTemplateState;
  readonly mailTemplate: MailTemplateState;
  readonly mailTemplateAttachment: MailTemplateAttachmentState;
  readonly matchProgressEntry: MatchProgressEntryState;
  readonly nationality: NationalityState;
  readonly newsAttachment: NewsAttachmentState;
  readonly newsPost: NewsPostState;
  readonly notification: NotificationState;
  readonly overallRanking: OverallRankingState;
  readonly participant: ParticipantState;
  readonly participantWarning: ParticipantWarningState;
  readonly personalBalanceHistory: PersonalBalanceHistoryState;
  readonly playerProfile: PlayerProfileState;
  readonly playerCategory: PlayerCategoryState;
  readonly playerOverallRanking: PlayerOverallRankingState;
  readonly ranking: RankingState;
  readonly rankingBreakdown: RankingBreakdownState;
  readonly season: SeasonState;
  readonly sponsor: SponsorState;
  readonly sponsorForEvent: SponsorForEventState;
  readonly tag: TagState;
  readonly tournamentExtended: TournamentExtendedState;
  readonly tournamentCategory: TournamentCategoryState;
  readonly tournamentMatch: TournamentMatchState;
  readonly tournamentMatchExtended: TournamentMatchExtendedState;
  readonly userManagement: UserManagementState;
  readonly venue: VenueState;
  readonly warningTemplates: WarningTemplatesState;
  readonly websiteContent: WebsiteContentState;
  readonly websiteContentArea: WebsiteContentAreaState;

  /* BACKEND */
  readonly matchesAnnounced: MatchesAnnouncedState;
  readonly matchesUpcoming: MatchesUpcomingState;
  readonly tournamentsRunning: TournamentsRunningState;
  readonly tournamentsUpcoming: TournamentsUpcomingState;
  readonly tournamentsArchived: TournamentsArchivedState;
  readonly tournamentParticipants: TournamentParticipantState;
}

export const history = createHashHistory()

const rootReducer = combineReducers<IAppState>({
  authentication,
  applicationProfile,
  router: connectRouter(history),
  settings: settingsReducer,
  pageData: pageDataReducer,

  /* ENTITIES */
  billiardTable,
  billiardTableExtended,
  boardMember,
  boardMemberType,
  calendarItem,
  chartTemplate,
  chartTemplateMatch,
  chartTemplateRound,
  chartTemplateTimeSession,
  chartTemplateTimeSessionMatches,
  continent,
  country,
  discipline,
  document,
  event,
  eventPoster,
  federation,
  hotel,
  invoice,
  invoiceItem,
  invoiceItemTemplate,
  invoiceTemplate,
  mailTemplate,
  mailTemplateAttachment,
  matchProgressEntry,
  nationality,
  newsAttachment,
  newsPost,
  notification,
  overallRanking,
  participant,
  participantWarning,
  personalBalanceHistory,
  playerProfile,
  playerCategory,
  playerOverallRanking,
  ranking,
  rankingBreakdown,
  season,
  sponsor,
  sponsorForEvent,
  tag,
  tournamentExtended,
  tournamentCategory,
  tournamentMatch,
  tournamentMatchExtended,
  userManagement,
  venue,
  warningTemplates,
  websiteContent,
  websiteContentArea,

  /* BACKEND */
  matchesAnnounced,
  matchesUpcoming,
  tournamentsRunning,
  tournamentsUpcoming,
  tournamentsArchived,
  tournamentParticipants,
})

const defaultMiddlewares = [
  thunkMiddleware,
  promiseMiddleware,
  // loggerMiddleware,
]
const composedMiddlewares = middlewares => compose(applyMiddleware(...defaultMiddlewares, ...middlewares))

export type AppState = ReturnType<typeof rootReducer>;

export const store = (initialState?: IAppState, middlewares = []) => createStore(rootReducer, initialState, composedMiddlewares(middlewares))

export default store