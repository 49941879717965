import React, { useEffect, useState } from 'react'
import { history, IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { APP_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'

import { Col, Row, Space, Table, Tooltip } from 'antd'
import { AuditOutlined } from '@ant-design/icons'
import Button from 'antd/es/button'

import { ITournamentMatchExtended } from '../../../shared/model/extended/tournament-match.extended.model'
import {
  getEntities,
  getEntity,
} from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'
import { getEntities as getTournaments } from '../../tournament/tournaments-running.reducer'
import { MatchStatus } from '../../../shared/model/enumerations/match-status.model'
import { TournamentStatus } from '../../../shared/model/enumerations/tournament-status.model'

export interface IMatchesApprovedProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const MatchesApproved = (props: IMatchesApprovedProps) => {

  const pageData: IPageData = {
    title: 'Approved Matches',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Matches',
      },
      {
        title: 'Approved',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'matchScheduledTime',
    order: 'desc',
  })

  const [tableFilters, setTableFilters] = useState({
    matchStatus: [MatchStatus.APPROVED],
    matchScheduledTime: true,
    aWalkover: false,
    bWalkover: false,
    tournamentStatus: [TournamentStatus.RUNNING],
  })

  const { onSetPage } = props
  useEffect(() => {
    onSetPage(pageData)
    loadEntities()
    loadTournamentFilters()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
    loadTournamentFilters()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const [tournamentFilters, setTournamentFilters] = useState([])
  useEffect(() => {
    if (!props.tournamentsLoading && props.tournaments && props.tournaments.length > 0)
      setTournamentFilters(props.tournaments.map(tournament => ({
        text: `${tournament.eventName}: ${tournament.name}`,
        value: tournament.id,
      })))
  }, [props.tournamentsLoading])

  const loadEntities = () => {
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
    setConfirmAssignShotClockLoading(false)
  }

  const loadTournamentFilters = () => {
    props.getTournaments(0, 25, 'name,desc')
  }

  const [selectedMatches, setSelectedMatches] = useState([])

  const handleSelectChange = (selectedRowKeys) => {
    setSelectedMatches(selectedRowKeys)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  const showMatchHistory = (matchId: number) => {
    history.push(`${props.match.url}/${matchId}/showHistory`)
  }

  const [confirmAssignShotClockLoading, setConfirmAssignShotClockLoading] = useState(false)

  const showEditMatchModal = (matchId: number) => {
    history.push(`${props.match.url}/${matchId}/editMatch`)
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24} style={{ textAlign: 'right' }}>
        {/*ACTIONS*/}
      </Col>
      <Col span={24}>
        <Table<ITournamentMatchExtended> id={'tournamentMatchesTable'} rowKey={'id'} loading={props.loading}
                                         size={'small'}
                                         showSorterTooltip
                                         dataSource={props.entities.flat()}
                                         rowSelection={{
                                           selectedRowKeys: selectedMatches,
                                           onChange: handleSelectChange,
                                         }}
                                         onChange={handleTableChange}
                                         pagination={{
                                           defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                                           total: props.totalItems,
                                           pageSize: paginationState.itemsPerPage,
                                         }}
        >
          <Table.Column<ITournamentMatchExtended> dataIndex={'label'} sorter title={'Label'} align={'center'}
                                                  render={(value, row) => (
                                                    <Tooltip title={'edit match'}
                                                             placement={'rightTop'}>
                                                      <Button type={'link'}
                                                              onClick={event => showEditMatchModal(row.id)}>
                                                        {row.roundMnemonic}-{value}
                                                      </Button>
                                                    </Tooltip>

                                                  )}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'previousAssignedTable'} title={'Table'} />
          <Table.Column<ITournamentMatchExtended> dataIndex={'matchScheduledTime'} sorter
                                                  title={'Scheduled Time'}
                                                  align={'center'}
                                                  defaultSortOrder={'descend'}
                                                  render={(value) => (
                                                    <TextFormat type={'date'} value={value}
                                                                format={APP_DATETIME_FORMAT_EU}
                                                                blankOnInvalid/>
                                                  )}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'matchStartTime'} sorter title={'Start Time'}
                                                  align={'center'}
                                                  render={(value) => value ? (
                                                    <TextFormat type="date" value={value}
                                                                format={APP_DATETIME_FORMAT_EU}/>
                                                  ) : 'not started'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'raceTo'} title={'Race To'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'participantADisplayName'} title={'Player A'} render={(value, row) => value ? (
            <span><img style={{height: '18px'}} src={'data:'+row.participantAFlagContentType+';base64,'+row.participantAFlagImg} /> {value}</span>
          ) : 'waiting for player'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'aScore'} title={'Score A'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'bScore'} title={'Score B'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'participantBDisplayName'} title={'Player B'} render={(value, row) => value ? (
            <span><img style={{height: '18px'}} src={'data:'+row.participantBFlagContentType+';base64,'+row.participantBFlagImg} /> {value}</span>
          ) : 'waiting for player'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'tournamentId'} title={'Tournament'}
                                                  filters={tournamentFilters}
                                                  render={(value, row) => row.tournamentMnemonic}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'id'} title={''}
                                                  render={(value) => {
                                                    return <Space>
                                                      <Tooltip title={'open match history'}
                                                               placement={'leftTop'}>
                                                        <Button type={'text'} shape={'circle'}
                                                                icon={<AuditOutlined/>}
                                                                style={{ color: 'black' }}
                                                                onClick={event => showMatchHistory(value)}/>
                                                      </Tooltip>
                                                    </Space>
                                                  }}/>
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ authentication, tournamentMatchExtended, tournamentsRunning }: IAppState) => ({
  loading: tournamentMatchExtended.loading,
  entities: tournamentMatchExtended.entities,
  totalItems: tournamentMatchExtended.totalItems,
  tournaments: tournamentsRunning.entities,
  tournamentsLoading: tournamentsRunning.loading,
  account: authentication.account,
})

const mapDispatchToProps = {
  getEntity,
  getEntities,
  getTournaments,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MatchesApproved)