import { Moment } from 'moment';
import { IEvent } from './event.model';
import { SeasonStatus } from './enumerations/season-status.model';

export interface ISeason {
  id?: number;
  name?: string;
  seasonStartDate?: string;
  seasonEndDate?: string;
  seasonYear?: string;
  seasonStatus?: SeasonStatus;
  events?: IEvent[];
}

export const defaultValue: Readonly<ISeason> = {};
