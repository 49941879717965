import React from 'react'
import { NavLink, RouteComponentProps, Redirect } from 'react-router-dom'

import './SignIn.scss'
import { IAppState } from '../../../redux/store'
import { login } from '../../../shared/reducers/authentication'
import { connect } from 'react-redux'
import SingInForm from './SignInForm'

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
}

export const PageSignIn = (props: ILoginProps) => {

  const handleLogin = (username, password, rememberMe = false) => props.login(username, password, rememberMe)

  const { isAuthenticated } = props
  if (isAuthenticated) {
    return <Redirect to='/'/>
  }


  return (
    <>
      <div className='title-block'>
        <h5 className='form-title'>Sign In</h5>
        <NavLink to='/public/sign-up' className='link right'>New user ?</NavLink>
      </div>
      <SingInForm handleLogin={handleLogin} loginError={props.loginError}/>
    </>
  )
}

const mapStateToProps = ({ authentication }: IAppState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
})

const mapDispatchToProps = { login }

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PageSignIn)
