import { IFederation } from './federation.model';

export interface ICountry {
  id?: number;
  name?: string;
  iso3?: string;
  ioc?: string;
  currency?: string;
  currencyCode?: string;
  enabled?: boolean;
  flag?: string;
  flagImgContentType?: string;
  flagImg?: any;
  capital?: string;
  latlng?: string;
  federations?: IFederation[];
  continentName?: string;
  continentId?: number;
}

export const defaultValue: Readonly<ICountry> = {
  enabled: false,
};
