import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster'

import { cleanEntity } from '../../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util'

import { defaultValue, ITournamentExtended } from '../../../model/extended/tournament.extended.model'
import { TournamentStatus } from '../../../model/enumerations/tournament-status.model'
import { message, Modal } from 'antd'
import { saveAs } from 'file-saver'

export const ACTION_TYPES = {
  FETCH_TOURNAMENT_LIST: 'tournamentExtended/FETCH_TOURNAMENT_LIST',
  FETCH_TOURNAMENT: 'tournamentExtended/FETCH_TOURNAMENT',
  CREATE_TOURNAMENT: 'tournamentExtended/CREATE_TOURNAMENT',
  UPDATE_TOURNAMENT: 'tournamentExtended/UPDATE_TOURNAMENT',
  DELETE_TOURNAMENT: 'tournamentExtended/DELETE_TOURNAMENT',
  SET_BLOB: 'tournamentExtended/SET_BLOB',
  RESET: 'tournamentExtended/RESET',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITournamentExtended>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

let filterState = {
  filters: null,
  page: 0,
  size: 20,
  sort: null,
}

export type TournamentExtendedState = Readonly<typeof initialState>;

// Reducer

export default (state: TournamentExtendedState = initialState, action): TournamentExtendedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.CREATE_TOURNAMENT):
    case REQUEST(ACTION_TYPES.UPDATE_TOURNAMENT):
    case REQUEST(ACTION_TYPES.DELETE_TOURNAMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENT):
    case FAILURE(ACTION_TYPES.CREATE_TOURNAMENT):
    case FAILURE(ACTION_TYPES.UPDATE_TOURNAMENT):
    case FAILURE(ACTION_TYPES.DELETE_TOURNAMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.CREATE_TOURNAMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_TOURNAMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.DELETE_TOURNAMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      }
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      }
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
};

const apiUrl = 'api/tournaments'
const apiUrlGlobal = 'api/tournaments'

// Actions

export const getEntities: ICrudGetAllAction<ITournamentExtended> = (page, size, sort, filters?: {}) => {
  filterState = {
    filters, page: page || 0, size: size || 10, sort: sort || null,
  }
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  if (filters) {
    if (filters['name']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}name.contains=${filters['name']}`
    }
    if (filters['eventId']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}eventId.in=${filters['eventId'].join('&eventId.in=')}`
    }
    if (filters['disciplineId']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}disciplineId.in=${filters['disciplineId'].join('&disciplineId.in=')}`
    }
    if (filters['tournamentCategoryId']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}tournamentCategoryId.in=${filters['tournamentCategoryId'].join('&tournamentCategoryId.in=')}`
    }
    if (filters['tournamentStatus']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}tournamentStatus.in=${filters['tournamentStatus'].join('&tournamentStatus.in=')}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENT_LIST,
    payload: axios.get<ITournamentExtended>(`${requestUrl}${sort ? '&' : '?'}extended=true&cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<ITournamentExtended> = id => {
  const requestUrl = `${apiUrlGlobal}/${id}?extended=true`
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENT,
    payload: axios.get<ITournamentExtended>(requestUrl),
  }
}

export const createEntity: ICrudPutAction<ITournamentExtended> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TOURNAMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  })
  // @ts-ignore
  dispatch(getEntities(filterState.page, filterState.size, filterState.sort, filterState.filters))
  return result
}

export const updateEntity: ICrudPutAction<ITournamentExtended> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TOURNAMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  // @ts-ignore
  dispatch(getEntities(filterState.page, filterState.size, filterState.sort, filterState.filters))
  return result
}

export const deleteEntity: ICrudDeleteAction<ITournamentExtended> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}?extended=true&`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TOURNAMENT,
    payload: axios.delete(requestUrl),
  })
  // @ts-ignore
  dispatch(getEntities(filterState.page, filterState.size, filterState.sort, filterState.filters))
  return result
}

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
})

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})

export const getRunningEntities: ICrudGetAllAction<ITournamentExtended> = (page, size, sort, filters?: {}) => {
  filterState = {
    filters, page: page || 0, size: size || 10, sort: sort || null,
  }
  filters = {
    tournamentStatus: [TournamentStatus.RUNNING, TournamentStatus.GENERATED, TournamentStatus.FINISHED],
    ...filters,
  }
  // @ts-ignore
  return getEntities(page, size, sort, filters)
}

export const getUpcomingEntities: ICrudGetAllAction<ITournamentExtended> = (page, size, sort, filters?: {}) => {
  filterState = {
    filters, page: page || 0, size: size || 10, sort: sort || null,
  }
  filters = {
    tournamentStatus: [TournamentStatus.CREATED],
    ...filters,
  }
  // @ts-ignore
  return getEntities(page, size, sort, filters)
}

export const getArchiveEntities: ICrudGetAllAction<ITournamentExtended> = (page, size, sort, filters?: {}) => {
  filterState = {
    filters, page: page || 0, size: size || 10, sort: sort || null,
  }
  filters = {
    tournamentStatus: [TournamentStatus.APPROVED],
    ...filters,
  }
  // @ts-ignore
  return getEntities(page, size, sort, filters)
}

export const getRankingPdf = (id: number, tournamentName: string) => {
  let secondsToGo = 7
  const modal = Modal.success({
    title: 'Prepare ranking pdf',
    content: `Download starting soon.`,
    okButtonProps: { style: { display: 'none' } },
  })
  axios.get(`api/management/tournaments/${id}/ranking.pdf?cacheBuster=${new Date().getTime()}`, {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf',
    },
  })
    .then((response) => {
      // response.data is an empty object
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      })
      saveAs(blob, `Ranking - ${tournamentName}.pdf`)
    })
    .catch(reason => {
      message.error('Creating invoice failed')
    })
    .finally(() => {
      setTimeout(() => {
        modal.destroy()
      }, 500)
    })
}
