export enum InvoicePaymentType {
  CASH_PAYMENT = 'Cash payment',

  BANK_TRANSFER = 'Bank transfer',

  PAYMENT_PROVIDER = 'Payment service provider',

  INVITATION = 'Invitation',

  WILDCARD = 'Wildcard',

  REFUND = 'Refund',

  PRIZE_MONEY_BALANCE = 'Prize money balance',

  PERSONAL_BALANCE = 'Personal balance',
}
