import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster'

import { cleanEntity } from '../../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util'

import { defaultValue, ITournamentMatchExtended } from '../../../model/extended/tournament-match.extended.model'

export const ACTION_TYPES = {
  FETCH_TOURNAMENTMATCH_EXTENDED_LIST: 'tournamentMatch/FETCH_TOURNAMENTMATCH_EXTENDED_LIST',
  FETCH_TOURNAMENTMATCH_EXTENDED: 'tournamentMatch/FETCH_TOURNAMENTMATCH_EXTENDED',
  CREATE_TOURNAMENTMATCH_EXTENDED: 'tournamentMatch/CREATE_TOURNAMENTMATCH_EXTENDED',
  UPDATE_TOURNAMENTMATCH_EXTENDED: 'tournamentMatch/UPDATE_TOURNAMENTMATCH_EXTENDED',
  DELETE_TOURNAMENTMATCH_EXTENDED: 'tournamentMatch/DELETE_TOURNAMENTMATCH_EXTENDED',
  RESET: 'tournamentMatch/RESET',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITournamentMatchExtended>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type TournamentMatchExtendedState = Readonly<typeof initialState>;

// Reducer

export default (state: TournamentMatchExtendedState = initialState, action): TournamentMatchExtendedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.CREATE_TOURNAMENTMATCH_EXTENDED):
    case REQUEST(ACTION_TYPES.UPDATE_TOURNAMENTMATCH_EXTENDED):
    case REQUEST(ACTION_TYPES.DELETE_TOURNAMENTMATCH_EXTENDED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED):
    case FAILURE(ACTION_TYPES.CREATE_TOURNAMENTMATCH_EXTENDED):
    case FAILURE(ACTION_TYPES.UPDATE_TOURNAMENTMATCH_EXTENDED):
    case FAILURE(ACTION_TYPES.DELETE_TOURNAMENTMATCH_EXTENDED):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.CREATE_TOURNAMENTMATCH_EXTENDED):
    case SUCCESS(ACTION_TYPES.UPDATE_TOURNAMENTMATCH_EXTENDED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.DELETE_TOURNAMENTMATCH_EXTENDED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
};

const apiUrl = 'api/tournament-matches'

// Actions

export const getEntities: ICrudGetAllAction<ITournamentMatchExtended> = (page, size, sort, filters?: {}) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  if (filters) {
    // console.log(filters)
    if (filters['matchStatus']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}matchStatus.in=${filters['matchStatus'].join('&matchStatus.in=')}`
    }
    if (filters['tournamentStatus']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}tournamentStatus.in=${filters['tournamentStatus'].join('&tournamentStatus.in=')}`
    }
    if (filters['tournamentId']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}tournamentId.in=${filters['tournamentId'].join('&tournamentId.in=')}`
    }
    if (filters['matchScheduledTime'] && typeof filters['matchScheduledTime'] == 'boolean') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}matchScheduledTime.specified=${filters['matchScheduledTime']}`
    }
    if (filters['matchScheduledTime'] && typeof filters['matchScheduledTime'] == 'string') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}matchScheduledTime.equals=${filters['matchScheduledTime']}`
    }
    if (filters['roundId'] && typeof filters['roundId'] === 'number') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}roundId.equals=${filters['roundId']}`
    }
    if (filters['eventId'] && typeof filters['eventId'] === 'number') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}eventId.equals=${filters['eventId']}`
    }
    if (typeof filters['aWalkover'] === 'boolean') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}aWalkover.equals=${filters['aWalkover']}`
    }
    if (typeof filters['bWalkover'] === 'boolean') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}bWalkover.equals=${filters['bWalkover']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED_LIST,
    payload: axios.get<ITournamentMatchExtended>(`${requestUrl}${sort ? '&' : '?'}extended=true&cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<ITournamentMatchExtended> = id => {
  const requestUrl = `${apiUrl}/${id}?extended=true`
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENTMATCH_EXTENDED,
    payload: axios.get<ITournamentMatchExtended>(requestUrl),
  }
}

export const createEntity: ICrudPutAction<ITournamentMatchExtended> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TOURNAMENTMATCH_EXTENDED,
    payload: axios.post(`${apiUrl}?extended=true`, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}

export const updateEntity: ICrudPutAction<ITournamentMatchExtended> = (entity, action?: string) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TOURNAMENTMATCH_EXTENDED,
    payload: axios.put(`${apiUrl}?extended=true${action ? '&action=' + action : ''}`, cleanEntity(entity)),
  })
  return result
}

export const deleteEntity: ICrudDeleteAction<ITournamentMatchExtended> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}?extended=true`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TOURNAMENTMATCH_EXTENDED,
    payload: axios.delete(requestUrl),
  })
  dispatch(getEntities())
  return result
}

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})
