import { Moment } from 'moment';
import { MatchProgressEntryType } from './enumerations/match-progress-entry-type.model';
import { MatchProgressTarget } from './enumerations/match-progress-target.model';

export interface IMatchProgressEntry {
  id?: number;
  entryDate?: string;
  progressType?: MatchProgressEntryType;
  scoreA?: number;
  scoreB?: number;
  scoreChange?: number;
  target?: MatchProgressTarget;
  matchId?: number;
}

export const defaultValue: Readonly<IMatchProgressEntry> = {};
