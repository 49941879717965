import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { IPageProps } from '../../interfaces/page-data'
import EventsActive from './events-active'
import EventsArchive from './events-archive'
import EventUpdate from './event-update'

export interface IEventsIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IEventsIndexProps) => {

  return <>
    <Switch>
      <Route key='eCreate' exact path={'/app/events/:type/new'}
             render={() => (<EventUpdate {...props}/>)}/>
      <Route key='eUpdate' exact path={`/app/events/:type/:id`}
             render={() => (<EventUpdate {...props}/>)}/>
      <Route key='eListActive' path={'/app/events/active'} render={() => (<EventsActive {...props} />)}/>
      <Route key='eListArchive' path={'/app/events/archive'} render={() => (<EventsArchive {...props} />)}/>
    </Switch>
  </>
}

export default withRouter(connect()(Routes))