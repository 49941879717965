export interface INationality {
  id?: number;
  iso3?: string;
  ioc?: string;
  name?: string;
  flag?: string;
  flagImgContentType?: string;
  flagImg?: any;
}

export const defaultValue: Readonly<INationality> = {};
