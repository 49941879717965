import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { IPageProps } from '../../../interfaces/page-data'
import HotelsList from './hotels'
import HotelUpdate from './hotel-update'

export interface IHotelsIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IHotelsIndexProps) => {

  return <>
    <Switch>
      <Route key='vNew' path={'/app/management/hotels/new'} render={() => (<HotelUpdate {...props}/>)}/>
      <Route key='vUpdate' path={`/app/management/hotels/:id`} render={() => (<HotelUpdate {...props}/>)}/>
      <Route key='sList' path={'/app/management/hotels'} render={() => (<HotelsList {...props}/>)}/>
    </Switch>
  </>
}
export default withRouter(connect()(Routes))
