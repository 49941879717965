import { IRankingBreakdown } from './ranking-breakdown.model';
import { RankingCategory } from './enumerations/ranking-category.model';

export interface IRanking {
  id?: number;
  name?: string;
  totalPrizeMoney?: number;
  maxNumbersOfParticipants?: number;
  rankingCategory?: RankingCategory;
  breakdowns?: IRankingBreakdown[];
}

export const defaultValue: Readonly<IRanking> = {};
