import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IWebsiteContentArea } from '../../model/website-content-area.model'

export const ACTION_TYPES = {
  SEARCH_WEBSITECONTENTAREAS: 'websiteContentArea/SEARCH_WEBSITECONTENTAREAS',
  FETCH_WEBSITECONTENTAREA_LIST: 'websiteContentArea/FETCH_WEBSITECONTENTAREA_LIST',
  FETCH_WEBSITECONTENTAREA: 'websiteContentArea/FETCH_WEBSITECONTENTAREA',
  CREATE_WEBSITECONTENTAREA: 'websiteContentArea/CREATE_WEBSITECONTENTAREA',
  UPDATE_WEBSITECONTENTAREA: 'websiteContentArea/UPDATE_WEBSITECONTENTAREA',
  DELETE_WEBSITECONTENTAREA: 'websiteContentArea/DELETE_WEBSITECONTENTAREA',
  RESET: 'websiteContentArea/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IWebsiteContentArea>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type WebsiteContentAreaState = Readonly<typeof initialState>;

// Reducer

export default (state: WebsiteContentAreaState = initialState, action): WebsiteContentAreaState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_WEBSITECONTENTAREAS):
    case REQUEST(ACTION_TYPES.FETCH_WEBSITECONTENTAREA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_WEBSITECONTENTAREA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_WEBSITECONTENTAREA):
    case REQUEST(ACTION_TYPES.UPDATE_WEBSITECONTENTAREA):
    case REQUEST(ACTION_TYPES.DELETE_WEBSITECONTENTAREA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_WEBSITECONTENTAREAS):
    case FAILURE(ACTION_TYPES.FETCH_WEBSITECONTENTAREA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_WEBSITECONTENTAREA):
    case FAILURE(ACTION_TYPES.CREATE_WEBSITECONTENTAREA):
    case FAILURE(ACTION_TYPES.UPDATE_WEBSITECONTENTAREA):
    case FAILURE(ACTION_TYPES.DELETE_WEBSITECONTENTAREA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_WEBSITECONTENTAREAS):
    case SUCCESS(ACTION_TYPES.FETCH_WEBSITECONTENTAREA_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_WEBSITECONTENTAREA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_WEBSITECONTENTAREA):
    case SUCCESS(ACTION_TYPES.UPDATE_WEBSITECONTENTAREA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_WEBSITECONTENTAREA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/website-content-areas';
const apiSearchUrl = 'api/_search/website-content-areas';

// Actions

export const getSearchEntities: ICrudSearchAction<IWebsiteContentArea> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_WEBSITECONTENTAREAS,
  payload: axios.get<IWebsiteContentArea>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IWebsiteContentArea> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_WEBSITECONTENTAREA_LIST,
    payload: axios.get<IWebsiteContentArea>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IWebsiteContentArea> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_WEBSITECONTENTAREA,
    payload: axios.get<IWebsiteContentArea>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IWebsiteContentArea> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_WEBSITECONTENTAREA,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IWebsiteContentArea> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_WEBSITECONTENTAREA,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IWebsiteContentArea> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_WEBSITECONTENTAREA,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
