import { ICountry } from './country.model';

export interface IFederation {
  id?: number;
  mnemonic?: string;
  name?: string;
  enabled?: boolean;
  street?: string;
  postcode?: string;
  city?: string;
  website?: string;
  email?: string;
  childFederations?: IFederation[];
  parentFederationName?: string;
  parentFederationId?: number;
  countries?: ICountry[];
}

export const defaultValue: Readonly<IFederation> = {
  enabled: false,
};
