import axios from 'axios';
import {ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction} from 'react-jhipster';

import {cleanEntity} from '../../../utils/entity-utils';
import {REQUEST, SUCCESS, FAILURE} from '../action-type.util';

import {IMatchProgressEntry, defaultValue} from '../../model/match-progress-entry.model';

export const ACTION_TYPES = {
    SEARCH_MATCHPROGRESSENTRIES: 'matchProgressEntry/SEARCH_MATCHPROGRESSENTRIES',
    FETCH_MATCHPROGRESSENTRY_LIST: 'matchProgressEntry/FETCH_MATCHPROGRESSENTRY_LIST',
    FETCH_MATCHPROGRESSENTRY: 'matchProgressEntry/FETCH_MATCHPROGRESSENTRY',
    CREATE_MATCHPROGRESSENTRY: 'matchProgressEntry/CREATE_MATCHPROGRESSENTRY',
    UPDATE_MATCHPROGRESSENTRY: 'matchProgressEntry/UPDATE_MATCHPROGRESSENTRY',
    DELETE_MATCHPROGRESSENTRY: 'matchProgressEntry/DELETE_MATCHPROGRESSENTRY',
    RESET: 'matchProgressEntry/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IMatchProgressEntry>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type MatchProgressEntryState = Readonly<typeof initialState>;

// Reducer

export default (state: MatchProgressEntryState = initialState, action): MatchProgressEntryState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.SEARCH_MATCHPROGRESSENTRIES):
        case REQUEST(ACTION_TYPES.FETCH_MATCHPROGRESSENTRY_LIST):
        case REQUEST(ACTION_TYPES.FETCH_MATCHPROGRESSENTRY):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_MATCHPROGRESSENTRY):
        case REQUEST(ACTION_TYPES.UPDATE_MATCHPROGRESSENTRY):
        case REQUEST(ACTION_TYPES.DELETE_MATCHPROGRESSENTRY):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.SEARCH_MATCHPROGRESSENTRIES):
        case FAILURE(ACTION_TYPES.FETCH_MATCHPROGRESSENTRY_LIST):
        case FAILURE(ACTION_TYPES.FETCH_MATCHPROGRESSENTRY):
        case FAILURE(ACTION_TYPES.CREATE_MATCHPROGRESSENTRY):
        case FAILURE(ACTION_TYPES.UPDATE_MATCHPROGRESSENTRY):
        case FAILURE(ACTION_TYPES.DELETE_MATCHPROGRESSENTRY):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.SEARCH_MATCHPROGRESSENTRIES):
        case SUCCESS(ACTION_TYPES.FETCH_MATCHPROGRESSENTRY_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_MATCHPROGRESSENTRY):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_MATCHPROGRESSENTRY):
        case SUCCESS(ACTION_TYPES.UPDATE_MATCHPROGRESSENTRY):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_MATCHPROGRESSENTRY):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/match-progress-entries';
const apiSearchUrl = 'api/_search/match-progress-entries';

// Actions

export const getSearchEntities: ICrudSearchAction<IMatchProgressEntry> = (query, page, size, sort) => ({
    type: ACTION_TYPES.SEARCH_MATCHPROGRESSENTRIES,
    payload: axios.get<IMatchProgressEntry>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IMatchProgressEntry> = (page, size, sort, filters?: {}) => {
    let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    if (filters) {
        if (filters['matchId'] && typeof filters['matchId'] == 'number') {
            requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}matchId=${filters['matchId']}`
        }
    }
    return {
        type: ACTION_TYPES.FETCH_MATCHPROGRESSENTRY_LIST,
        payload: axios.get<IMatchProgressEntry>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
    };
};

export const getEntity: ICrudGetAction<IMatchProgressEntry> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_MATCHPROGRESSENTRY,
        payload: axios.get<IMatchProgressEntry>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IMatchProgressEntry> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_MATCHPROGRESSENTRY,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<IMatchProgressEntry> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_MATCHPROGRESSENTRY,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<IMatchProgressEntry> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_MATCHPROGRESSENTRY,
        payload: axios.delete(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
