import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { IPageProps } from '../../../../interfaces/page-data'

import WebsiteNewsPosts from './website-news-posts'
import WebsiteNewsPostEdit from './website-news-posts-edit'
import WebsiteNewsPostDeleteDialog from './'
import UploadAttachmentModal from './website-news-posts-upload-dialog'

export interface IWebsiteNewsPostIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IWebsiteNewsPostIndexProps) => {

  return <>
    <Switch>
      <Route key='nCreate' exact path={`/app/public/website/news/new`}
             render={() => (<WebsiteNewsPostEdit {...props}  />)}/>
      <Route key='nEdit' exact path={[`/app/public/website/news/:id`,`/app/public/website/news/:id/upload`]}
             render={() => (<WebsiteNewsPostEdit {...props}  />)}/>
      <Route key='basePage' path={`/app/public/website/news`} render={() => (<WebsiteNewsPosts {...props}  />)}/>
    </Switch>
    <Route key='uploadAttachmentModal' exact path={'/app/public/website/news/:id/upload'}
           render={() => (<UploadAttachmentModal {...props} />)}/>
    <Route key='deleteDialog' exact path={`/app/public/website/news/:id/delete`}
           component={WebsiteNewsPostDeleteDialog}/>
  </>
}

export default withRouter(Routes)
