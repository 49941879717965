import { IWebsiteContent } from './website-content.model';

export interface IWebsiteContentArea {
  id?: number;
  name?: string;
  accessCode?: string;
  showTableOfContents?: boolean;
  contents?: IWebsiteContent[];
}

export const defaultValue: Readonly<IWebsiteContentArea> = {
  showTableOfContents: false,
};
