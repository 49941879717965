import axios from 'axios'
import { IPlayerProfile } from '../../../model/player-profile.model'
import { ACTION_TYPES, apiUrl } from '../player-profile.reducer'
import { ICrudGetAllExtendedAction } from '../../../../utils/table-utils'
import { ICrudDeleteAction, ICrudPutAction } from 'react-jhipster'
import { cleanEntity } from '../../../../utils/entity-utils'

let filterState = {
  filters: null,
  page: 0,
  size: 10,
  sort: null,
}

export const getEntities: ICrudGetAllExtendedAction<IPlayerProfile> = (filters: {}, page, size, sort) => {
  filterState = {
    filters, page: page || 0, size: size || 10, sort: sort || null,
  }
  let requestUrl = `${apiUrl}${sort && sort.length > 0 ? `?page=${page}&size=${size}&sort=${sort.join('&sort=')}` : ''}`
  if (filters) {
    if (typeof filters['teamProfile'] === 'boolean') {
      requestUrl += `${requestUrl.includes('?') ? '&' : '?'}teamProfile.equals=${filters['teamProfile']}`
    }
    if (filters['displayName']) {
      requestUrl += `${requestUrl.includes('?') ? '&' : '?'}displayName.contains=${filters['displayName']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_PLAYERPROFILE_LIST,
    payload: axios.get<IPlayerProfile>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const createEntity: ICrudPutAction<IPlayerProfile> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PLAYERPROFILE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities(filterState.filters, filterState.page, filterState.size, filterState.sort))
  return result
}

export const updateEntity: ICrudPutAction<IPlayerProfile> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLAYERPROFILE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities(filterState.filters, filterState.page, filterState.size, filterState.sort))
  return result
}

export const deleteEntity: ICrudDeleteAction<IPlayerProfile> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PLAYERPROFILE,
    payload: axios.delete(requestUrl),
  })
  dispatch(getEntities(filterState.filters, filterState.page, filterState.size, filterState.sort))
  return result
}

