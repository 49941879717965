import { TournamentCategoryType } from './enumerations/tournament-category-type.model';

export interface ITournamentCategory {
  id?: number;
  name?: string;
  mnemonic?: string;
  type?: TournamentCategoryType;
}

export const defaultValue: Readonly<ITournamentCategory> = {};
