import { SponsorLevel } from './enumerations/sponsor-level.model';

export interface ISponsorForEvent {
  id?: number;
  etmWebsite?: boolean;
  etwWebsite?: boolean;
  eventPoster?: boolean;
  invitation?: boolean;
  eventBanner?: boolean;
  sponsorLevel?: SponsorLevel;
  ecWebsite?: boolean;
  flowchart?: boolean;
  localSponsor?: boolean;
  sponsorName?: string;
  sponsorId?: number;
  eventName?: string;
  eventId?: number;
}

export const defaultValue: Readonly<ISponsorForEvent> = {
  etmWebsite: false,
  etwWebsite: false,
  eventPoster: false,
  invitation: false,
  eventBanner: false,
  ecWebsite: false,
  flowchart: false,
  localSponsor: false,
};
