import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../../redux/store'
import { Button, Checkbox, Col, Collapse, Divider, Form, Input, Modal, Radio, Row, Select, Space, Tooltip } from 'antd'
import axios from 'axios'

import { getEntity, reset, updateEntity } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'
import { ITournamentMatchExtended } from '../../../shared/model/extended/tournament-match.extended.model'

import { TextFormat } from 'react-jhipster'
import { APP_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'

import './match-history.scss'

export interface IMatchEditModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

const ShowMatchEditModal: React.FC<IMatchEditModalProps> = (props: IMatchEditModalProps) => {

  const matchId = Number(props.match.params.id)
  const matchType = props.match.params.type

  const [visible, showModal] = useState(false)

  const [submitLoading, setLoadingSubmit] = useState(false)
  const [playerModificationNeedsConfirmation, setPlayerModificationNeedsConfirmation] = useState(false)
  const [matchCanApproved, setMatchCanApproved] = useState(false)
  const [matchCanTimeout, setMatchCanTimeout] = useState(false)
  const [aTimeoutOvertime, setATimeoutOvertime] = useState(false)
  const [bTimeoutOvertime, setBTimeoutOvertime] = useState(false)
  const [nextToBreakRequired, setNextToBreakRequired] = useState(false)

  const [form] = Form.useForm<ITournamentMatchExtended>()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const validateMatchStatus = () => {
    let fieldsValue = form.getFieldsValue(['matchStatus', 'aTimeoutUntil', 'bTimeoutUntil', 'aScore', 'bScore'])
    let canTimeout = (fieldsValue.aTimeoutsRemaining > 0) || fieldsValue.bTimeoutsRemaining > 0
    setMatchCanTimeout(canTimeout)
    let canApprove = Number(fieldsValue.aScore) >= props.tournamentMatch?.raceTo || Number(fieldsValue.bScore) >= props.tournamentMatch?.raceTo
    setMatchCanApproved(canApprove)
    setTimeout(() => form.validateFields(), 200)
  }

  useEffect(() => {
    // console.log('default effect', matchId, props.loading)
    if (matchId && !props.loading)
      props.getEntity(matchId)
  }, [])

  useEffect(() => {
    // console.log('loading effect', props.loading)
    if (!props.loading && props.tournamentMatch) {
      setFieldsValue({ ...props.tournamentMatch })
      validateMatchStatus()
      showModal(true)
    }
  }, [props.loading, props.tournamentMatch])

  const onStatusChange = selectedValue => {
    setNextToBreakRequired(selectedValue !== 'READY' && props.tournamentMatch.tableId != null)
    validateMatchStatus()
  }

  const onPlayerUpdateChance = evt => {
    if (evt.target.value !== '')
      setPlayerModificationNeedsConfirmation(true)
    else {
      let fieldsValue = form.getFieldsValue(['participantAUpdate', 'participantBUpdate'])
      setPlayerModificationNeedsConfirmation(fieldsValue.participantAUpdate !== '' || fieldsValue.participantBUpdate !== '')
    }
  }

  const onSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        axios
          .post(`/api/management/announced-matches/save-changes`, { ...values, matchId: props.tournamentMatch.id }, {
            params: {
              matchId: props.tournamentMatch.id,
            },
          })
          .then(() => {
              setLoadingSubmit(false)
              showModal(false)
              props.history.push(`/app/matches/running`)
              props.reset()
            },
          )
        // TODO: change from specific request to global working save request
        /*
        // @ts-ignore
        props.updateEntity({ ...props.tournamentMatch, ...values }, 'updateMatch')
        setTimeout(() => {
            setLoadingSubmit(false)
            showModal(false)
            props.history.push(`/app/matches/running`)
            props.reset()
        }, 1000)
        */
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        setTimeout(() => {
          setLoadingSubmit(false)
        }, 1000)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.goBack()
    props.reset()
  }

  const onReset = () => {
    form.resetFields()
    setPlayerModificationNeedsConfirmation(false)
  }

  return <Modal
    width={'50%'}
    title={`Edit match ${props.tournamentMatch?.label}`}
    visible={visible}
    destroyOnClose={true}
    okText="Save"
    onOk={onSubmit}
    onCancel={onCancel}
    cancelText="Cancel"
    footer={(<Space>
        <Button key="cancel" onClick={onCancel}>Cancel</Button>
        <Tooltip key="resetTT" title="Reset form to the original values">
          <Button key="reset" htmlType="reset" form="matchForm" onClick={onReset}>Reset Form</Button>
        </Tooltip>
        <Button key="submit" htmlType="submit" form="matchForm" type="primary" onClick={onSubmit}
                loading={submitLoading}>
          Save
        </Button>
      </Space>
    )}
  >
    <Form layout={'horizontal'} form={form} preserve={false} id="matchForm">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} label="Status" name="matchStatus"
                     initialValue={props.tournamentMatch?.matchStatus}
                     rules={[
                       { required: true },
                       {
                         validator: (rule, value) => {
                           if (!matchCanTimeout && value === 'TIMEOUT') {
                             return Promise.reject('Time-Out status not allowed!')
                           } else if (matchCanTimeout && value !== 'TIMEOUT') {
                             return Promise.reject('Time-Out status must set, if a player have a time-out!')
                           } else if (!matchCanApproved && value === 'APPROVED') {
                             return Promise.reject('Approve status not allowed, if no player have reached race-to')
                           }
                           return Promise.resolve()
                         },
                       },
                     ]}>
            <Select onChange={onStatusChange}>
              <Select.Option value="READY" disabled={matchCanTimeout}>Announced</Select.Option>
              <Select.Option value="RUNNING" disabled={matchCanTimeout}>Running</Select.Option>
              <Select.Option value="TIMEOUT" disabled={!matchCanTimeout}>Time-Out</Select.Option>
              <Select.Option value="FINISHED"
                             disabled={matchCanTimeout || !matchCanApproved}>Finished</Select.Option>
              {/*
                <Select.Option value='APPROVED'
                               disabled={matchCanTimeout || !matchCanApproved}>Approved</Select.Option>
*/}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Player A" name="participantADisplayName"
                     initialValue={props.tournamentMatch?.participantADisplayName}
                     noStyle>
            <Input disabled style={{
              fontSize: 'large',
              fontWeight: 'bolder',
              color: 'black',
              textAlign: 'center',
            }}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Player B" name="participantBDisplayName"
                     initialValue={props.tournamentMatch?.participantBDisplayName}
                     noStyle>
            <Input disabled style={{
              fontSize: 'large',
              fontWeight: 'bolder',
              color: 'black',
              textAlign: 'center',
            }}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation={'center'}>Score</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="aScore" initialValue={props.tournamentMatch?.aScore}
                     rules={[{ required: true, message: 'Score cannot be empty' }]}>
            <Input type={'number'} style={{ textAlign: 'right' }} onChange={validateMatchStatus}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bScore" initialValue={props.tournamentMatch?.bScore}
                     rules={[{ required: true, message: 'Score cannot be empty' }]}>
            <Input type={'number'} onChange={validateMatchStatus}/>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="nextToBreak" initialValue={props.tournamentMatch?.nextToBreak}
                 rules={[{ required: nextToBreakRequired, message: 'Please select the player who breaks' }]}>
        <Radio.Group buttonStyle={'solid'} size={'small'} style={{ display: 'block' }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Radio.Button value="A"
                            style={{ display: 'block', textAlign: 'center' }}>Breaker</Radio.Button>
            </Col>
            <Col span={12}>
              <Radio.Button value="B"
                            style={{ display: 'block', textAlign: 'center' }}>Breaker</Radio.Button>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="aTimeoutUntil" initialValue={props.tournamentMatch?.aTimeoutUntil != null}
                     valuePropName="checked">
            {props.tournamentMatch ?
              props.tournamentMatch.aTimeoutUntil ? (
                <Checkbox/>
              ) : 'No Time-Out running'
              : null
            }
            <Checkbox
              style={{ display: 'block', textAlign: 'center', color: aTimeoutOvertime ? 'red' : null }}
              onChange={validateMatchStatus}>
              Time-Out
              {props.tournamentMatch?.aTimeoutUntil ? (
                <span>- until: <TextFormat type="date" value={props.tournamentMatch.aTimeoutUntil}
                                           format={APP_DATETIME_FORMAT_EU}/></span>
              ) : null}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bTimeoutUntil" initialValue={props.tournamentMatch?.bTimeoutUntil != null}
                     valuePropName="checked">
            <Checkbox
              style={{ display: 'block', textAlign: 'center', color: bTimeoutOvertime ? 'red' : null }}
              onChange={validateMatchStatus}>
              Time-Out
              {props.tournamentMatch?.bTimeoutUntil ? (
                <span>- until: <TextFormat type="date" value={props.tournamentMatch.bTimeoutUntil}
                                           format={APP_DATETIME_FORMAT_EU}/></span>
              ) : null}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Collapse>
        <Collapse.Panel header="Player Modifications" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={10} offset={2}>
              <Form.Item name="participantAUpdate" initialValue="">
                <Radio.Group onChange={onPlayerUpdateChance}>
                  <Radio style={radioStyle} value="">no changes</Radio>
                  {/*<Radio style={radioStyle} value='walkover'>change player to walk-over</Radio>*/}
                  <Radio style={radioStyle} value="forfeit">forfeit</Radio>
                  <Radio style={radioStyle} value="disqualified">disqualify player</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item name="participantBUpdate" initialValue="">
                <Radio.Group onChange={onPlayerUpdateChance}>
                  <Radio style={radioStyle} value="">no changes</Radio>
                  {/*<Radio style={radioStyle} value='walkover'>change player to walk-over</Radio>*/}
                  <Radio style={radioStyle} value="forfeit">forfeit</Radio>
                  <Radio style={radioStyle} value="disqualified">disqualify player</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              {playerModificationNeedsConfirmation === true ? (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={16} offset={4}
                       style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Form.Item name="playerChangeConfirmation" valuePropName="checked" rules={[{
                      required: playerModificationNeedsConfirmation,
                      message: 'Please confirm to save the changes',
                    }]}>
                      <Checkbox style={{ backgroundColor: 'yellow', padding: '5px 10px' }}>I confirm that the
                        changes should
                        be made.</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel key={'matchReset'} header="Match Modifications">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item name="resetMatch" valuePropName="checked">
                <Checkbox
                  style={{ display: 'block', textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                  Reset the match {'->'} Match will move back to the Upcoming-List
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Form>
  </Modal>
}

const mapStateToProps = ({ tournamentMatchExtended }: IAppState) => ({
  tournamentMatch: tournamentMatchExtended.entity,
  loading: tournamentMatchExtended.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowMatchEditModal))
