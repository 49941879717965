import axios from 'axios'
import { ICrudGetAction, ICrudGetAllAction, ICrudSearchAction } from 'react-jhipster'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, ITag } from '../../model/tag.model'

export const ACTION_TYPES = {
  SEARCH_TAGS: 'tag/SEARCH_TAGS',
  FETCH_TAG_LIST: 'tag/FETCH_TAG_LIST',
  FETCH_TAG: 'tag/FETCH_TAG',
  RESET: 'tag/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITag>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type TagState = Readonly<typeof initialState>;

// Reducer

export default (state: TagState = initialState, action): TagState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_TAGS):
    case REQUEST(ACTION_TYPES.FETCH_TAG_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TAG):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_TAGS):
    case FAILURE(ACTION_TYPES.FETCH_TAG_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TAG):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_TAGS):
    case SUCCESS(ACTION_TYPES.FETCH_TAG_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_TAG):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tags';
const apiSearchUrl = 'api/_search/tags';

// Actions

export const getSearchEntities: ICrudSearchAction<ITag> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_TAGS,
  payload: axios.get<ITag>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<ITag> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TAG_LIST,
    payload: axios.get<ITag>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ITag> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TAG,
    payload: axios.get<ITag>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
