import React, { useEffect, useState } from 'react'
import { IAppState } from '../../redux/store'
import { connect } from 'react-redux'
import { Button, Form, Input, Modal, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons/lib'
import axios from 'axios'
import Text from 'antd/es/typography/Text'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { IPageProps } from '../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getEntities as getParticipants } from '../../shared/reducers/model/participant.reducer'
import { getEntity as getTournament, getRankingPdf } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { IPlayerProfile } from '../../shared/model/player-profile.model'
import { IParticipant } from '../../shared/model/participant.model'

export interface ITournamentParticipantsModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

const TournamentParticipantsModal: React.FC<ITournamentParticipantsModalProps> = (props: ITournamentParticipantsModalProps) => {

  const tournamentId = Number(props.match.params.id)
  const statusType = props.match.params.type

  const [visible, showModal] = useState(false)
  const [addParticipantModalVisible, showAddParticipantModal] = useState(false)

  const [addParticipantSubmitButtonDisabled, disableAddParticipantSubmitButton] = useState(false)

  const [availablePlayerProfiles, setAvailablePlayerProfiles] = useState([])
  const [availablePlayerProfilesLoading, setAvailablePlayerProfilesLoading] = useState(false)
  const [submitLoading, setLoadingSubmit] = useState(false)
  const [confirmationButtonLoading, setConfirmationButtonLoading] = useState({})
  const [deleteButtonLoading, setDeleteButtonLoading] = useState({})

  const [form] = Form.useForm<IPlayerProfile>()

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: 10,
    activePage: 1,
    sort: 'points',
    order: 'desc',
  })

  const [tableFilters, setTableFilters] = useState({
    tournamentId: tournamentId,
  })

  useEffect(() => {
    if (tournamentId && !props.tournamentLoading)
      props.getTournament(tournamentId)
  }, [])

  useEffect(() => {
    if (!props.tournamentLoading && props.tournament) {
      loadParticipants()
      showModal(true)
    }
  }, [props.tournamentLoading, props.tournament])

  useEffect(() => {
    if (addParticipantModalVisible)
      loadAvailablePlayerProfiles()
  }, [addParticipantModalVisible])

  useEffect(() => {
    loadParticipants()
  }, [paginationState, tableFilters])

  const onSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        // props.updateEntity({ ...props.tournament, ...values })
        setTimeout(() => {
          setLoadingSubmit(false)
          showModal(false)
          props.history.push(`/app/tournaments/${statusType}`)
        }, 1000)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        setTimeout(() => {
          setLoadingSubmit(false)
        }, 1000)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.push(`/app/tournaments/${statusType}`)
  }

  const fetchRankingPdf = () => {
    getRankingPdf(tournamentId, props.tournament.name)
  }

  const onReset = () => {
    form.resetFields()
  }

  const loadParticipants = () => {
    // @ts-ignore
    props.getParticipants(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const loadAvailablePlayerProfiles = async () => {
    setAvailablePlayerProfilesLoading(true)
    await axios
      .get(`/api/management/tournaments/${tournamentId}/participants/available-players`)
      .then(({ data }) => {
        setAvailablePlayerProfiles(data.map(playerProfile => ({
          label: playerProfile.displayName,
          value: playerProfile.id,
        })))
        setAvailablePlayerProfilesLoading(false)
      })
  }

  const handleAddParticipantSubmit = () => {
    form.validateFields()
      .then(values => {
        // console.log(values)
        disableAddParticipantSubmitButton(true)
        axios
          .put(`/api/management/tournaments/${props.tournament.id}/participants/available-players`, { id: Number(values.id) })
          .then(() => {
            setTimeout(() => {
              showAddParticipantModal(false)
              disableAddParticipantSubmitButton(false)
              loadParticipants()
            }, 500)
          })
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
      })
  }

  const handleRemoveParticipant = (participantId) => {
    setDeleteButtonLoading({ [participantId]: true })
    axios
      .delete(`/api/participants/${participantId}`)
      .then(resp => {
        setTimeout(() => {
          loadParticipants()
          setDeleteButtonLoading({ [participantId]: false })
        }, 500)
      })
  }

  const toggleConfirmation = (participantId, currentValue) => {
    if (currentValue !== true) {
      setConfirmationButtonLoading({ [participantId]: true })
      axios
        .post(`/api/management/tournaments/${props.tournament.id}/participants/${participantId}/approve-registration`)
        .then(resp => {
          setTimeout(() => {
            loadParticipants()
            setConfirmationButtonLoading({ [participantId]: false })
          }, 500)
        })
    }
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  return <>
    <Modal
      width={'80%'}
      title={`Final ranking for the tournament: ${props.tournament?.name}`}
      visible={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      /*
            okButtonProps={{ style: { display: 'none' } }}
            cancelText={'Close'}
      */
      footer={[
        <Button key='getPdf' onClick={fetchRankingPdf}>Ranking PDF</Button>,
        <Button key='cancel' onClick={onCancel}>Close</Button>,
      ]}
    >
      <Text strong>{`Currently ${props.participantsTotalItems} participants registered`}</Text>
      <Table key='participantsTable' id='participantTable'
             rowKey='id'
             size='small'
             dataSource={props.participants.flat()}
             loading={props.participantsLoading}
             onChange={handleTableChanges}
             pagination={{
               defaultPageSize: 10,
               total: props.participantsTotalItems,
               pageSize: paginationState.itemsPerPage,
               hideOnSinglePage: false,
               position: ['topRight', 'bottomRight'],
               showSizeChanger: false,
             }}
      >
        <Table.Column key={'place'} dataIndex={'place'} title={'Place'} width={50} align={'right'}/>
        <Table.Column<IParticipant> key={'playerProfileDisplayName'} dataIndex={'playerProfileDisplayName'} title={'Name'}
                                    sorter
                                    {...getColumnSearchProps('playerProfileDisplayName')}
                                    render={(value, row) => {
                                      let ret = value
                                      if (row.disqualified)
                                        ret += ' (DIS)'
                                      return ret
                                    }}
        />
        <Table.Column key={'points'} dataIndex={'points'} title={'Points'}
                      sorter
                      defaultSortOrder={'descend'}
                      render={(value) => <TextFormat type="number" value={value}/>}
        />
      </Table>
    </Modal>
  </>
}

const mapStateToProps = ({ participant, tournamentExtended }: IAppState) => ({
  participantsLoading: participant.loading,
  participants: participant.entities,
  participantsTotalItems: participant.totalItems,
  tournament: tournamentExtended.entity,
  tournamentLoading: tournamentExtended.loading,
})

const mapDispatchToProps = {
  getParticipants,
  getTournament,
  getRankingPdf,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TournamentParticipantsModal))
