import { Moment } from 'moment';
import { ITag } from './tag.model';

export interface IWebsiteContent {
  id?: number;
  published?: boolean;
  title?: string;
  text?: any;
  publicationDate?: string;
  sorting?: number;
  areaName?: string;
  areaId?: number;
  tags?: ITag[];
}

export const defaultValue: Readonly<IWebsiteContent> = {
  published: false,
};
