import { Moment } from 'moment';
import { IDiscipline } from './discipline.model';
import { IPlayerCategory } from './player-category.model';
import { CalendarItemTournamentCategory } from './enumerations/calendar-item-tournament-category.model';
import { CalendarItemStatus } from './enumerations/calendar-item-status.model';

export interface ICalendarItem {
  id?: number;
  calendarItemTitle?: string;
  calendarItemLocation?: string;
  calendarItemLink?: string;
  calendarItemTournamentCategory?: CalendarItemTournamentCategory;
  calendarItemStatus?: CalendarItemStatus;
  calendarItemStartDate?: string;
  calendarItemEndDate?: string;
  posterContentType?: string;
  poster?: any;
  calendarItemDisciplines?: IDiscipline[];
  calendarItemPlayerCategories?: IPlayerCategory[];
}

export const defaultValue: Readonly<ICalendarItem> = {};
