import { deleteEntity, getEntity } from '../../../../shared/reducers/model/calendar-item.reducer'
import { message, Modal, Typography } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { IAppState } from '../../../../redux/store'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../../../redux/settings/constants'
import { TextFormat } from 'react-jhipster'

const { Paragraph, Text } = Typography

export interface IWebsiteCalendarDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const CalendarItemDeleteDialog = (props: IWebsiteCalendarDeleteDialogProps) => {

  useEffect(() => {
    props.getEntity(props.match.params.id)
  }, [])

  const handleClose = () => {
    props.history.push('/app/public/website/calendar')
  }

  const confirmDelete = () => {
    props.deleteEntity(props.calendarItemEntity.id)
    message.success('Calendar-Item deleted')
  }

  useEffect(() => {
    if (props.updateSuccess)
      handleClose()
  }, [props.updateSuccess])

  return (
    <Modal visible={true} title='Delete Calendar Item'
           onOk={confirmDelete}
           okText='Yes, delete it'
           okType={'primary'}
           okButtonProps={{ danger: true }}
           onCancel={handleClose}
           cancelText='No, back to List'
    >
      <Paragraph>Are you sure you want to delete the following Calendar-Item?</Paragraph>
      <Paragraph>
        <Text strong>
          <TextFormat type="date" value={props.calendarItemEntity.calendarItemStartDate}
                      format={APP_LOCAL_DATE_FORMAT_EU}/>
          &nbsp;to&nbsp;
          <TextFormat type="date" value={props.calendarItemEntity.calendarItemEndDate}
                      format={APP_LOCAL_DATE_FORMAT_EU}/>
          <br/>
          {props.calendarItemEntity.calendarItemTitle}
        </Text>
      </Paragraph>
    </Modal>
  )
}

const mapStateToProps = ({ calendarItem }: IAppState) => ({
  calendarItemEntity: calendarItem.entity,
  updateSuccess: calendarItem.updateSuccess,
})

const mapDispatchToProps = { getEntity, deleteEntity }

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CalendarItemDeleteDialog)