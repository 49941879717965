import { IChartTemplateMatch } from './chart-template-match.model';

export interface IChartTemplate {
  id?: number;
  name?: string;
  description?: any;
  maxParticipants?: number;
  minParticipants?: number;
  numberOfStages?: number;
  seedingPlanStage1?: any;
  seedingPlanStage2?: any;
  seedingPlanStage3?: any;
  numberOfQualifierStage1?: number;
  numberOfQualifierStage2?: number;
  numberOfQualifierStage3?: number;
  matches?: IChartTemplateMatch[];
}

export const defaultValue: Readonly<IChartTemplate> = {};
