import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { Link, RouteComponentProps } from 'react-router-dom'
import { IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import { Button, Checkbox, Col, Input, Row, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { IPaginationBaseState } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { IPlayerProfile } from '../../../shared/model/player-profile.model'
import { IChartTemplateTimeSession } from '../../../shared/model/chart-template-time-session.model'
import { reset } from '../../../shared/reducers/model/chart-template-time-session.reducer'
import { getEntities } from '../../../shared/reducers/model/extended/chart-template-time-session.extended.reducer'

export interface ITimeSessionListProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const TimeSessionList = (props: ITimeSessionListProps) => {

  const { match, loading, onSetPage, entities, updateSuccess, updating, entity, totalItems, location } = props

  const pageData: IPageData = {
    title: 'Time Session Templates',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Time Session Templates',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'name',
    order: 'asc',
  })
  const [tableFilters, setTableFilters] = useState({})

  const fetchTimeSessions = () => {
    // console.log('fetch player profiles', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, [`${paginationState.sort},${paginationState.order}`], { ...tableFilters })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters(filters)
  }

  useEffect(() => {
    // console.log('placer profile list default hook')
    onSetPage(pageData)
    fetchTimeSessions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage(pageData)
    fetchTimeSessions()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    fetchTimeSessions()
  }, [paginationState, tableFilters])

  const tableColumns: ColumnsType<IChartTemplateTimeSession> = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      sorter: true,
    }, {
      title: 'Number of Participants',
      dataIndex: 'numberOfParticipants',
    }, {
      title: 'Number of Tables',
      dataIndex: 'numberOfTables',
    }, {
      title: '',
      dataIndex: 'id',
      width: '10%',
      align: 'right',
      render: (value, row, index) => (
        <Space>
          <Link to={`${match.url}/${value}`}><EditOutlined/></Link>
        </Space>
      ),
    },
  ]

  return <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
    <Col span={24}>
      <Link to={`${match.url}/new`} style={{ float: 'right' }}>
        <Button type={'primary'}>Create new Time Session Template</Button>
      </Link>
    </Col>
    <Col span={24}>
      <Table id="mngTimeSessionTable"
             rowKey="id"
             size="small"
             dataSource={entities.flat()}
             columns={tableColumns}
             loading={loading}
             onChange={handleTableChanges}
             pagination={{
               defaultPageSize: paginationState.itemsPerPage,
               total: props.totalItems,
               pageSize: paginationState.itemsPerPage,
               showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
             }}
      />
    </Col>
  </Row>
}

const mapStateToProps = ({ chartTemplateTimeSession }: IAppState) => ({
  entities: chartTemplateTimeSession.entities,
  loading: chartTemplateTimeSession.loading,
  totalItems: chartTemplateTimeSession.totalItems,
  entity: chartTemplateTimeSession.entity,
  updateSuccess: chartTemplateTimeSession.updateSuccess,
  updating: chartTemplateTimeSession.updating,
})

const mapDispatchToProps = {
  getEntities,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(TimeSessionList)