export enum TournamentStatus {
  CREATED = 'CREATED',

  GENERATED = 'GENERATED',

  RUNNING = 'RUNNING',

  FINISHED = 'FINISHED',

  APPROVED = 'APPROVED',

  CANCELED = 'CANCELED',
}
