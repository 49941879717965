import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../interfaces/page-data'
import { IAppState } from '../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import moment from 'moment'

import { mapIdList } from '../../utils/entity-utils'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../redux/settings/constants'
import { EventStatus } from '../../shared/model/enumerations/event-status.model'
import { EventType } from '../../shared/model/enumerations/event-type.model'

import { createEntity, getEntity, reset, updateEntity } from '../../shared/reducers/model/event.reducer'
import { getEntities as getHotels } from '../../shared/reducers/model/hotel.reducer'
import { getEntities as getVenues } from '../../shared/reducers/model/venue.reducer'
import { getEntities as getSeasons } from '../../shared/reducers/model/season.reducer'
import { getTournamentLeaders } from '../../shared/reducers/model/user-management.reducer'

import { Button, Col, DatePicker, Divider, Form, Input, message, Row, Select, Space } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import './events.scss'
import EventSponsorsModal from './event-sponsors'
import EventPosterUploadModal from './event-poster.upload'

export interface IEventUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

export const EventUpdate = (props: IEventUpdateProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const eventListType = props.match.params.type
  const { entity, onSetPage, getPageData } = props
  const [submitLoading, setLoadingSubmit] = useState(false)
  const [isEventSponsorsModalVisible, showEventSponsorsModal] = useState(false)
  const [isEventPosterUploadModalVisible, showEventPosterUploadModal] = useState(false)

  const [eventType, setEventType] = useState(EventType.EURO_TOUR)

  const pageData: IPageData = {
    title: `Event - ${!isNew ? `${entity.name} [${entity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
      },
      {
        title: 'Events',
        route: `/app/events/${eventListType}`,
      },
      {
        title: !isNew ? `Event: ${entity.name}` : 'Create new Event',
      },
    ],
  }

  useEffect(() => {
    onSetPage(pageData)
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    props.getHotels(0, 99, 'name')
    props.getVenues(0, 999, 'name')
    props.getSeasons(0, 99, 'seasonYear,desc')
    props.getTournamentLeaders()
    // Next Line is preventing Chrome and Firefox autofill/autocomplete feature nightmare
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopDamnAutocomplete')
      //you can put any value but NOT "off" or "false" because they DO NOT works
    })
    return () => {
      props.reset()
    }
  }, [])

  useEffect(() => {
    if (!props.loading && !isNew && entity?.id) {
      let formValues = {
        ...entity,
        eventStartDate: entity.eventStartDate ? moment(entity.eventStartDate, 'YYYY-MM-DD') : null,
        eventEndDate: entity.eventEndDate ? moment(entity.eventEndDate, 'YYYY-MM-DD') : null,
        eventDates: [entity.eventStartDate ? moment(entity.eventStartDate, 'YYYY-MM-DD') : null, entity.eventEndDate ? moment(entity.eventEndDate, 'YYYY-MM-DD') : null],
        registrationStartDate: entity.registrationStartDate ? moment(entity.registrationStartDate, 'YYYY-MM-DD') : null,
        registrationEndDate: entity.registrationEndDate ? moment(entity.registrationEndDate, 'YYYY-MM-DD') : null,
        registrationDates: [entity.registrationStartDate ? moment(entity.registrationStartDate, 'YYYY-MM-DD') : null, entity.registrationEndDate ? moment(entity.registrationEndDate, 'YYYY-MM-DD') : null],
        hotels: entity.hotels.map(value => value.id),
        venues: entity.venues.map(value => value.id),
        users: entity.users.map(value => value.id),
      }
      setFieldsValue(formValues)
      setEventType(entity.eventType)

    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess && !isEventPosterUploadModalVisible) {
      props.history.push(`/app/events/${eventListType}`)
    }
    if (isEventPosterUploadModalVisible)
      showEventPosterUploadModal(false)
  }, [props.updateSuccess])

  const [availableHotels, setAvailableHotels] = useState([])
  useEffect(() => {
    if (!props.hotelsLoading) {
      setAvailableHotels(props.hotels.map(hotel => ({
        value: hotel.id,
        label: hotel.name,
      })))
    }
  }, [props.hotelsLoading])

  const [availableVenues, setAvailableVenues] = useState([])
  useEffect(() => {
    if (!props.venuesLoading) {
      setAvailableVenues(props.venues.map(venue => ({
        value: venue.id,
        label: venue.name,
      })))
    }
  }, [props.venuesLoading])

  const [availableSeasons, setAvailableSeasons] = useState([])
  useEffect(() => {
    if (!props.seasonsLoading) {
      setAvailableSeasons(props.seasons.map(season => ({
        value: season.id,
        label: season.name,
      })))
    }
  }, [props.seasonsLoading])

  const [availableUsers, setAvailableUsers] = useState([])
  useEffect(() => {
    if (!props.usersLoading && props.users) {
      setAvailableUsers(props.users.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })))
    }
  }, [props.usersLoading])

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...entity,
          ...values,
          eventStartDate: (values.eventDates && values.eventDates[0]) ? values.eventDates[0].format('YYYY-MM-DD') : null,
          eventEndDate: (values.eventDates && values.eventDates[1]) ? values.eventDates[1].format('YYYY-MM-DD') : null,
          registrationStartDate: (values.registrationDates && values.registrationDates[0]) ? values.registrationDates[0].format('YYYY-MM-DD') : null,
          registrationEndDate: (values.registrationDates && values.registrationDates[1]) ? values.registrationDates[1].format('YYYY-MM-DD') : null,
          venues: mapIdList(values.venues),
          hotels: mapIdList(values.hotels),
          users: values.users ? mapIdList(values.users) : null,
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        alert(reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...entity,
        eventStartDate: entity.eventStartDate ? moment(entity.eventStartDate, 'YYYY-MM-DD') : null,
        eventEndDate: entity.eventEndDate ? moment(entity.eventEndDate, 'YYYY-MM-DD') : null,
        eventDates: [entity.eventStartDate ? moment(entity.eventStartDate, 'YYYY-MM-DD') : null, entity.eventEndDate ? moment(entity.eventEndDate, 'YYYY-MM-DD') : null],
        registrationStartDate: entity.registrationStartDate ? moment(entity.registrationStartDate, 'YYYY-MM-DD') : null,
        registrationEndDate: entity.registrationEndDate ? moment(entity.registrationEndDate, 'YYYY-MM-DD') : null,
        registrationDates: [entity.registrationStartDate ? moment(entity.registrationStartDate, 'YYYY-MM-DD') : null, entity.registrationEndDate ? moment(entity.registrationEndDate, 'YYYY-MM-DD') : null],
        hotels: entity.hotels.map(value => value.id),
        venues: entity.venues.map(value => value.id),
        users: entity.users.map(value => value.id),
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleOnEditorChange = (value, delta, source, editor) => {
    setFieldsValue({ description: value })
  }

  return (
    <Form form={form} id={'updateEvent'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
          autoComplete={'off'}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleFormOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="name" label={'Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 80 }]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="eventType" label={'Event-Type'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <Select
              options={Object.keys(EventType).map(key => ({
                text: EventType[key],
                value: key,
              }))}
              onChange={(value, option) => {
                setEventType(EventType[value.toString()])
                console.log(value)
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="shortName" label={'Short-Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true, max: 30 }]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="eventStatus" label={'Status'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: true }]}
                     help={'CREATED: pre creation - not visible on website; The "newest" active/running event is the current shown active event on website. You can only add tournaments to this event in status CREATED!'}
          >
            <Select
              options={Object.keys(EventStatus).map(key => ({
                text: EventStatus[key],
                value: key,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="description" hidden>
            <Input type="text"/>
          </Form.Item>
          <Form.Item name="description" label="Description" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <ReactQuill id="descriptionEditor" theme={'snow'} value={entity.description}
                        onChange={handleOnEditorChange}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="eventDates" label={'Event From/To'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: true }]}>
            <DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT_EU}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="registrationDates" label={'Registration Open'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: eventType === EventType.EURO_TOUR }]}
                     help={'Only necessary for Euro-Tour'}
          >
            <DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT_EU}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="numberOfTables" label={'Number of tables for the event'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     initialValue={1}
                     getValueFromEvent={e => {
                       const convertedValue = Number(e.currentTarget.value)
                       if (isNaN(convertedValue)) {
                         return Number(form.getFieldValue('numberOfTables'))
                       } else {
                         return convertedValue
                       }
                     }}
                     rules={[{ required: true, min: 1, type: 'number' }]}>
            <Input type={'number'} min={1}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="venues" label="Venues" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select
              showSearch
              autoClearSearchValue
              mode={'multiple'}
              optionFilterProp="label"
              loading={props.venuesLoading}
              options={availableVenues}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="seasonId" label="Season" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.seasonsLoading}
              options={availableSeasons}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="hotels" label="Hotels" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select
              showSearch
              autoClearSearchValue
              mode={'multiple'}
              optionFilterProp="label"
              loading={props.hotelsLoading}
              options={availableHotels}
            />
          </Form.Item>
        </Col>
        <Col span={12} offset={12}>
          <Form.Item name="users" label="User-Access" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select
              showSearch
              autoClearSearchValue
              mode={'multiple'}
              optionFilterProp="label"
              loading={props.usersLoading}
              options={availableUsers}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Space>
            {!isNew ? (<>
                <Button htmlType="button" onClick={() => showEventSponsorsModal(true)}>
                  Add/Edit Event Sponsors
                </Button>
                <EventSponsorsModal visible={isEventSponsorsModalVisible} event={props.entity}
                                    onClose={() => showEventSponsorsModal(false)}/>
                <Button htmlType={'button'} onClick={() => showEventPosterUploadModal(true)}>
                  {props.entity.eventPosterId ? 'Replace' : 'Add'} Event Poster
                </Button>
                <EventPosterUploadModal visible={isEventPosterUploadModalVisible}
                                        eventPosterId={props.entity.eventPosterId}
                                        onOk={(eventPosterId) => {
                                          props.updateEntity({
                                            ...props.entity,
                                            eventPosterId,
                                          })
                                        }}
                                        onCancel={() => showEventPosterUploadModal(false)}
                />
              </>
            ) : null}
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Link to={`../${eventListType}`}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entity: storeState.event.entity,
  loading: storeState.event.loading,
  updating: storeState.event.updating,
  updateSuccess: storeState.event.updateSuccess,
  seasons: storeState.season.entities,
  seasonsLoading: storeState.season.loading,
  venues: storeState.venue.entities,
  venuesLoading: storeState.venue.loading,
  hotels: storeState.hotel.entities,
  hotelsLoading: storeState.hotel.loading,
  users: storeState.userManagement.users,
  usersLoading: storeState.userManagement.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getSeasons,
  getHotels,
  getVenues,
  getTournamentLeaders,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventUpdate))
