export interface IChartTemplateMatch {
  id?: number;
  label?: string;
  winnerToMatch?: string;
  winnerToPosition?: string;
  loserToMatch?: string;
  loserToPosition?: string;
  loserPlace?: number;
  stage?: number;
  roundLabel?: string;
  roundId?: number;
  chartTemplateName?: string;
  chartTemplateId?: number;
}

export const defaultValue: Readonly<IChartTemplateMatch> = {};
