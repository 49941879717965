import React from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

export interface IInvoiceUpdateProps extends IPageProps, RouteComponentProps<{id: string}> {}

const InvoiceUpdate = (props: IInvoiceUpdateProps) => {
  return <div/>
}

export default withRouter(connect()(InvoiceUpdate))