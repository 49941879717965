import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  createUser,
  getRoles,
  getUser,
  reset,
  updateUser,
} from '../../../shared/reducers/model/user-management.reducer'
import { Button, Col, Form, Input, message, Row, Select, Space, Switch } from 'antd'

import { AUTHORITIES_LABELS } from '../../../redux/settings/constants'
import { languages, locales } from '../../../redux/settings/translation'

export interface IUserManagementEditProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ login: string }> {
}

export const UserManagementEdit = (props: IUserManagementEditProps) => {

  const [isNew] = useState(!props.match.params || !props.match.params.login)
  const { onSetPage } = props

  const pageData: IPageData = {
    title: `Website News - ${!isNew ? `${props.user.lastName}, ${props.user.firstName}` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Administration',
      },
      {
        title: 'Users',
        route: 'administration/users',
      },
      {
        title: !isNew ? `${props.user.lastName}, ${props.user.firstName}` : 'Create new News-Post',
      },
    ],
  }

  const login = props.match.params.login
  const [form] = Form.useForm()

  useEffect(() => {
    onSetPage(pageData)
    if (!isNew) {
      props.getUser(login)
    }
    props.getRoles()
    return () => props.reset()
  }, [])

  useEffect(() => {
    if (!props.loading && props.user) {
      form.setFieldsValue(props.user)
    }
    onSetPage({ ...pageData, loaded: !props.loading })
  }, [props.loading, props.user])

  const [roleOptions, setRoleOptions] = useState([])
  useEffect(() => {
    setRoleOptions(props.roles.map(role => ({
      key: role,
      label: AUTHORITIES_LABELS[role.substr(5)],
      value: role,
    })))
  }, [props.roles])

  const [languageOptions] = useState(
    locales.map(locale => ({
      key: locale,
      label: languages[locale].name,
      value: locale,
    })))

  useEffect(() => {
    if (!props.updating && props.updateSuccess) {
      message.success('Record successfully saved')
      props.history.push('.')
    }
  }, [props.updateSuccess, props.updating])

  const handleFormOnFinish = values => {
    form.validateFields()
      .then(values => {
        const entity = {
          ...props.user,
          ...values,
        }
        if (isNew) {
          props.createUser(entity)
        } else {
          props.updateUser(entity)
        }
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error(`Validation failed: ${reason}`, 0)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
    message.error(errorInfo, 0)
  }

  const handleOnReset = () => {
    if (!isNew) {
      form.setFieldsValue(props.user)
    } else {
      form.resetFields()
    }
  }

  const handleAbort = () => {
    props.history.push('.')
  }

  return (
    <Form form={form} id={'userEditForm'} layout={'horizontal'} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name={'activated'} label={'Login activated'} required valuePropName={'checked'}>
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'id'} label={'ID'}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'login'} label={'Login'} required>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'email'} label={'Email'} required>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'firstName'} label={'First name'} required>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'lastName'} label={'Last name'} required>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'authorities'} label={'Roles'}>
            <Select
              allowClear
              mode={'multiple'}
              options={roleOptions}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'langKey'} label={'Language'} required>
            <Select
              options={languageOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={props.loading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Button htmlType="button" onClick={handleAbort}>
              Cancel
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )

}

const mapStateToProps = ({ userManagement }: IAppState) => ({
  user: userManagement.user,
  loading: userManagement.loading,
  roles: userManagement.authorities,
  updateSuccess: userManagement.updateSuccess,
  errorMessage: userManagement.errorMessage,
  updating: userManagement.updating,
})

const mapDispatchToProps = {
  getUser,
  getRoles,
  updateUser,
  createUser,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserManagementEdit))