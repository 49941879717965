import axios from 'axios'
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster'

import { cleanEntity } from '../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../../shared/reducers/action-type.util'

import { defaultValue, IParticipant } from '../../shared/model/participant.model'

export const ACTION_TYPES = {
  FETCH_TOURNAMENTPARTICIPANTS_LIST: 'tournamentMatch/FETCH_TOURNAMENTPARTICIPANTS_LIST',
  FETCH_TOURNAMENTPARTICIPANTS: 'tournamentMatch/FETCH_TOURNAMENTPARTICIPANTS',
  UPDATE_TOURNAMENTPARTICIPANTS: 'tournamentMatch/UPDATE_TOURNAMENTPARTICIPANTS',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IParticipant>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type TournamentParticipantState = Readonly<typeof initialState>;

// Reducer

export default (state: TournamentParticipantState = initialState, action): TournamentParticipantState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.UPDATE_TOURNAMENTPARTICIPANTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS):
    case FAILURE(ACTION_TYPES.UPDATE_TOURNAMENTPARTICIPANTS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.UPDATE_TOURNAMENTPARTICIPANTS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    default:
      return state
  }
};

const apiUrl = 'api/participants'

// Actions
export const getEntities: ICrudGetAllAction<IParticipant> = (page, size, sort, filters?: {}) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  // console.log('filters', filters)
  if (filters) {
    // console.log(typeof filters['registrationApproved'])
    if (filters['tournamentId']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}tournamentId.equals=${filters['tournamentId']}`
    }
    if (filters['qualifierForStage']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}qualifierForStage.equals=${filters['qualifierForStage']}`
    }
    if (filters['displayName']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}displayName.contains=${filters['displayName']}`
    }
    if (filters['registrationApproved'] && typeof filters['registrationApproved'] === 'object' && filters['registrationApproved'].length > 1) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}registrationApproved.in=${filters['registrationApproved'].join('&registrationApproved.in=')}`
    }
    if (filters['registrationApproved'] && typeof filters['registrationApproved'] === 'object' && filters['registrationApproved'].length === 1) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}registrationApproved.equals=${filters['registrationApproved'][0]}`
    }

  }
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS_LIST,
    payload: axios.get<IParticipant>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<IParticipant> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENTPARTICIPANTS,
    payload: axios.get<IParticipant>(requestUrl),
  }
}

export const updateEntity: ICrudPutAction<IParticipant> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TOURNAMENTPARTICIPANTS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}
