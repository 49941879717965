import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IInvoice } from '../../model/invoice.model'

import { saveAs } from 'file-saver'
import { message, Modal } from 'antd'

export const ACTION_TYPES = {
  FETCH_INVOICE_LIST: 'invoice/FETCH_INVOICE_LIST',
  FETCH_INVOICE: 'invoice/FETCH_INVOICE',
  CREATE_INVOICE: 'invoice/CREATE_INVOICE',
  UPDATE_INVOICE: 'invoice/UPDATE_INVOICE',
  DELETE_INVOICE: 'invoice/DELETE_INVOICE',
  RESET: 'invoice/RESET',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoice>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type InvoiceState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceState = initialState, action): InvoiceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INVOICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.CREATE_INVOICE):
    case REQUEST(ACTION_TYPES.UPDATE_INVOICE):
    case REQUEST(ACTION_TYPES.DELETE_INVOICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.FETCH_INVOICE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INVOICE):
    case FAILURE(ACTION_TYPES.CREATE_INVOICE):
    case FAILURE(ACTION_TYPES.UPDATE_INVOICE):
    case FAILURE(ACTION_TYPES.DELETE_INVOICE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.FETCH_INVOICE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_INVOICE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.CREATE_INVOICE):
    case SUCCESS(ACTION_TYPES.UPDATE_INVOICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.DELETE_INVOICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
};

export const apiUrl = 'api/invoices'

// Actions

export const getEntities: ICrudGetAllAction<IInvoice> = (page, size, sort, filters?: object) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  console.debug('filters:', filters)
  if (filters) {
    if (filters['invoiceNumber']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}invoiceNumber.contains=${filters['invoiceNumber']}`
    }
    if (filters['playerProfileDisplayName']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}playerProfileDisplayName.contains=${filters['playerProfileDisplayName']}`
    }
    if (filters['playerProfileId'] && typeof filters['playerProfileId'] === 'number') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}playerProfileId.equals=${filters['playerProfileId']}`
    }
    /*
        if (filters['playerProfile.displayName']) {
          requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}playerProfile.displayName.contains=${filters['playerProfile.displayName']}`
        }
    */
    if (filters['status']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}status.in=${filters['status'].join('&status.in=')}`
    }
    console.log(typeof filters['purpose'])
    if (filters['purpose'] && typeof filters['purpose'] === 'string') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}purpose.contains=${filters['purpose']}`
    }
    if (filters['purpose'] && typeof filters['purpose'] === 'object') {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}purpose.contains=${filters['purpose'].join(' ')}`
    }
    if (filters['paymentType']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}paymentType.equals=${filters['paymentType']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_INVOICE_LIST,
    payload: axios.get<IInvoice>(`${requestUrl}${requestUrl.indexOf('?') >= 0 ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<IInvoice> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_INVOICE,
    payload: axios.get<IInvoice>(requestUrl),
  }
}

export const createEntity: ICrudPutAction<IInvoice> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}

export const updateEntity: ICrudPutAction<IInvoice> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INVOICE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  return result
}

export const deleteEntity: ICrudDeleteAction<IInvoice> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INVOICE,
    payload: axios.delete(requestUrl),
  })
  dispatch(getEntities())
  return result
}

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})

export const getPdf = (id: number, invoiceNumber: string) => {
  let secondsToGo = 7
  const modal = Modal.success({
    title: 'Prepare Invoice',
    content: `Download starting soon.`,
    okButtonProps: { style: { display: 'none' } },
  })
  axios.get(`${apiUrl}/${id}.pdf?cacheBuster=${new Date().getTime()}`, {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf',
    },
  })
    .then((response) => {
      // response.data is an empty object
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      })
      saveAs(blob, `${invoiceNumber}.pdf`)
    })
    .catch(reason => {
      message.error('Creating invoice failed')
    })
    .finally(() => {
      setTimeout(() => {
        modal.destroy()
      }, 500)
    })
}
