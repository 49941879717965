import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { history, IAppState } from '../../redux/store'
import { IPageData, IPageProps } from '../../interfaces/page-data'
import { IPaginationBaseState } from 'react-jhipster'
import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE } from '../../utils/pagination.constants'
import { getEntities, getRankingPdf } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { getEntities as getEvents } from '../../shared/reducers/model/event.reducer'
import { getEnabledEntities as getDisciplines } from '../../shared/reducers/model/discipline.reducer'
import { getEntities as getCategories } from '../../shared/reducers/model/tournament-category.reducer'
import { TournamentStatus } from '../../shared/model/enumerations/tournament-status.model'
import { Col, Dropdown, Input, Menu, message, Modal, Row, Space, Table, Tooltip } from 'antd'
import Button from 'antd/es/button'
import { ApartmentOutlined, CheckCircleTwoTone, DownOutlined, ReloadOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons'
import { ITournamentExtended } from '../../shared/model/extended/tournament.extended.model'
import axios from 'axios'
import { EventStatus } from '../../shared/model/enumerations/event-status.model'

const EDIT_TOURNAMENT = 'EDIT_TOURNAMENT'
const SHOW_PARTICIPANTS = 'SHOW_PARTICIPANTS'
const START_TOURNAMENT = 'START_TOURNAMENT'
const FINISH_TOURNAMENT = 'FINISH_TOURNAMENT'
const APPROVE_TOURNAMENT = 'APPROVE_TOURNAMENT'
const DRAW_NEXT_STAGE = 'DRAW_NEXT_STAGE'
const EXPORT_RANKING = 'EXPORT_RANKING'
const EXPORT_FLOWCHART = 'EXPORT_FLOWCHART'
const AUTOPLAY = 'AUTOPLAY'

export interface IActiveTournamentsProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const ActiveTournaments = (props: IActiveTournamentsProps) => {

  const pageData: IPageData = {
    title: 'Active/Running Tournaments',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Tournaments',
      },
      {
        title: 'Active/Running',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'tournamentStartDate',
    order: 'desc',
  })

  const [tableFilters, setTableFilters] = useState({
    tournamentStatus: [TournamentStatus.RUNNING, TournamentStatus.GENERATED, TournamentStatus.FINISHED],
  })

  const { onSetPage } = props
  useEffect(() => {
    onSetPage(pageData)
    loadEntities()
    loadEventsFilters()
    loadDisciplines()
    loadCategories()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('useEffect: UpcomingTournaments - location')
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
    loadEventsFilters()
    loadDisciplines()
    loadCategories()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('useEffect: UpcomingTournaments - loading')
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const [eventFilters, setEventFilters] = useState([])
  useEffect(() => {
    if (!props.eventsLoading && props.events && props.events.length > 0)
      setEventFilters(props.events.map(event => ({
        text: event.name,
        value: event.id,
      })))
  }, [props.eventsLoading])

  const [disciplineFilters, setDisciplineFilters] = useState([])
  useEffect(() => {
    if (!props.disciplinesLoading && props.disciplines && props.disciplines.length > 0)
      setDisciplineFilters(props.disciplines.map(discipline => ({
        text: discipline.name,
        value: discipline.id,
      })))
  }, [props.disciplinesLoading])

  const [categoryFilters, setCategoryFilters] = useState([])
  useEffect(() => {
    if (!props.categoriesLoading && props.categories && props.categories.length > 0)
      setCategoryFilters(props.categories.map(category => ({
        text: category.name,
        value: category.id,
      })))
  }, [props.categoriesLoading])

  const loadEntities = () => {
    // console.log('load tournaments', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const loadEventsFilters = () => {
    // @ts-ignore
    props.getEvents(0, 99, 'eventStartDate,desc', {
      eventStatus: [EventStatus.RUNNING],
    })
  }

  const loadDisciplines = () => {
    props.getDisciplines(0, 99, 'name')
  }

  const loadCategories = () => {
    props.getCategories(0, 99, 'name')
  }

  const actionMenu = (tournament: ITournamentExtended) => {
    const disableBtnStartTournament = tournament.tournamentStatus !== TournamentStatus.GENERATED
    const disableBtnDrawNextStage = !tournament.readyForNextStageDraw
    const disableBtnFlowchart = tournament.tournamentStatus === TournamentStatus.CREATED
    const disableBtnAutoplay = tournament.tournamentStatus !== TournamentStatus.RUNNING
    const disableBtnFinishTournament = !tournament.readyToFinish

    return <Menu onClick={evt => handleMenuClick(evt, tournament)}>
      {/*<Menu.Item key={EDIT_TOURNAMENT}>Edit Tournament</Menu.Item>*/}
      <Menu.Item key={START_TOURNAMENT}
                 disabled={disableBtnStartTournament}>
        Start Tournament
      </Menu.Item>
      <Menu.Item key={DRAW_NEXT_STAGE}
                 disabled={disableBtnDrawNextStage}>
        Make Draw for Next-Stage
      </Menu.Item>
      {/*
      <Menu.Item key={FINISH_TOURNAMENT}
                 disabled={disableBtnFinishTournament}>
        Finish Tournament
      </Menu.Item>
*/}
      <Menu.Item key={SHOW_PARTICIPANTS}>Show Participants</Menu.Item>
      <Menu.Item key={EXPORT_RANKING}
                 disabled={disableBtnFinishTournament}>
        Export Ranking (PDF)
      </Menu.Item>
      <Menu.Item key={EXPORT_FLOWCHART}
                 disabled={disableBtnFlowchart}>
        Download Flowchart
      </Menu.Item>

      {/*
      <Menu.Item key={AUTOPLAY}
                 disabled={disableBtnAutoplay}>
        Start Autoplay
      </Menu.Item>
      */}
    </Menu>
  }

  const handleMenuClick = (evt, tournament: ITournamentExtended) => {
    switch (evt.key) {
      /*
            case EDIT_TOURNAMENT:
              history.push(`/app/tournaments/running/${tournament.id}/edit`)
              break
      */
      case START_TOURNAMENT:
        const startConfirmModal = Modal.confirm({
          okText: 'Start',
          okType: 'primary',
          cancelText: 'Back',
          cancelButtonProps: { danger: true },
          title: 'Start Tournament',
          content: 'Do you want to start the tournament ?',
          onOk: args => {
            axios
              .put(`/api/management/tournaments/${tournament.id}/start-tournament`)
              .then(() => {
                startConfirmModal.destroy()
                loadEntities()
              })
          },
        })
        break
      /*
            case FINISH_TOURNAMENT:
              history.push(`/app/tournaments/running/${tournament.id}/finish-tournament`)
              break
      */
      case DRAW_NEXT_STAGE:
        history.push(`/app/tournaments/running/${tournament.id}/draw-next-stage`)
        break
      case EXPORT_RANKING:
        getRankingPdf(tournament.id, tournament.name)
        break
      case EXPORT_FLOWCHART:
        history.push(`/app/tournaments/running/${tournament.id}/export`)
        /*
                axios.get(`/api/management/tournaments/${tournament.id}/generate-excel-chart`, { responseType: 'blob' })
                  .then((response) => {
                    saveAs(response.data, `${tournament.name}.xls`)
                  })
        */
        break
      case SHOW_PARTICIPANTS:
        history.push(`/app/tournaments/running/${tournament.id}/participants`)
        break
      case AUTOPLAY:
        console.log('Start Autoplay')
        axios
          .get(`/api/management/tournaments/${tournament.id}/autoplay`)
        break
      default:
        message.error('invalid action')
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    if (selectedKeys[0] === '')
      delete tableFilters[dataIndex]
    else
      tableFilters[dataIndex] = selectedKeys[0]
    setTableFilters({
      ...tableFilters,
    })
  }

  const handleReset = (clearFields, dataIndex) => {
    delete tableFilters[dataIndex]
    clearFields()
  }

  const handleShowCreateTournament = () => {
    history.push(`${props.match.url}/create`)
  }

  const handleShowEditTournament = (tournamentId: number) => {
    history.push(`${props.match.url}/${tournamentId}/edit`)
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24} style={{ textAlign: 'right', marginBottom: 8 }}>
        <Space>
          <Tooltip placement="top" title="Refresh List">
            <Button icon={<ReloadOutlined style={{ fontSize: 24 }}/>} type="text" onClick={loadEntities}/>
          </Tooltip>
        </Space>
      </Col>
      <Col span={24}>
        <Table id={'activeTournamentsTable'} size={'small'} showSorterTooltip
               rowKey={'id'}
               loading={props.loading}
               dataSource={props.entities.flat()}
          /*
                         rowSelection={{
                           selectedRowKeys: selectedMatches,
                           onChange: handleSelectChange,
                         }}
          */
               onChange={handleTableChange}
               pagination={{
                 defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                 total: props.totalItems,
                 pageSize: paginationState.itemsPerPage,
               }}
        >
          <Table.Column<ITournamentExtended> key={'mnemonic'} dataIndex={'mnemonic'} title={'Id'}/>
          <Table.Column<ITournamentExtended> key={'tournamentName'} dataIndex={'name'} title={'Name'}
                                             {...getColumnSearchProps('name')}
          />
          {/*
          <Table.Column<ITournamentExtended> key={'tournamentStartDate'} dataIndex={'tournamentStartDate'}
                                             title={'Start-Date'}
                                             sorter defaultSortOrder={'descend'}
                                             width={'150px'}
                                             render={(value) => <TextFormat value={value} type={'date'}
                                                                            format={APP_LOCAL_DATE_FORMAT_EU}/>}
          />
*/}
          {/*
          <Table.Column<ITournamentExtended> key={'participants'} dataIndex={'entryLimit'} title={'Participants'}
                                             render={(value, row) => `${row.numberOfParticipants}/${value}`}
          />
*/}
          <Table.Column<ITournamentExtended> key={'raceTo'} dataIndex={'raceTo'} title={'Race to'} width={75}
                                             align={'center'}/>
          <Table.Column<ITournamentExtended> key={'tournamentCategoryId'} dataIndex={'tournamentCategoryId'}
                                             title={'Classification'}
                                             width={200}
                                             filters={categoryFilters}
                                             render={(value, row) => row.tournamentCategoryName}
          />
          <Table.Column<ITournamentExtended> key={'disciplineId'} dataIndex={'disciplineId'} title={'Discipline'}
                                             width={150}
                                             filters={disciplineFilters}
                                             render={(value, row) => row.disciplineName}
          />
          <Table.Column<ITournamentExtended> key={'eventId'} dataIndex={'eventId'} title={'Event'}
                                             filters={eventFilters}
                                             render={(value, row) => row.eventName}
          />
          <Table.Column<ITournamentExtended> key={'tournamentStatus'} dataIndex={'tournamentStatus'} title={'Status'}
                                             width={65}
                                             align={'center'}
                                             render={(value, row) => {
                                               switch (value) {
                                                 case TournamentStatus.RUNNING:
                                                   return <Tooltip title={'Tournament is running'}>
                                                     <SyncOutlined spin
                                                                   style={{
                                                                     fontSize: 20,
                                                                     color: 'darkgreen',
                                                                   }}/>
                                                   </Tooltip>
                                                 case TournamentStatus.GENERATED:
                                                   return <Tooltip title={'Draw was made - ready to start'}>
                                                     <ApartmentOutlined style={{
                                                       fontSize: 20,
                                                       color: 'orange',
                                                     }}/>
                                                   </Tooltip>
                                                 case TournamentStatus.FINISHED:
                                                   return <Tooltip title={'Tournament finished - ready to approve'}>
                                                     <CheckCircleTwoTone twoToneColor="#52c41a" style={{
                                                       fontSize: 20,
                                                     }}/>
                                                   </Tooltip>
                                               }
                                             }}
          />
          <Table.Column<ITournamentExtended> key={'actions'} dataIndex={'id'}
                                             width={125} align={'right'}
                                             render={(value, row) => (
                                               <Dropdown overlay={() => actionMenu(row)} trigger={['click']}>
                                                 <Button>Actions <DownOutlined/></Button>
                                               </Dropdown>
                                             )}
          />
        </Table>
      </Col>
    </Row>
  )

}

const mapStateToProps = ({ tournamentExtended, event, tournamentCategory, discipline, authentication }: IAppState) => ({
  entities: tournamentExtended.entities,
  loading: tournamentExtended.loading,
  totalItems: tournamentExtended.totalItems,
  events: event.entities,
  eventsLoading: event.loading,
  categories: tournamentCategory.entities,
  categoriesLoading: tournamentCategory.loading,
  disciplines: discipline.entities,
  disciplinesLoading: discipline.loading,
  account: authentication.account,
})

const mapDispatchToProps = {
  getEntities,
  getEvents,
  getDisciplines,
  getCategories,
  getRankingPdf,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActiveTournaments)