import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Table, Tooltip } from 'antd'
import { getEntity, reset as resetTable } from '../../../shared/reducers/model/billiard-table.reducer'
import { getEntities as getMatches } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { APP_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'
import { TextFormat } from 'react-jhipster'
import Button from 'antd/es/button'
import { LoginOutlined } from '@ant-design/icons'
import { ITournamentMatch } from '../../../shared/model/tournament-match.model'
import axios from 'axios'
import { MatchStatus } from '../../../shared/model/enumerations/match-status.model'
import { TournamentStatus } from '../../../shared/model/enumerations/tournament-status.model'

export interface IOverviewsTablesAssignMatchModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const OverviewsTablesAssignMatchModal: React.FC<IOverviewsTablesAssignMatchModalProps> = (props: IOverviewsTablesAssignMatchModalProps) => {

  const tableId = Number(props.match.params.id)

  const [visible, setVisible] = useState(false)
  const { tableEntity } = props

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form
  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Tournaments',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'tables',
      },
      { title: 'Tables' },
      { title: tableEntity.displayNumber },
      { title: 'Assign Match' },
    ],
  }

  const [tableFilters, setTableFilters] = useState({
    matchStatus: [MatchStatus.SCHEDULED],
    tournamentStatus: [TournamentStatus.RUNNING],
  })

  useEffect(() => {
    // console.log('assign match modal')
    props.getEntity(tableId)
    return () => {
      props.resetTable()
    }
  }, [])

  useEffect(() => {
    if (!props.loading && !props.tournamentMatchesLoading && props.tableEntity && props.tableEntity.eventId) {
      setVisible(true)
      tableFilters['eventId'] = tableEntity.eventId
      setTableFilters({
        ...tableFilters,
      })
      // @ts-ignore
      props.getMatches(0, ITEMS_PER_PAGE, 'matchScheduledTime,asc', tableFilters)
      // props.getUpcomingMatches(0, ITEMS_PER_PAGE, 'matchScheduledTime')
    }
    onSetPage(pageData)
  }, [props.loading])

  useEffect(() => {
    if (!props.tournamentMatchesLoading) {

    }
  }, [props.tournamentMatchesLoading])

  const handleClose = () => {
    props.history.push('/app/matches/tables')
  }

  const handleSubmit = () => {
    validateFields().then(values => {
      // console.log(values)
    })
  }

  const handleAssignMatch = (id: number) => {
    // console.log('assign match: ', id)
    axios
      .post(`/api/management/upcoming-matches/assign-table`, null, {
        params: {
          matchId: id,
          tableId: tableId,
        },
      })
      .then(() => {
          setVisible(false)
          props.history.push('/app/matches/tables')
        },
      )
  }

  const handleQualifier = (match: ITournamentMatch, aOrB: string) => {
    // console.log('handleQualifier')
  }

  return (
    <Modal
      visible={visible}
      width={900}
      title={`Assign match to table ${tableEntity.displayNumber}`}
      destroyOnClose={true}
      onCancel={handleClose}
      onOk={handleSubmit}
      footer={[
        <Button key='backToOverview' onClick={handleClose}>Close</Button>,
      ]}
    >
      <Table id='assignMatchList' rowKey='id' size='small'
             loading={props.tournamentMatchesLoading}
             dataSource={props.tournamentMatches.flat()}
      >
        <Table.Column title='Label' dataIndex='label'/>
        <Table.Column title='Scheduled Time' dataIndex='matchScheduledTime'
                      render={(value) => <TextFormat type="date" value={value} format={APP_DATETIME_FORMAT_EU}/>}/>
        {/*<Table.Column title='Player A' dataIndex='participantADisplayName'/>*/}
        <Table.Column title='Player A' dataIndex='participantADisplayName' render={(value, row: ITournamentMatch) => (
          <>
            {row.aPlaceholder ? 'Qualifier' : row.aWalkover ? 'Walkover' : value}
            {/*
            {row.aPlaceholder ? (
              <Button size='small' type='primary' block onClick={() => handleQualifier(row, 'a')}>Qualifier</Button>
            ) : row.aWalkover ? 'Walkover' : value
            }
*/}
          </>
        )}/>
        {/*<Table.Column title='Player B' dataIndex='participantBDisplayName'/>*/}
        <Table.Column title='Player B' dataIndex='participantBDisplayName' render={(value, row: ITournamentMatch) => (
          <>
            {row.bPlaceholder ? 'Qualifier' : row.bWalkover ? 'Walkover' : value}
            {/*
            {row.bPlaceholder ? (
              <Button size='small' type='primary' block onClick={() => handleQualifier(row, 'b')}>Qualifier</Button>
            ) : row.bWalkover ? 'Walkover' : value
            }
*/}
          </>
        )}/>
        <Table.Column title='' dataIndex='id'
                      render={(value, row: ITournamentMatch) => <Tooltip placement='left'
                                                                         title={row.aPlaceholder || row.bPlaceholder ? 'Replace Qualifier in match list' : 'Assign this match to the table'}>
                        <Button onClick={() => handleAssignMatch(value)} icon={<LoginOutlined/>} shape='circle'
                                disabled={row.aPlaceholder || row.bPlaceholder}/>
                      </Tooltip>}/>
      </Table>
    </Modal>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  tableEntity: storeState.billiardTable.entity,
  loading: storeState.billiardTable.loading,
  tournamentMatches: storeState.tournamentMatchExtended.entities,
  tournamentMatchesLoading: storeState.tournamentMatchExtended.loading,
})

const mapDispatchToProps = {
  getEntity,
  getMatches,
  resetTable,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewsTablesAssignMatchModal))
