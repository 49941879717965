import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, ISponsorForEvent } from '../../model/sponsor-for-event.model'

export const ACTION_TYPES = {
  SEARCH_SPONSORFOREVENTS: 'sponsorForEvent/SEARCH_SPONSORFOREVENTS',
  FETCH_SPONSORFOREVENT_LIST: 'sponsorForEvent/FETCH_SPONSORFOREVENT_LIST',
  FETCH_SPONSORFOREVENT: 'sponsorForEvent/FETCH_SPONSORFOREVENT',
  CREATE_SPONSORFOREVENT: 'sponsorForEvent/CREATE_SPONSORFOREVENT',
  UPDATE_SPONSORFOREVENT: 'sponsorForEvent/UPDATE_SPONSORFOREVENT',
  DELETE_SPONSORFOREVENT: 'sponsorForEvent/DELETE_SPONSORFOREVENT',
  RESET: 'sponsorForEvent/RESET',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISponsorForEvent>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type SponsorForEventState = Readonly<typeof initialState>;

// Reducer

export default (state: SponsorForEventState = initialState, action): SponsorForEventState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_SPONSORFOREVENTS):
    case REQUEST(ACTION_TYPES.FETCH_SPONSORFOREVENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SPONSORFOREVENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.CREATE_SPONSORFOREVENT):
    case REQUEST(ACTION_TYPES.UPDATE_SPONSORFOREVENT):
    case REQUEST(ACTION_TYPES.DELETE_SPONSORFOREVENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.SEARCH_SPONSORFOREVENTS):
    case FAILURE(ACTION_TYPES.FETCH_SPONSORFOREVENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SPONSORFOREVENT):
    case FAILURE(ACTION_TYPES.CREATE_SPONSORFOREVENT):
    case FAILURE(ACTION_TYPES.UPDATE_SPONSORFOREVENT):
    case FAILURE(ACTION_TYPES.DELETE_SPONSORFOREVENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.SEARCH_SPONSORFOREVENTS):
    case SUCCESS(ACTION_TYPES.FETCH_SPONSORFOREVENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_SPONSORFOREVENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.CREATE_SPONSORFOREVENT):
    case SUCCESS(ACTION_TYPES.UPDATE_SPONSORFOREVENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.DELETE_SPONSORFOREVENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
};

const apiUrl = 'api/sponsor-for-events'
const apiSearchUrl = 'api/_search/sponsor-for-events'

// Actions

export const getSearchEntities: ICrudSearchAction<ISponsorForEvent> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_SPONSORFOREVENTS,
  payload: axios.get<ISponsorForEvent>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
})

export const getEntities: ICrudGetAllAction<ISponsorForEvent> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  return {
    type: ACTION_TYPES.FETCH_SPONSORFOREVENT_LIST,
    payload: axios.get<ISponsorForEvent>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<ISponsorForEvent> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_SPONSORFOREVENT,
    payload: axios.get<ISponsorForEvent>(requestUrl),
  }
}

export const createEntity: ICrudPutAction<ISponsorForEvent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SPONSORFOREVENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}

export const updateEntity: ICrudPutAction<ISponsorForEvent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SPONSORFOREVENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  return result
}

export const deleteEntity: ICrudDeleteAction<ISponsorForEvent> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SPONSORFOREVENT,
    payload: axios.delete(requestUrl),
  })
  dispatch(getEntities())
  return result
}

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})

export const getEntitiesForEvent: ICrudGetAllAction<ISponsorForEvent> = (page, size, sort, eventId?: number) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${eventId ? `${sort ? '&' : '?'}eventId.equals=${eventId}` : ''}`
  return {
    type: ACTION_TYPES.FETCH_SPONSORFOREVENT_LIST,
    payload: axios.get<ISponsorForEvent>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

