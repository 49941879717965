import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../../redux/store'
import { Form, Modal, Radio, Select } from 'antd'
import axios from 'axios'

import { getEntity, reset, updateEntity } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'

import './match-history.scss'
import moment from 'moment'
import { APP_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'

export interface IMatchEditQualifierModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, side: string, type: string }> {
}

const ShowEditMatchModal: React.FC<IMatchEditQualifierModalProps> = (props: IMatchEditQualifierModalProps) => {

  const matchId = Number(props.match.params.id)
  const participantSide = props.match.params.side
  const matchType = props.match.params.type

  const [visible, showModal] = useState(false)

  const [submitLoading, setLoadingSubmit] = useState(false)

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const getAvailablePlayers = async (match) => {
    setAvailablePlayersLoading(true)
    await axios
      .get(`/api/management/tournaments/${match.tournamentId}/participants/available-players`)
      .then(({ data }) => {
        setAvailablePlayers(data.map(participant => ({
          label: `${participant.displayName} (${participant.id})`,
          value: participant.id,
        })))
        setAvailablePlayersLoading(false)
      })
  }

  const getSameRoundMatches = async (match) => {
    setSameRoundMatchesLoading(true)
    await axios
      .get(`/api/management/upcoming-matches/same-round-matches/${match.id}`)
      .then(({ data }) => {
        setSameRoundMatches(data.map(match => ({
          label: `${moment(match.matchScheduledTime).format(APP_DATETIME_FORMAT_EU)} | ${match.participantADisplayName} - ${match.participantBDisplayName}`,
          value: match.id,
        })))
        setSameRoundMatchesLoading(false)
      })
  }

  useEffect(() => {
    // console.log('default effect', matchId, props.loading)
    if (matchId && !props.loading)
      props.getEntity(matchId)
  }, [])

  useEffect(() => {
    // console.log('loading effect', props.loading)
    if (!props.loading && props.tournamentMatch) {
      let formValues = {
        ...props.tournamentMatch,
        matchScheduledTime: moment(props.tournamentMatch.matchScheduledTime),
      }
      setFieldsValue(formValues)
      showModal(true)
    }
  }, [props.loading, props.tournamentMatch])

  const onSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        axios
          .post(`/api/management/upcoming-matches/replace-placeholder`, null, {
            params: {
              matchId: matchId,
              howToReplace: values.howToReplace,
              replacementMatchId: Number(values.replacementMatchId) || 0,
              replacementPlayerId: Number(values.replacementPlayerId) || 0,
              playerABSelection: participantSide,
            },
          })
          .then(() => {
              setLoadingSubmit(false)
              showModal(false)
              props.history.goBack()
              props.reset()
            },
          )
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        setTimeout(() => {
          setLoadingSubmit(false)
        }, 1000)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.goBack()
    props.reset()
  }

  const onReset = () => {
    form.resetFields()
  }

  const [availablePlayers, setAvailablePlayers] = useState([])
  const [availablePlayersLoading, setAvailablePlayersLoading] = useState(false)
  const [replacementPlayerRequired, setReplacementPlayerRequired] = useState(false)
  const [replacementSessionTimeRequired, setReplacementSessionTimeRequired] = useState(false)
  const [sameRoundMatches, setSameRoundMatches] = useState([])
  const [sameRoundMatchesLoading, setSameRoundMatchesLoading] = useState(false)
  const handleChangeOfQualifierReplaceType = (evt) => {
    switch (evt.target.value) {
      case 'withPlayer':
        if (availablePlayers.length === 0)
          getAvailablePlayers(props.tournamentMatch)
        setReplacementPlayerRequired(true)
        setReplacementSessionTimeRequired(false)
        break
      case 'switchSessionTime':
        if (sameRoundMatches.length === 0)
          getSameRoundMatches(props.tournamentMatch)
        setReplacementPlayerRequired(false)
        setReplacementSessionTimeRequired(true)
        break
      default:
        setReplacementPlayerRequired(false)
        setReplacementSessionTimeRequired(false)
    }
  }

  return <Modal
    width={'50%'}
    title={`Replace Qualifier from match ${props.tournamentMatch?.label}`}
    visible={visible}
    destroyOnClose={true}
    okText="Accept"
    okType="primary"
    onOk={onSubmit}
    onCancel={onCancel}
    cancelText="Cancel"
    afterClose={() => {
      setReplacementSessionTimeRequired(false)
      setReplacementPlayerRequired(false)
      setSameRoundMatches([])
      setAvailablePlayers([])
    }}
  >
    <Form form={form} layout="vertical"
          initialValues={{
            howToReplace: 'withWalkover',
          }}>
      <Form.Item label="Replace with" name="howToReplace">
        <Radio.Group onChange={handleChangeOfQualifierReplaceType} buttonStyle="solid">
          <Radio.Button value="withWalkover">Walkover</Radio.Button>
          <Radio.Button value="withPlayer">Player</Radio.Button>
          <Radio.Button value="switchSessionTime">other Match</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Replace with new participant" name="replacementPlayerId"
                 hidden={!replacementPlayerRequired}
                 required={replacementPlayerRequired}
                 extra={'Only non registered Players displayed'}>
        <Select showSearch autoClearSearchValue clearIcon placeholder="Select a player"
                disabled={!replacementPlayerRequired}
                loading={availablePlayersLoading}
                optionFilterProp="label"
                options={availablePlayers}
        />
      </Form.Item>
      <Form.Item label="Change start-time with match" name="replacementMatchId"
                 hidden={!replacementSessionTimeRequired}
                 required={replacementSessionTimeRequired}
                 extra={'Only matches from same round displayed'}
      >
        <Select placeholder="Select a match"
                notFoundContent={'no matches for switching available'}
                disabled={!replacementSessionTimeRequired}
                loading={sameRoundMatchesLoading}
                optionFilterProp="label"
                options={sameRoundMatches}
        />
      </Form.Item>
    </Form>
  </Modal>
}

const mapStateToProps = ({ tournamentMatchExtended }: IAppState) => ({
  tournamentMatch: tournamentMatchExtended.entity,
  loading: tournamentMatchExtended.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowEditMatchModal))
