import React, { useEffect, useState } from 'react'
import { IAppState } from '../../redux/store'
import { connect } from 'react-redux'
import { Checkbox, Form, Input, message, Modal, Table } from 'antd'
import { IPageData, IPageProps } from '../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import axios from 'axios'

import { getEntity, updateEntity } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { getEntities as getQualifiers } from './tournament-participants.reducer'
import { getEntity as getChartTemplate } from '../../shared/reducers/model/chart-template.reducer'
import { TournamentStatus } from '../../shared/model/enumerations/tournament-status.model'

import { IParticipant } from '../../shared/model/participant.model'

export interface IMakeDrawNextStageModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const MakeDrawNextStageModal: React.FC<IMakeDrawNextStageModalProps> = (props: IMakeDrawNextStageModalProps) => {

  const [visible, showModal] = useState(false)

  const tournamentId = Number(props.match.params.id)
  const { tournamentEntity, loading, updating, qualifiers, chartTemplate } = props
  const notFound = tournamentEntity.id !== tournamentId

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form
  const { onSetPage } = props

  useEffect(() => {
    // console.log(`load tournament entity with id ${tournamentId}`)
    props.getEntity(tournamentId)
  }, [])

  const pageData: IPageData = {
    title: 'Tournaments',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'matches',
      },
      { title: 'Tournament' },
      { title: tournamentEntity.name },
      { title: 'Draw Next Stage' },
    ],
  }

  useEffect(() => {
    // console.log('qualifiers',qualifiers)
    if (qualifiers && qualifiers.length > 0) {
      showModal(true)
      onSetPage(pageData)
    } else if (qualifiers && qualifiers.length == 0) {
      message.warn('no qualifiers found')
    }
  }, [qualifiers])

  useEffect(() => {
    // console.log('chartTemplate: ', chartTemplate)
    if (chartTemplate?.id) {
      //@ts-ignore
      // props.getQualifiers(0, chartTemplate.numberOfQualifierStage1 / 2, 'qualifierForStage,desc', {
      props.getQualifiers(0, 999, 'displayName,asc', {
        tournamentId: tournamentId,
        qualifierForStage: tournamentEntity.currentRunningStage + 1,
      })
    }
  }, [chartTemplate])

  useEffect(() => console.log('chartTemplateLoading:', props.chartTemplateLoading), [props.chartTemplateLoading])

  useEffect(() => {
    // console.log('tournamentEntity:', tournamentEntity)
    if (!loading && !notFound && tournamentEntity && tournamentEntity.id > 0) {
      if (tournamentEntity.tournamentStatus !== TournamentStatus.RUNNING || !tournamentEntity.readyForNextStageDraw) {
        message.warn('Draw next stage for this tournament not allowed')
        handleClose()
      }
      props.getChartTemplate(tournamentEntity.chartTemplateId)
    }
  }, [tournamentEntity, loading])

  const handleClose = () => {
    props.history.goBack()
  }

  const handleSubmit = () => {
    axios.put(`/api/management/tournaments/${tournamentId}/draw-next-stage`)
      .then(resp => {
        message.success('Next Stage Draw done. Matches announced.')
        handleClose()
      })
      .catch(reason => {
        message.error('Something goes wrong. Try again or contact administrator.')
        // console.log(reason)
      })
  }

  return (
    <>
      <Modal visible={visible}
             width={700}
             title={`Draw stage ${tournamentEntity.readyForNextStageDraw ? tournamentEntity.currentRunningStage + 1 : ''} for ${tournamentEntity.name} (${tournamentEntity.eventName})`}
             destroyOnClose={true}
             onCancel={handleClose}
             onOk={handleSubmit}
      >
        <Form form={form} id="drawNextStageForm" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
          <Form.Item name="id" hidden>
            <Input type={'hidden'}/>
          </Form.Item>
          <Table<IParticipant> id="qualifierList"
                               size={'small'}
                               scroll={{ y: 500 }}
                               pagination={false}
                               rowKey={'id'}
                               dataSource={qualifiers.flat()}>
            <Table.Column<IParticipant> key="displayName" title="Losers Qualifiers"
                                        dataIndex="displayName"
                                        defaultSortOrder="ascend"
                                        sorter={(a, b) => a.displayName > b.displayName ? 1 : -1}/>
          </Table>
          <Form.Item name="confirmation" valuePropName="checked" wrapperCol={{ span: 24 }}
                     rules={[{ required: true, message: 'Please confirm' }]}
                     style={{ textAlign: 'center' }}
          >
            <Checkbox>Yes, I want to make the draw. This cannot be undone.</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  tournamentEntity: storeState.tournamentExtended.entity,
  loading: storeState.tournamentExtended.loading,
  updating: storeState.tournamentExtended.updating,
  updateSuccess: storeState.tournamentExtended.updateSuccess,
  qualifiers: storeState.tournamentParticipants.entities,
  chartTemplate: storeState.chartTemplate.entity,
  chartTemplateLoading: storeState.chartTemplate.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  getQualifiers,
  getChartTemplate,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MakeDrawNextStageModal))
