import React, {useEffect, useRef, useState} from 'react'
import {RouteComponentProps} from "react-router-dom";
import {IPageData, IPageProps} from "../../../interfaces/page-data";
import {connect} from "react-redux";
import {IPaginationBaseState} from "react-jhipster";
import {DEFAULT_ITEMS_PER_PAGE} from "../../../utils/pagination.constants";
import {MatchStatus} from "../../../shared/model/enumerations/match-status.model";
import {TournamentStatus} from "../../../shared/model/enumerations/tournament-status.model";
import axios from "axios";
import {ITournamentMatchExtended} from "../../../shared/model/extended/tournament-match.extended.model";
import {Col, Row, Table} from "antd";
import Button from "antd/es/button";
import {IAppState} from "../../../redux/store";
import {getEntities} from "../../../shared/reducers/model/extended/tournament-match.extended.reducer";

export interface IMatchesRunningProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{
    url: string
}> {
}

const MatchesRunning = (props: IMatchesRunningProps) => {

    const pageData: IPageData = {
        title: 'Uniform Control',
        loaded: false,
        breadcrumbs: [
            {
                title: 'BitsButler',
                route: '/',
            },
            {
                title: 'Referee',
            },
            {
                title: 'Uniform control',
            },
        ],
    }

    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        itemsPerPage: 999,
        activePage: 1,
        sort: 'tableDisplayNumber',
        order: 'asc',
    })

    const [tableFilters, setTableFilters] = useState({
        matchStatus: [MatchStatus.READY],
        tournamentStatus: [TournamentStatus.RUNNING],
    })

    const autoRefreshTimer = useRef(null)

    const {onSetPage} = props
    useEffect(() => {
        onSetPage(pageData)
        // loadEntities()
        autoRefreshTimer.current = setInterval(loadEntities, 20000)
        return () => {
            clearTimeout(autoRefreshTimer.current)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onSetPage({...pageData, loaded: true})
        loadEntities()
    }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!props.loading)
            onSetPage({...pageData, loaded: true})
    }, [props.loading])

    const loadEntities = () => {
        // @ts-ignore
        props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
    }

    const handleApproveUniform = (tournamentMatchId: number, side: string) => {
        axios
            .put('/api/management/referee/uniform/toggle-uniform', null, {
                params: {
                    tournamentMatchId: tournamentMatchId,
                    side: side
                },
            })
            .then(() => {
                loadEntities()
            })
    }

    return (
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
            <Col span={24}>
                <Table<ITournamentMatchExtended> id={'tournamentMatchesTable'} rowKey={'id'} loading={props.loading}
                                                 size={'small'}
                                                 showSorterTooltip
                                                 dataSource={props.entities.flat()}
                                                 pagination={{
                                                     defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                                                     total: props.totalItems,
                                                     pageSize: paginationState.itemsPerPage,
                                                 }}
                >
                    <Table.Column<ITournamentMatchExtended> dataIndex={'participantADisplayName'} title={'Player A'}
                                                            render={(value, row) => value ? (
                                                                <span>
                                                                    <img style={{height: '18px'}} src={'data:' + row.participantAFlagContentType + ';base64,' + row.participantAFlagImg}/> {value}
                                                                </span>
                                                            ) : 'waiting for player'}/>
                    <Table.Column<ITournamentMatchExtended> dataIndex={'aUniformApproved'} align={'center'} render={(value, row) => {
                        return !value ?
                            <Button type={'primary'} danger block onClick={() => handleApproveUniform(row.id, 'A')}>not approved</Button>
                            :
                            <Button type={'primary'} block color={'success'} onClick={() => handleApproveUniform(row.id, 'A')}>approved</Button>
                    }}/>
                    <Table.Column<ITournamentMatchExtended> dataIndex={'tableDisplayNumber'} sorter title={'Table'}
                                                            align={'center'}
                                                            defaultSortOrder={'ascend'}
                    />
                    <Table.Column<ITournamentMatchExtended> dataIndex={'participantBDisplayName'} title={'Player B'}
                                                            render={(value, row) => value ? (
                                                                <span>
                                                                    <img style={{height: '18px'}} src={'data:' + row.participantBFlagContentType + ';base64,' + row.participantBFlagImg}/> {value}
                                                                </span>
                                                            ) : 'waiting for player'}/>
                    <Table.Column<ITournamentMatchExtended> dataIndex={'bUniformApproved'} align={'center'} render={(value, row) => {
                        return !value ?
                            <Button type={'primary'} danger block onClick={() => handleApproveUniform(row.id, 'B')}>not approved</Button>
                            :
                            <Button type={'primary'} block color={'success'} onClick={() => handleApproveUniform(row.id, 'B')}>approved</Button>
                    }}/>
                </Table>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ authentication, tournamentMatchExtended, tournamentsRunning }: IAppState) => ({
    loading: tournamentMatchExtended.loading,
    entities: tournamentMatchExtended.entities,
    totalItems: tournamentMatchExtended.totalItems,
    account: authentication.account,
})

const mapDispatchToProps = {
    getEntities,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MatchesRunning)