import axios from 'axios'
import {
  ICrudDeleteAction,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudSearchAction,
  loadMoreDataWhenScrolled,
  parseHeaderForLinks,
} from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IOverallRanking } from '../../model/overall-ranking.model'
import { RankingCategory } from '../../model/enumerations/ranking-category.model'

export const ACTION_TYPES = {
  SEARCH_OVERALLRANKINGS: 'overallRanking/SEARCH_OVERALLRANKINGS',
  FETCH_OVERALLRANKING_LIST: 'overallRanking/FETCH_OVERALLRANKING_LIST',
  FETCH_OVERALLRANKING: 'overallRanking/FETCH_OVERALLRANKING',
  CREATE_OVERALLRANKING: 'overallRanking/CREATE_OVERALLRANKING',
  UPDATE_OVERALLRANKING: 'overallRanking/UPDATE_OVERALLRANKING',
  DELETE_OVERALLRANKING: 'overallRanking/DELETE_OVERALLRANKING',
  RESET: 'overallRanking/RESET',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IOverallRanking>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type OverallRankingState = Readonly<typeof initialState>;

// Reducer

export default (state: OverallRankingState = initialState, action): OverallRankingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_OVERALLRANKINGS):
    case REQUEST(ACTION_TYPES.FETCH_OVERALLRANKING_LIST):
    case REQUEST(ACTION_TYPES.FETCH_OVERALLRANKING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.CREATE_OVERALLRANKING):
    case REQUEST(ACTION_TYPES.UPDATE_OVERALLRANKING):
    case REQUEST(ACTION_TYPES.DELETE_OVERALLRANKING):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.SEARCH_OVERALLRANKINGS):
    case FAILURE(ACTION_TYPES.FETCH_OVERALLRANKING_LIST):
    case FAILURE(ACTION_TYPES.FETCH_OVERALLRANKING):
    case FAILURE(ACTION_TYPES.CREATE_OVERALLRANKING):
    case FAILURE(ACTION_TYPES.UPDATE_OVERALLRANKING):
    case FAILURE(ACTION_TYPES.DELETE_OVERALLRANKING):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.SEARCH_OVERALLRANKINGS):
    case SUCCESS(ACTION_TYPES.FETCH_OVERALLRANKING_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link)

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_OVERALLRANKING):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.CREATE_OVERALLRANKING):
    case SUCCESS(ACTION_TYPES.UPDATE_OVERALLRANKING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.DELETE_OVERALLRANKING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
};

const apiUrl = 'api/overall-rankings'
const apiSearchUrl = 'api/_search/overall-rankings'

// Actions

export const getSearchEntities: ICrudSearchAction<IOverallRanking> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_OVERALLRANKINGS,
  payload: axios.get<IOverallRanking>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
})

export const getEntities: ICrudGetAllAction<IOverallRanking> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  return {
    type: ACTION_TYPES.FETCH_OVERALLRANKING_LIST,
    payload: axios.get<IOverallRanking>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<IOverallRanking> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_OVERALLRANKING,
    payload: axios.get<IOverallRanking>(requestUrl),
  }
}

export const createEntity: ICrudPutAction<IOverallRanking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_OVERALLRANKING,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  })
  return result
}

export const updateEntity: ICrudPutAction<IOverallRanking> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_OVERALLRANKING,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  return result
}

export const deleteEntity: ICrudDeleteAction<IOverallRanking> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_OVERALLRANKING,
    payload: axios.delete(requestUrl),
  })
  return result
}

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})

export const getEntitiesByCategory: ICrudGetAllAction<IOverallRanking> = (page, size, sort, rankingCategory?: RankingCategory) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}rankingCategory.equals=${rankingCategory.toString()}`
  return {
    type: ACTION_TYPES.FETCH_OVERALLRANKING_LIST,
    payload: axios.get<IOverallRanking>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  }
}
