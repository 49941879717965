import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IFederation } from '../../model/federation.model'

export const ACTION_TYPES = {
  SEARCH_FEDERATIONS: 'federation/SEARCH_FEDERATIONS',
  FETCH_FEDERATION_LIST: 'federation/FETCH_FEDERATION_LIST',
  FETCH_FEDERATION: 'federation/FETCH_FEDERATION',
  CREATE_FEDERATION: 'federation/CREATE_FEDERATION',
  UPDATE_FEDERATION: 'federation/UPDATE_FEDERATION',
  DELETE_FEDERATION: 'federation/DELETE_FEDERATION',
  RESET: 'federation/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IFederation>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type FederationState = Readonly<typeof initialState>;

// Reducer

export default (state: FederationState = initialState, action): FederationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_FEDERATIONS):
    case REQUEST(ACTION_TYPES.FETCH_FEDERATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_FEDERATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_FEDERATION):
    case REQUEST(ACTION_TYPES.UPDATE_FEDERATION):
    case REQUEST(ACTION_TYPES.DELETE_FEDERATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_FEDERATIONS):
    case FAILURE(ACTION_TYPES.FETCH_FEDERATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FEDERATION):
    case FAILURE(ACTION_TYPES.CREATE_FEDERATION):
    case FAILURE(ACTION_TYPES.UPDATE_FEDERATION):
    case FAILURE(ACTION_TYPES.DELETE_FEDERATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_FEDERATIONS):
    case SUCCESS(ACTION_TYPES.FETCH_FEDERATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_FEDERATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_FEDERATION):
    case SUCCESS(ACTION_TYPES.UPDATE_FEDERATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_FEDERATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/federations';
const apiSearchUrl = 'api/_search/federations';

// Actions

export const getSearchEntities: ICrudSearchAction<IFederation> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_FEDERATIONS,
  payload: axios.get<IFederation>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IFederation> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_FEDERATION_LIST,
    payload: axios.get<IFederation>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IFederation> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_FEDERATION,
    payload: axios.get<IFederation>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IFederation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FEDERATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IFederation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_FEDERATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IFederation> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FEDERATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
