import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, ITournamentCategory } from '../../model/tournament-category.model'

export const ACTION_TYPES = {
  SEARCH_TOURNAMENTCATEGORIES: 'tournamentCategory/SEARCH_TOURNAMENTCATEGORIES',
  FETCH_TOURNAMENTCATEGORY_LIST: 'tournamentCategory/FETCH_TOURNAMENTCATEGORY_LIST',
  FETCH_TOURNAMENTCATEGORY: 'tournamentCategory/FETCH_TOURNAMENTCATEGORY',
  CREATE_TOURNAMENTCATEGORY: 'tournamentCategory/CREATE_TOURNAMENTCATEGORY',
  UPDATE_TOURNAMENTCATEGORY: 'tournamentCategory/UPDATE_TOURNAMENTCATEGORY',
  DELETE_TOURNAMENTCATEGORY: 'tournamentCategory/DELETE_TOURNAMENTCATEGORY',
  RESET: 'tournamentCategory/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITournamentCategory>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type TournamentCategoryState = Readonly<typeof initialState>;

// Reducer

export default (state: TournamentCategoryState = initialState, action): TournamentCategoryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_TOURNAMENTCATEGORIES):
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENTCATEGORY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TOURNAMENTCATEGORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_TOURNAMENTCATEGORY):
    case REQUEST(ACTION_TYPES.UPDATE_TOURNAMENTCATEGORY):
    case REQUEST(ACTION_TYPES.DELETE_TOURNAMENTCATEGORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_TOURNAMENTCATEGORIES):
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENTCATEGORY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TOURNAMENTCATEGORY):
    case FAILURE(ACTION_TYPES.CREATE_TOURNAMENTCATEGORY):
    case FAILURE(ACTION_TYPES.UPDATE_TOURNAMENTCATEGORY):
    case FAILURE(ACTION_TYPES.DELETE_TOURNAMENTCATEGORY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_TOURNAMENTCATEGORIES):
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENTCATEGORY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_TOURNAMENTCATEGORY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_TOURNAMENTCATEGORY):
    case SUCCESS(ACTION_TYPES.UPDATE_TOURNAMENTCATEGORY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_TOURNAMENTCATEGORY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tournament-categories';
const apiSearchUrl = 'api/_search/tournament-categories';

// Actions

export const getSearchEntities: ICrudSearchAction<ITournamentCategory> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_TOURNAMENTCATEGORIES,
  payload: axios.get<ITournamentCategory>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<ITournamentCategory> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENTCATEGORY_LIST,
    payload: axios.get<ITournamentCategory>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ITournamentCategory> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TOURNAMENTCATEGORY,
    payload: axios.get<ITournamentCategory>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ITournamentCategory> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TOURNAMENTCATEGORY,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITournamentCategory> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TOURNAMENTCATEGORY,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITournamentCategory> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TOURNAMENTCATEGORY,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
