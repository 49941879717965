export interface ISponsor {
  id?: number;
  name?: string;
  lemma?: string;
  description?: any;
  street?: string;
  postcode?: string;
  city?: string;
  state?: string;
  telephone?: string;
  telefax?: string;
  website?: string;
  email?: string;
  mainSponsor?: boolean;
  premiumSponsor?: boolean;
  mainLogoImgContentType?: string;
  mainLogoImg?: any;
  mediumLogoImgContentType?: string;
  mediumLogoImg?: any;
  smallLogoImgContentType?: string;
  smallLogoImg?: any;
  countryName?: string;
  countryId?: number;
}

export const defaultValue: Readonly<ISponsor> = {
  mainSponsor: false,
  premiumSponsor: false,
};
