import { IEvent } from '../../shared/model/event.model'
import { Button, Checkbox, Form, message, Modal, Space, Table, Tooltip } from 'antd'
import { IAppState } from '../../redux/store'
import { deleteEntity, getEntitiesForEvent as loadSponsorForEvent, reset } from '../../shared/reducers/model/sponsor-for-event.reducer'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import EventSponsorsAddModal from './event-sponsors-add'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

export interface IEventSponsorsProps extends StateProps, DispatchProps {
  visible: boolean,
  event: IEvent,
  onClose: any,
}

const EventSponsorsModal: React.FC<IEventSponsorsProps> = (props: IEventSponsorsProps) => {

  const [form] = Form.useForm()
  const { event, visible, onClose } = props
  const [isEventSponsorsAddModalVisible, showEventSponsorsAddModal] = useState(false)
  const [sponsorForEventId, setSponsorForEventId] = useState(0)

  const getSponsors = () => {
    // @ts-ignore
    props.loadSponsorForEvent(0, 999, 'sponsorName', event?.id)
  }

  useEffect(() => {
    return (() => {
      showEventSponsorsAddModal(false)
    })
  }, [])

  useEffect(() => {
    if (visible && event && event.id)
      getSponsors()
  }, [visible, event])

  const handleDeleteEntity = (entity) => {
    const confirmDeletion = Modal.confirm({
      title: `Remove ${entity.sponsorName}?`,
      content: (<>
        <p>Are you sure you want to remove the following sponsor from event?</p>
        <h6>{entity.sponsorName}</h6>
      </>),
      okText: 'Remove',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => {
        props.deleteEntity(entity.id)
        getSponsors()
        setTimeout(() => {
          message.info('Sponsor removed')
          confirmDeletion.destroy()
        }, 1000)
      },
      onCancel: () => {
        confirmDeletion.destroy()
      },
    })
  }

  return <>
    <Modal visible={visible}
           width={980}
           title={`Sponsors of Event: ${event?.name}`}
           destroyOnClose
           onCancel={onClose}
           footer={(
             <Space>
               <Button key='addSponsor' onClick={() => showEventSponsorsAddModal(true)}>Add Sponsor</Button>
               <Button key='closeModal' type='primary' onClick={onClose}>Close</Button>
             </Space>
           )}
    >
      <Table key='sponsorsForEventTable' id='sponsorsForEventTable'
             rowKey='id'
             size='small'
             loading={props.sponsorsLoading}
             dataSource={props.sponsors.flat()}
             pagination={{ hideOnSinglePage: false, position: ['topRight', 'bottomRight'] }}
      >
        <Table.Column title={'Name'} dataIndex={'sponsorName'}/>
        <Table.ColumnGroup colSpan={3} title={'Show on Website'}>
          <Table.Column title={'ETM'} dataIndex={'etmWebsite'} align={'center'}
                        render={value => <Checkbox disabled checked={value}/>}/>
          <Table.Column title={'ETW'} dataIndex={'etwWebsite'} align={'center'}
                        render={value => <Checkbox disabled checked={value}/>}/>
          <Table.Column title={'EC'} dataIndex={'etcWebsite'} align={'center'}
                        render={value => <Checkbox disabled checked={value}/>}/>
        </Table.ColumnGroup>
        <Table.Column title={'Invitation'} dataIndex={'invitation'} align={'center'}
                      render={value => <Checkbox disabled checked={value}/>}/>
        <Table.Column title={'Banner'} dataIndex={'eventBanner'} align={'center'}
                      render={value => <Checkbox disabled checked={value}/>}/>
        <Table.Column title={'Flowchart'} dataIndex={'flowchart'} align={'center'}
                      render={value => <Checkbox disabled checked={value}/>}/>
        <Table.Column title={'Local Sponsor'} dataIndex={'localSponsor'} align={'center'}
                      render={value => <Checkbox disabled checked={value}/>}/>
        <Table.Column title={'Level'} dataIndex={'sponsorLevel'}/>
        <Table.Column title={''} dataIndex={'id'} render={(id, row) => (
          <Space>
            <EditOutlined onClick={() => {
              setSponsorForEventId(id)
              showEventSponsorsAddModal(true)
            }}
            />
            <Tooltip title={'Remove sponsor from event'} placement={'left'}>
              <DeleteOutlined onClick={() => handleDeleteEntity(row)}
                              style={{color: 'red' }}
              />
            </Tooltip>
          </Space>
        )}/>
      </Table>
    </Modal>
    <EventSponsorsAddModal visible={isEventSponsorsAddModalVisible} event={event} updateId={sponsorForEventId}
                           onClose={() => {
                             showEventSponsorsAddModal(false)
                             props.reset()
                             getSponsors()
                           }}/>
  </>
}

const mapStateToProps = ({ sponsorForEvent }: IAppState) => ({
  sponsors: sponsorForEvent.entities,
  sponsorsLoading: sponsorForEvent.loading,
  sponsorsTotal: sponsorForEvent.totalItems,
})

const mapDispatchToProps = {
  loadSponsorForEvent,
  deleteEntity,
  reset
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsorsModal)
