import axios from 'axios'
import { ICrudGetAllExtendedAction } from '../../../../utils/table-utils'

import { IParticipant } from '../../../model/participant.model'
import { ACTION_TYPES, apiUrl } from '../participant.reducer'

export const getSeededPlayers: ICrudGetAllExtendedAction<IParticipant> = (filters: {}) => {
  let requestUrl = `${apiUrl}?extended=true`
  if (filters) {
    if (filters['tournamentId']) {
      requestUrl += `&tournamentId=${filters['tournamentId']}`
    }
    if (filters['seedingId']) {
      requestUrl += `&seedingId=${filters['seedingId']}`
    }
    if (filters['numberOfSeededPlayers']) {
      requestUrl += `&numberOfSeededPlayers=${filters['numberOfSeededPlayers']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_PARTICIPANT_LIST,
    payload: axios.get<IParticipant>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  }
}
