import { Moment } from 'moment';
import { INewsAttachment } from './news-attachment.model';
import { ITag } from './tag.model';

export interface INewsPost {
  id?: number;
  subject?: string;
  introduction?: any;
  text?: any;
  postDate?: string;
  published?: boolean;
  attachments?: INewsAttachment[];
  userLogin?: string;
  userId?: number;
  tags?: ITag[];
}

export const defaultValue: Readonly<INewsPost> = {
  published: false,
};
