import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { Link, RouteComponentProps } from 'react-router-dom'
import { IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import { getSearchEntities, reset } from '../../../shared/reducers/model/player-profile.reducer'
import { overridePaginationStateWithQueryParams } from '../../../utils/entity-utils'
import { getSortState, IPaginationBaseState, TextFormat } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { Button, Checkbox, Col, Input, Row, Space, Table } from 'antd'
import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

import './player-profiles.scss'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../../redux/settings/constants'
import { Sex } from '../../../shared/model/enumerations/sex.model'
import { ColumnsType } from 'antd/es/table'
import { IPlayerProfile } from '../../../shared/model/player-profile.model'
import { getEntities } from '../../../shared/reducers/model/extended/player-profile.extended.reducer'

export interface IPlayerProfilesProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const PlayerProfiles = (props: IPlayerProfilesProps) => {

  const { match, loading, onSetPage, entities } = props

  const pageData: IPageData = {
    title: 'Player Profiles',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Player Profiles',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'displayName',
    order: 'asc',
  })
  const [tableFilters, setTableFilters] = useState({})

  const fetchPlayerProfiles = () => {
    // console.log('fetch player profiles', tableFilters)
    // @ts-ignore
    props.getEntities({ ...tableFilters, teamProfile: false }, paginationState.activePage - 1, paginationState.itemsPerPage, [`${paginationState.sort},${paginationState.order}`])
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters(filters)
  }

  useEffect(() => {
    // console.log('placer profile list default hook')
    onSetPage(pageData)
    fetchPlayerProfiles()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage(pageData)
    fetchPlayerProfiles()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    fetchPlayerProfiles()
  }, [paginationState, tableFilters])

  const tableColumns: ColumnsType<IPlayerProfile> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '5%',
      sorter: true,
      align: 'right',
      defaultSortOrder: 'ascend',
    }, {
      title: 'Active',
      dataIndex: 'enabled',
      align: 'center',
      render: value => <Checkbox disabled checked={value === true}/>,
    }, {
      title: 'Reg. Lock',
      dataIndex: 'blockedForRegistrations',
      align: 'center',
      render: value => <Checkbox disabled checked={value === true}/>,
    }, {
      title: 'Name',
      dataIndex: 'displayName',
      ...getColumnSearchProps('displayName'),
      sorter: true,
      render: (value, row) => `${row.lastName}, ${row.firstName}`,
    }, {
      title: 'Birthday',
      dataIndex: 'birthday',
      render: value => <TextFormat value={value} type="date" format={APP_LOCAL_DATE_FORMAT_EU} blankOnInvalid/>,
    }, {
      title: 'Sex',
      dataIndex: 'sex',
      /*
            filters: [
              { text: 'Male', value: Sex.MALE },
              { text: 'Female', value: Sex.FEMALE },
              { text: 'Divers', value: Sex.DIVERS },
            ],
            onFilter: (value, record) => {
              return record.sex === value
            },
      */
    }, {
      title: 'Category',
      dataIndex: 'playerCategoryName',
    }, {
      title: 'E-Mail',
      dataIndex: 'email',
      sorter: true,
    }, {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      render: value => <TextFormat value={value} type="date" format={APP_LOCAL_DATE_FORMAT_EU} blankOnInvalid/>,
      sorter: true,
    }, {
      title: 'Nationality',
      dataIndex: 'nationalityName',
      sorter: true,
    }, {
      title: 'Country',
      dataIndex: 'countryName',
      sorter: true,
    }, {
      title: 'User Login',
      dataIndex: 'userId',
      align: 'center',
      /*
            filters: [
              { text: 'with user login', value: true },
              { text: 'without user login', value: false },
            ],
            onFilter: (value, record) => {
              return value && !(typeof record.userId === 'undefined' || record.userId === null)
            },
      */
      render: value => <Checkbox disabled checked={value !== null}/>,
    }, {
      title: '',
      dataIndex: 'id',
      width: '10%',
      align: 'right',
      render: (value, row, index) => (
        <Space>
          <Link to={`${match.url}/${value}`}><EditOutlined/></Link>
          {/*<Link to={`${match.url}/${value}/delete`}><DeleteOutlined/></Link>*/}
        </Space>
      ),
    },
  ]

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Player Profile</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table id="mngPlayerProfilesTable"
               rowKey="id"
               size="small"
               dataSource={entities.flat()}
               columns={tableColumns}
               loading={loading}
               onChange={handleTableChanges}
               pagination={{
                 defaultPageSize: paginationState.itemsPerPage,
                 total: props.totalItems,
                 pageSize: paginationState.itemsPerPage,
               }}
        />
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ playerProfile }: IAppState) => ({
  entities: playerProfile.entities,
  loading: playerProfile.loading,
  totalItems: playerProfile.totalItems,
  entity: playerProfile.entity,
  updateSuccess: playerProfile.updateSuccess,
})

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfiles)
