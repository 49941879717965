import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from '../../../utils/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from '../action-type.util';

import { IBoardMember, defaultValue } from '../../model/board-member.model';

export const ACTION_TYPES = {
  FETCH_BOARDMEMBER_LIST: 'boardMember/FETCH_BOARDMEMBER_LIST',
  FETCH_BOARDMEMBER: 'boardMember/FETCH_BOARDMEMBER',
  CREATE_BOARDMEMBER: 'boardMember/CREATE_BOARDMEMBER',
  UPDATE_BOARDMEMBER: 'boardMember/UPDATE_BOARDMEMBER',
  DELETE_BOARDMEMBER: 'boardMember/DELETE_BOARDMEMBER',
  RESET: 'boardMember/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBoardMember>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type BoardMemberState = Readonly<typeof initialState>;

// Reducer

export default (state: BoardMemberState = initialState, action): BoardMemberState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BOARDMEMBER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BOARDMEMBER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_BOARDMEMBER):
    case REQUEST(ACTION_TYPES.UPDATE_BOARDMEMBER):
    case REQUEST(ACTION_TYPES.DELETE_BOARDMEMBER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_BOARDMEMBER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BOARDMEMBER):
    case FAILURE(ACTION_TYPES.CREATE_BOARDMEMBER):
    case FAILURE(ACTION_TYPES.UPDATE_BOARDMEMBER):
    case FAILURE(ACTION_TYPES.DELETE_BOARDMEMBER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_BOARDMEMBER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_BOARDMEMBER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_BOARDMEMBER):
    case SUCCESS(ACTION_TYPES.UPDATE_BOARDMEMBER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_BOARDMEMBER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/board-members';

// Actions

export const getEntities: ICrudGetAllAction<IBoardMember> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_BOARDMEMBER_LIST,
    payload: axios.get<IBoardMember>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IBoardMember> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BOARDMEMBER,
    payload: axios.get<IBoardMember>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IBoardMember> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BOARDMEMBER,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IBoardMember> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BOARDMEMBER,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IBoardMember> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BOARDMEMBER,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
