import axios from 'axios'
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../../../shared/reducers/action-type.util'

import { defaultValue, ITournamentMatch } from '../../../shared/model/tournament-match.model'

export const ACTION_TYPES = {
  FETCH_ANNOUNCEDMATCH_LIST: 'tournamentMatch/FETCH_ANNOUNCEDMATCH_LIST',
  FETCH_ANNOUNCEDMATCH: 'tournamentMatch/FETCH_ANNOUNCEDMATCH',
  UPDATE_ANNOUNCEDMATCH: 'tournamentMatch/UPDATE_ANNOUNCEDMATCH',
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITournamentMatch>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

export type MatchesAnnouncedState = Readonly<typeof initialState>;

// Reducer

export default (state: MatchesAnnouncedState = initialState, action): MatchesAnnouncedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ANNOUNCEDMATCH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ANNOUNCEDMATCH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      }
    case REQUEST(ACTION_TYPES.UPDATE_ANNOUNCEDMATCH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      }
    case FAILURE(ACTION_TYPES.FETCH_ANNOUNCEDMATCH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ANNOUNCEDMATCH):
    case FAILURE(ACTION_TYPES.UPDATE_ANNOUNCEDMATCH):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      }
    case SUCCESS(ACTION_TYPES.FETCH_ANNOUNCEDMATCH_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_ANNOUNCEDMATCH):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      }
    case SUCCESS(ACTION_TYPES.UPDATE_ANNOUNCEDMATCH):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      }
    default:
      return state
  }
};

const apiUrl = '/api/management/announced-matches'

// Actions
export const getEntities: ICrudGetAllAction<ITournamentMatch> = (page, size, sort, round?: number, session?: string) => {
  // console.log('sort:', sort)
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${round ? sort ? `&round=${round}` : `?round=${round}` : ''}${session ? sort || round ? `&session=${session}` : `?session=${session}` : ''}`
  return {
    type: ACTION_TYPES.FETCH_ANNOUNCEDMATCH_LIST,
    payload: axios.get<ITournamentMatch>(`${requestUrl}${requestUrl.indexOf('?') >= 0 ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}

export const getEntity: ICrudGetAction<ITournamentMatch> = id => {
  const requestUrl = `${apiUrl}/${id}`
  return {
    type: ACTION_TYPES.FETCH_ANNOUNCEDMATCH,
    payload: axios.get<ITournamentMatch>(requestUrl),
  }
}

export const updateEntity: ICrudPutAction<ITournamentMatch> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ANNOUNCEDMATCH,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  })
  dispatch(getEntities())
  return result
}
