import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../../interfaces/page-data'
import { Link, RouteComponentProps } from 'react-router-dom'
import { IAppState } from '../../../../redux/store'
import { connect } from 'react-redux'
import {
  deleteEntity,
  getEntities,
  reset
} from '../../../../shared/reducers/model/calendar-item.reducer'
import { Button, Col, Input, message, Modal, Row, Space, Table, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE } from '../../../../utils/pagination.constants'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../../../redux/settings/constants'
import { CalendarItemStatus } from '../../../../shared/model/enumerations/calendar-item-status.model'

import './calendar-item.scss'
import { ICalendarItem } from '../../../../shared/model/calendar-item.model'

export interface ICalendarItemProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const CalendarItem = (props: ICalendarItemProps) => {

  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'Sports Calendar',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Website',
        route: 'management/website',
      },
      {
        title: 'Sports Calendar',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'calendarItemStartDate',
    order: 'desc',
  })

  const [tableFilters, setTableFilters] = useState({})

  useEffect(() => {
    onSetPage(pageData)
    loadEntities()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess)
      loadEntities()
  }, [props.updateSuccess])

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const loadEntities = () => {
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    if (selectedKeys[0] === '')
      delete tableFilters[dataIndex]
    else
      tableFilters[dataIndex] = selectedKeys[0]
    setTableFilters({
      ...tableFilters,
    })
  }

  const handleReset = (clearFields, dataIndex) => {
    delete tableFilters[dataIndex]
    clearFields()
  }

  const handleDeleteEntity = (calendarItem) => {
    const confirmDeletion = Modal.confirm({
      title: `Delete ${calendarItem.calendarItemTitle}?`,
      content: (<>
        <p>Are you sure you want to delete the following calendar item?</p>
        <h6>{calendarItem.calendarItemTitle}</h6>
      </>),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => {
        props.deleteEntity(calendarItem.id)
        setTimeout(() => {
          message.info('Calender Item deleted')
          confirmDeletion.destroy()
        }, 1000)
      },
      onCancel: () => {
        confirmDeletion.destroy()
      },
    })
  }

  const handleEditEntity = (id) => {
    props.history.push(`${props.match.url}/${id}`)
  }
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${props.match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Calendar Entry</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table id='mngCalendarItemsTable'
               rowKey='id'
               size='small'
               dataSource={props.entities.flat()}
               loading={props.loading}
               onChange={handleTableChange}
               pagination={{
                 defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                 total: props.totalItems,
                 pageSize: paginationState.itemsPerPage,
               }}
        >
          <Table.Column<ICalendarItem> key={'calendarItemStartDate'} dataIndex={'calendarItemStartDate'} title={'Date'}
                                       sorter
                                       defaultSortOrder={'descend'}
                                       render={(value, row) => (
                                         <>
                                           <TextFormat type={'date'} value={value} format={APP_LOCAL_DATE_FORMAT_EU}/>
                                           &nbsp;-&nbsp;
                                           <TextFormat type={'date'} value={row.calendarItemEndDate}
                                                       format={APP_LOCAL_DATE_FORMAT_EU}/>
                                         </>
                                       )}
          />
          <Table.Column key={'calendarItemTitle'} dataIndex={'calendarItemTitle'} title={'Title'}
                        {...getColumnSearchProps('calendarItemTitle')}
          />
          <Table.Column key={'calendarItemLocation'} dataIndex={'calendarItemLocation'} title={'Location'}
                        {...getColumnSearchProps('calendarItemLocation')}
          />
          <Table.Column key={'calendarItemDisciplines'} dataIndex={'calendarItemDisciplines'} title={'Discipline'}
                        render={(value) => value.map(v => v.mnemonic).join(', ')}
          />
          <Table.Column key={'calendarItemPlayerCategories'} dataIndex={'calendarItemPlayerCategories'}
                        title={'Classification'}
                        render={(value) => value.map(v => v.mnemonic).join(', ')}
          />
          <Table.Column key={'calendarItemStatus'} dataIndex={'calendarItemStatus'} title={'Status'}
                        render={(value) => CalendarItemStatus[value]}
          />
          <Table.Column key={'actions'} dataIndex={'id'} title={''}
                        render={(value, row) => (
                          <Space>
                            <Tooltip title={'Edit Calender Item'} placement={'left'}>
                              <EditOutlined onClick={() => handleEditEntity(value)}
                                            style={{ fontSize: 20 }}
                              />
                            </Tooltip>
                            <Tooltip title={'Delete Calender Item'} placement={'left'}>
                              <DeleteOutlined onClick={() => handleDeleteEntity(row)}
                                              style={{ fontSize: 20, color: 'red' }}
                              />
                            </Tooltip>
                          </Space>
                        )}
          />
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ calendarItem }: IAppState) => ({
  entities: calendarItem.entities,
  loading: calendarItem.loading,
  totalItems: calendarItem.totalItems,
  entity: calendarItem.entity,
  updateSuccess: calendarItem.updateSuccess,
})

const mapDispatchToProps = {
  getEntities,
  reset,
  deleteEntity,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CalendarItem)
