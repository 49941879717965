import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import {
  getEntity as getTimeSessionEntity,
  createEntity as createTimeSession,
  updateEntity as updateTimeSession,
  reset,
} from '../../../shared/reducers/model/chart-template-time-session.reducer'
import { getEntities as getTimeSessionMatches } from '../../../shared/reducers/model/extended/chart-template-time-session-matches.extended.reducer'
import { Col, Form, InputNumber, message, Row, Table } from 'antd'
import { Input } from 'antd/es'
import moment from 'moment'
import './times-sessions.update..scss'

export interface ITimeSessionUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const TimeSessionUpdate = (props: ITimeSessionUpdateProps) => {

  const { match, timeSessionEntity, onSetPage, timeSessionLoading, timeSessionMatches } = props

  const [isNew, setIsNew] = useState(!match.params || !match.params.id)

  const pageData: IPageData = {
    title: `Team Profile - ${!isNew ? `Profile: ${timeSessionEntity.name} [${timeSessionEntity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Time Session Templates',
        route: 'management/time-sessions',
      },
      {
        title: !isNew ? `Time Session: ${timeSessionEntity.name}` : 'Create new Time Session Template',
      },
    ],
  }

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form
  const [submitLoading, setLoadingSubmit] = useState(false)
  const [playedMatches, setPlayedMatches] = useState(0)

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...timeSessionEntity,
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...timeSessionEntity,
          ...values,
        }
        if (isNew) {
          props.createTimeSession(entityToSubmit)
        } else {
          props.updateTimeSession(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  useEffect(() => {
    if (!isNew) {
      props.getTimeSessionEntity(match.params.id)
    } else {
      props.reset()
    }
    onSetPage(pageData)
  }, [])

  useEffect(() => {
    if (!timeSessionLoading && !isNew && timeSessionEntity?.id) {
      let formValues = {
        ...timeSessionEntity,
      }
      setFieldsValue(formValues)
      // @ts-ignore
      props.getTimeSessionMatches(0, 999, 'matchLabel', { chartTemplateTimeSessionId: match.params.id })
    }
    onSetPage({ ...pageData, loaded: true })
  }, [timeSessionLoading])

  useEffect(() => {
    let walkovers = 0, playedMatches = 0
    timeSessionMatches.forEach((tsm) => {
      if (tsm.matchStartTime)
        playedMatches += 1
    })
    setPlayedMatches(playedMatches)
  }, [timeSessionMatches])

  return <Form form={form} id={'updateTimeSession'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
               autoComplete={'off'}
               onFinish={handleFormOnFinish}
               onFinishFailed={handleFormOnFailed}
               onReset={handleFormOnReset}
  >
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
        <Form.Item name="name" label={'Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                   rules={[{ required: true, message: 'Please enter a name' }, { max: 255 }]}>
          <Input/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="numberOfParticipants" label={'Number of Participants'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                   rules={[{ required: true, message: 'Numbers of Participants' }]}>
          <InputNumber/>
        </Form.Item>
        <Form.Item name="numberOfTables" label={'Number of Tables'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                   rules={[{ required: true, message: 'Number of Tables' }]}>
          <InputNumber/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={'Matches with Start-Time'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <InputNumber value={playedMatches} readOnly={true}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Table id={'time-session-matches-table'} title={() => 'Matches'} dataSource={timeSessionMatches.flat()} pagination={false} size={'small'}
               rowClassName={(record, index) => {
                 return record.matchStartTime ? '' : 'not-played'
               }}
        >
          <Table.Column dataIndex={'matchLabel'} title={'Label'}/>
          <Table.Column dataIndex={'matchStartTime'} title={'Start-Time'} align={'center'}/>
          <Table.Column dataIndex={'tournamentDay'} title={'Event-Day'} align={'center'}/>
          <Table.Column dataIndex={'id'} title={''} render={() => ''}/>
        </Table>
      </Col>
    </Row>
  </Form>
}

const mapStateToProps = ({ chartTemplateTimeSession, chartTemplateTimeSessionMatches }: IAppState) => ({
  timeSessionEntity: chartTemplateTimeSession.entity,
  timeSessionLoading: chartTemplateTimeSession.loading,
  timeSessionMatches: chartTemplateTimeSessionMatches.entities,
})

const mapDispatchToProps = {
  getTimeSessionEntity,
  createTimeSession,
  updateTimeSession,
  reset,
  getTimeSessionMatches,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeSessionUpdate))