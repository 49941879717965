import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../redux/store'
import { Col, Form, Modal, Radio, Row } from 'antd'
import axios from 'axios'
import { saveAs } from 'file-saver'

import { getEntity, reset, updateEntity } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { ITournamentMatchExtended } from '../../shared/model/extended/tournament-match.extended.model'

export interface ITournamentFlowChartExportModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string, type: string }> {
}

const TournamentFlowChartExportModal: React.FC<ITournamentFlowChartExportModalProps> = (props: ITournamentFlowChartExportModalProps) => {

  const tournamentId = Number(props.match.params.id)
  const statusType = props.match.params.type

  const [visible, showModal] = useState(false)
  const [submitLoading, setLoadingSubmit] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    // console.log('default effect', tournamentId, props.loading)
    if (tournamentId && !props.loading)
      props.getEntity(tournamentId)
  }, [])

  useEffect(() => {
    // console.log('loading effect', props.loading)
    if (!props.loading && props.tournament) {
      form.setFieldsValue({ ...props.tournament })
      showModal(true)
    }
  }, [props.loading, props.tournament])

  const exportTypeOptions = [
    { label: 'Excel', value: 'xls' },
    { label: 'PDF', value: 'pdf', disabled: true },
  ]

  const exportGroupOptions = [
    { label: 'Full Tournament', value: 'FULL' },
    { label: 'Group A', value: 'A' },
    { label: 'Group B', value: 'B' },
    { label: 'Group C', value: 'C' },
    { label: 'Group D', value: 'D' },
    { label: 'Group E', value: 'E' },
    { label: 'Group F', value: 'F' },
    { label: 'Group G', value: 'G' },
    { label: 'Group H', value: 'H' },
    { label: 'Final round', value: 'SE' },
  ]

  const onSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        axios.get(`/api/management/tournaments/${tournamentId}/generate-excel-chart`, {
          responseType: 'blob', params: {
            group: values.exportGroup,
          },
        })
          .then((response) => {
            saveAs(response.data, `${props.tournament.name}.xls`)
          })
        // props.updateEntity({ ...props.tournament, ...values })
        setTimeout(() => {
          setLoadingSubmit(false)
          showModal(false)
          props.history.push(`/app/tournaments/${statusType}`)
          props.reset()
        }, 1000)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        setTimeout(() => {
          setLoadingSubmit(false)
        }, 1000)
      })
  }

  const onCancel = () => {
    showModal(false)
    props.history.push(`/app/tournaments/${statusType}`)
    props.reset()
  }

  const onReset = () => {
    form.resetFields()
  }

  return <Modal
    width={'60%'}
    title={`Export flow-chart for tournament: \n${props.tournament?.name}`}
    visible={visible}
    destroyOnClose={true}
    okText="Export"
    okButtonProps={{ loading: submitLoading, type: 'primary' }}
    onOk={onSubmit}
    onCancel={onCancel}
    cancelText="Cancel"
    /*
        footer={(<Space>
            <Button key='cancel' onClick={onCancel}>Cancel</Button>
            <Tooltip key='resetTT' title='Reset form to the original values'>
              <Button key='reset' htmlType='reset' form='matchForm' onClick={onReset}>Reset Form</Button>
            </Tooltip>
            <Button key='submit' htmlType='submit' form='matchForm' type='primary' onClick={onSubmit}
                    loading={submitLoading}>
              Save
            </Button>
          </Space>
        )}
    */
  >
    <Form layout={'horizontal'} form={form} preserve={false} id="matchForm" labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item name={'exportType'} initialValue={'xls'} label={'Export-Type'}>
            <Radio.Group options={exportTypeOptions} optionType={'button'} buttonStyle={'solid'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={'exportGroup'} initialValue={'FULL'} label={'Group'}>
            <Radio.Group options={exportGroupOptions} optionType={'button'} buttonStyle={'solid'}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Modal>
}

const mapStateToProps = ({ tournamentExtended }: IAppState) => ({
  tournament: tournamentExtended.entity,
  loading: tournamentExtended.loading,
})

const mapDispatchToProps = {
  getEntity, updateEntity, reset,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TournamentFlowChartExportModal))