import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { IPageProps } from '../../../interfaces/page-data'
import { connect } from 'react-redux'

import PlayerProfiles from './player-profiles'
import PlayerProfileUpdate from './player-profile-update'

export interface IPlayerProfilesProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IPlayerProfilesProps) => {
  return <>
    <Switch>
      <Route key='nCreate' exact path={`/app/management/player/profile/new`}
             render={() => (<PlayerProfileUpdate {...props}  />)}/>
      <Route key='nEdit' exact path={`/app/management/player/profile/:id`}
             render={() => (<PlayerProfileUpdate {...props}  />)}/>
      <Route key='listPage' path={`/app/management/player/profile`} render={() => <PlayerProfiles {...props}/>}/>
    </Switch>
    {/*
    <Route key='deleteDialog' exact path={`/app/management/player/profile/:id/delete`}
           component={PlayerProfileDeleteDialog}/>
*/}
  </>
}

export default withRouter(connect()(Routes))