import React, { useEffect, useRef, useState } from 'react'
import { history, IAppState } from '../../../redux/store'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IPaginationBaseState, TextFormat } from 'react-jhipster'
import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE } from '../../../utils/pagination.constants'
import { APP_DATETIME_FORMAT_EU, BACKEND_API_URL } from '../../../redux/settings/constants'

import { Col, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import { AuditOutlined, ClockCircleTwoTone, LaptopOutlined, PauseCircleTwoTone, ReloadOutlined, SoundFilled, SyncOutlined } from '@ant-design/icons'
import Button from 'antd/es/button'

import { ITournamentMatchExtended } from '../../../shared/model/extended/tournament-match.extended.model'
import { getEntities, getEntity } from '../../../shared/reducers/model/extended/tournament-match.extended.reducer'
import { getEntities as getTournaments } from '../../tournament/tournaments-running.reducer'
import { MatchStatus } from '../../../shared/model/enumerations/match-status.model'
import axios from 'axios'
import { TournamentStatus } from '../../../shared/model/enumerations/tournament-status.model'

import './Matches.scss'

export interface IMatchesRunningProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

interface IChangeTableProps {
  matchId: number,
  label: string
}

const MatchesRunning = (props: IMatchesRunningProps) => {

  const pageData: IPageData = {
    title: 'Active/Running Matches',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Matches',
      },
      {
        title: 'Active/Running',
      },
    ],
  }

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'tableDisplayNumber',
    order: 'asc',
  })

  const [tableFilters, setTableFilters] = useState({
    matchStatus: [MatchStatus.READY, MatchStatus.RUNNING, MatchStatus.TIMEOUT, MatchStatus.FINISHED],
    tournamentStatus: [TournamentStatus.RUNNING],
  })

  const autoRefreshTimer = useRef(null)

  const { onSetPage } = props
  useEffect(() => {
    onSetPage(pageData)
    // loadEntities()
    loadTournamentFilters()
    loadRoundFilters()
    loadSessionFilters()
    autoRefreshTimer.current = setInterval(loadEntities, 20000)
    return () => {
      clearTimeout(autoRefreshTimer.current)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSetPage({ ...pageData, loaded: true })
    loadEntities()
    loadTournamentFilters()
    loadRoundFilters()
    loadSessionFilters()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.loading)
      onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    loadEntities()
  }, [paginationState, tableFilters])

  const [tournamentFilters, setTournamentFilters] = useState([])
  useEffect(() => {
    if (!props.tournamentsLoading && props.tournaments && props.tournaments.length > 0)
      setTournamentFilters(props.tournaments.map(tournament => ({
        text: `${tournament.eventName}: ${tournament.name}`,
        value: tournament.id,
      })))
  }, [props.tournamentsLoading])

  const [roundFilters, setRoundFilters] = useState([])
  const [sessionFilters, setSessionFilters] = useState([])
  const loadRoundFilters = async () => {
    delete tableFilters['roundId']
    await axios
      .get(`/api/management/tournaments/open-rounds?key=active`)
      .then(({ data }) => {
          setRoundFilters(data.map(round => ({
            label: round.label,
            value: round.id,
          })))
        },
      )
  }

  const [showChangeTableModal, openChangeTableModal] = useState<IChangeTableProps>()

  const loadSessionFilters = async () => {
    delete tableFilters['sessionId']
    await axios
      .get(`/api/management/tournaments/open-sessions?key=active`)
      .then(({ data }) => {
          setSessionFilters(data.map(session => ({
            label: <TextFormat type="date" format={APP_DATETIME_FORMAT_EU} value={session}/>,
            value: session,
          })))
        },
      )
  }

  const loadEntities = () => {
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
    setConfirmToggleShotClockLoading(false)
  }

  const loadTournamentFilters = () => {
    props.getTournaments(0, 25, 'name,desc')
  }

  const [selectedMatches, setSelectedMatches] = useState([])

  const handleSelectChange = (selectedRowKeys) => {
    setSelectedMatches(selectedRowKeys)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters({
      ...tableFilters,
      ...filters,
    })
  }

  const [disabledButtons, setDisabledButtons] = useState<boolean[]>([])
  const handleApproveMatch = (tournamentMatch: ITournamentMatchExtended) => {
    setDisabledButtons({
      ...disabledButtons,
      [tournamentMatch.id]: true,
    })
    axios
      .put(`/api/management/announced-matches/approve-match`, null, {
        params: {
          id: tournamentMatch.id,
        },
      })
      .then(() => {
        loadEntities()
      })
      .catch(() => {
        setTimeout(() => {
          setDisabledButtons({
            ...disabledButtons,
            [tournamentMatch.id]: false,
          })
        }, 2000)
      })
  }

  const showMatchHistory = (matchId: number) => {
    history.push(`${props.match.url}/${matchId}/showHistory`)
  }

  const [confirmToggleShotClockLoading, setConfirmToggleShotClockLoading] = useState(false)

  const handleToggleShotClock = (tournamentMatch: ITournamentMatchExtended) => {
    setConfirmToggleShotClockLoading(true)
    axios
      .put(`/api/management/announced-matches/toggle-shotclock-for-match`, null, {
        params: {
          id: tournamentMatch.id,
        },
      })
      .then(() => {
        loadEntities()
      })
  }

  const handleRoundFilterChange = (selectedRoundId) => {
    // console.log('Round Filter Event', selectedRoundId)
    if (typeof selectedRoundId === 'number') {
      tableFilters['roundId'] = selectedRoundId
      setTableFilters({
        ...tableFilters,
      })
    } else {
      delete tableFilters['roundId']
      setTableFilters({ ...tableFilters })
    }
    loadEntities()
  }

  const handleSessionFilterChange = (selectedSession) => {
    // console.log('Session Filter Event', selectedSession)
    if (selectedSession !== undefined) {
      tableFilters['matchScheduledTime'] = selectedSession
      setTableFilters({
        ...tableFilters,
      })
    } else {
      delete tableFilters['matchScheduledTime']
      setTableFilters({ ...tableFilters })
    }
    loadEntities()
  }

  const handleRowClassName = (row: ITournamentMatchExtended) => {
    switch (row.matchStatus) {
      case MatchStatus.TIMEOUT:
      case MatchStatus.RUNNING:
        return 'PLAYTIME_' + row.playtimeStatus
      case MatchStatus.FINISHED:
        return 'FINISHED'
    }
    return ''
  }

  const showEditMatchModal = (matchId: number) => {
    history.push(`${props.match.url}/${matchId}/editMatch`)
  }

  const openScoreboard = (tableId: number) => {
    window.open(`${BACKEND_API_URL}/web/scoreboards/${tableId}/sponsors`, '_blank')
  }

  /*
    const checkTableChangeFormAndSubmit = ({ matchId, tableId }) => {
      console.log(matchId, tableId)

    }
  */

  useEffect(() => {
    if (showChangeTableModal && showChangeTableModal.matchId && showChangeTableModal.matchId > 0 && showChangeTableModal.label) {
      history.push(`${props.match.url}/${showChangeTableModal.matchId}/assignTable`)
    }
  }, [showChangeTableModal])

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24} style={{ textAlign: 'right', marginBottom: 8 }}>
        <Space>
          <Select allowClear
                  placeholder="Round Filter"
                  style={{ width: 200 }}
                  notFoundContent={'no more rounds to play'}
                  options={roundFilters}
                  onChange={handleRoundFilterChange}
                  defaultValue={null}
          />
          <Select allowClear
                  placeholder="Session Filter"
                  style={{ width: 200 }}
                  notFoundContent={'no more sessions to play'}
                  options={sessionFilters}
                  onChange={handleSessionFilterChange}
                  defaultValue={null}
          />
          <Tooltip placement="top" title="Refresh List">
            <Button icon={<ReloadOutlined style={{ fontSize: 24 }}/>} type="text" onClick={loadEntities}/>
          </Tooltip>
        </Space>
      </Col>
      <Col span={24}>
        <Table<ITournamentMatchExtended> id={'tournamentMatchesTable'} rowKey={'id'} loading={props.loading}
                                         size={'small'}
                                         showSorterTooltip
                                         dataSource={props.entities.flat()}
                                         rowSelection={{
                                           selectedRowKeys: selectedMatches,
                                           onChange: handleSelectChange,
                                         }}
                                         rowClassName={handleRowClassName}
                                         onChange={handleTableChange}
                                         pagination={{
                                           defaultPageSize: DEFAULT_ITEMS_PER_PAGE,
                                           total: props.totalItems,
                                           pageSize: paginationState.itemsPerPage,
                                         }}
        >
          <Table.Column<ITournamentMatchExtended> dataIndex={'label'} sorter title={'Label'} align={'center'}
                                                  render={(value, row) => (
                                                    <Tooltip title={'edit match'}
                                                             placement={'top'}>
                                                      <Button type={'link'}
                                                              onClick={event => showEditMatchModal(row.id)}>
                                                        {row.roundMnemonic}-{value}
                                                      </Button>
                                                    </Tooltip>
                                                  )}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'tableDisplayNumber'} sorter title={'Table'}
                                                  align={'center'}
                                                  defaultSortOrder={'ascend'}
                                                  render={(value, row) => (
                                                    <Tooltip title={'change table'}
                                                             placement={'top'}>
                                                      <Button type={'link'}
                                                              onClick={() => openChangeTableModal({ matchId: row.id, label: row.label })}>
                                                        {value}
                                                      </Button>
                                                    </Tooltip>
                                                  )}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'matchScheduledTime'} sorter
                                                  title={'Scheduled Time'}
                                                  align={'center'}
                                                  render={(value) => (
                                                    <TextFormat type={'date'} value={value}
                                                                format={APP_DATETIME_FORMAT_EU}
                                                                blankOnInvalid/>
                                                  )}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'matchStartTime'} sorter title={'Start Time'}
                                                  align={'center'}
                                                  render={(value) => value ? (
                                                    <TextFormat type="date" value={value}
                                                                format={APP_DATETIME_FORMAT_EU}/>
                                                  ) : 'not started'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'raceTo'} title={'Race To'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'participantADisplayName'} title={'Player A'} render={(value, row) => value ? (
            <span><img style={{ height: '18px' }} src={'data:' + row.participantAFlagContentType + ';base64,' + row.participantAFlagImg}/> {value}</span>
          ) : 'waiting for player'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'aScore'} title={'Score A'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'bScore'} title={'Score B'} align={'center'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'participantBDisplayName'} title={'Player B'} render={(value, row) => value ? (
            <span><img style={{ height: '18px' }} src={'data:' + row.participantBFlagContentType + ';base64,' + row.participantBFlagImg}/> {value}</span>
          ) : 'waiting for player'}/>
          <Table.Column<ITournamentMatchExtended> dataIndex={'tournamentId'} title={'Tournament'}
                                                  filters={tournamentFilters}
                                                  render={(value, row) => row.tournamentMnemonic}
          />
          <Table.Column<ITournamentMatchExtended> dataIndex={'matchStatus'} title={''}
                                                  render={(value, row) => {
                                                    let rowActionStatus
                                                    switch (value) {
                                                      case 'READY':
                                                        rowActionStatus = <Tooltip
                                                          title={'Match is assigned to the table. Waiting for start.'}>
                                                          <SoundFilled style={{
                                                            fontSize: 20,
                                                            color: 'orange',
                                                          }}/>
                                                        </Tooltip>
                                                        break
                                                      case 'RUNNING':
                                                        rowActionStatus =
                                                          <Tooltip title={'Match is running'}>
                                                            <SyncOutlined spin
                                                                          style={{
                                                                            fontSize: 20,
                                                                            color: 'darkgreen',
                                                                          }}/>
                                                          </Tooltip>
                                                        break
                                                      case 'TIMEOUT':
                                                        let players = row.aTimeoutUntil ? row.participantADisplayName : ''
                                                        players += row.bTimeoutUntil ? (row.aTimeoutUntil ? ', ' : '') + row.participantBDisplayName : ''
                                                        rowActionStatus = <Tooltip
                                                          title={`The match is time-out. Taken from: ${players}`}>
                                                          <PauseCircleTwoTone
                                                            style={{ fontSize: 20 }}/>
                                                        </Tooltip>
                                                        break
                                                      case 'FINISHED':
                                                        rowActionStatus = <Button block type="primary"
                                                                                  disabled={disabledButtons[row.id]}
                                                                                  loading={disabledButtons[row.id]}
                                                                                  onClick={() => handleApproveMatch(row)}>Approve</Button>
                                                        break
                                                      default:
                                                        rowActionStatus = value
                                                    }
                                                    return <Space>
                                                      {rowActionStatus}
                                                      {row.tableId != null ?
                                                        <Popconfirm title={'Assign/Remove Shot-Clock?'}
                                                                    okText={'YES'}
                                                                    okButtonProps={{ loading: confirmToggleShotClockLoading }}
                                                                    onConfirm={() => handleToggleShotClock(row)}
                                                                    cancelText={'NO'}
                                                        >
                                                          {row.shotclockApproved ?
                                                            <Tooltip title={'Shot-clock approved'}
                                                                     placement={'left'}>
                                                              <ClockCircleTwoTone
                                                                twoToneColor="limegreen"
                                                                style={{ fontSize: 20 }}/>
                                                            </Tooltip>
                                                            : row.shotclockAssigned ?
                                                              <Tooltip title={'Shot-clock assigned'}
                                                                       placement={'left'}>
                                                                <ClockCircleTwoTone
                                                                  twoToneColor="orange"
                                                                  style={{ fontSize: 20 }}/>
                                                              </Tooltip>
                                                              :
                                                              <Tooltip
                                                                title={'No Shot-clock - click to assign'}
                                                                placement={'left'}>
                                                                <ClockCircleTwoTone
                                                                  twoToneColor="red"
                                                                  style={{ fontSize: 20 }}
                                                                />
                                                              </Tooltip>
                                                          }
                                                        </Popconfirm>
                                                        : null}
                                                      <Tooltip title={'open match history'}
                                                               placement={'leftTop'}>
                                                        <Button type={'text'} shape={'circle'}
                                                                icon={<AuditOutlined/>}
                                                                style={{ color: 'black' }}
                                                                onClick={() => showMatchHistory(row.id)}/>
                                                      </Tooltip>
                                                      {row.tableId != null ?
                                                        <Tooltip title="Open Scoreboard">
                                                          <LaptopOutlined
                                                            onClick={() => openScoreboard(row.tableId)}
                                                            style={{ color: 'black', fontSize: 20 }}/>
                                                        </Tooltip>
                                                        : null}
                                                    </Space>
                                                  }}/>

        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ authentication, tournamentMatchExtended, tournamentsRunning }: IAppState) => ({
  loading: tournamentMatchExtended.loading,
  entities: tournamentMatchExtended.entities,
  totalItems: tournamentMatchExtended.totalItems,
  tournaments: tournamentsRunning.entities,
  tournamentsLoading: tournamentsRunning.loading,
  account: authentication.account,
})

const mapDispatchToProps = {
  getEntity,
  getEntities,
  getTournaments,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MatchesRunning)