import React from 'react'
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {IPageProps} from '../../../interfaces/page-data'
import ActiveMatches from './active-matches'
import UpcomingMatches from './upcoming-matches'
import ApprovedMatches from './archived-matches'
import AssignTable from './match-assign-table.modal'
import EditActiveMatchModal from './match-edit.active.modal'
import EditApprovedMatchModal from './match-edit.approved.modal'
import EditUpcomingMatchModal from './match-edit.upcoming.modal'
import EditUpcomingQualifierMatchModal from './match-edit.qualifier.upcoming.modal'
import MatchHistoryModal from "./match-history.modal";

export interface IMatchesIndexProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IMatchesIndexProps) => {
    
    const { match } = props
    
    return <>
        <Switch>
            <Route key='basePage' path={`/app/matches/running`} render={() => (<ActiveMatches {...props} />)} />
            <Route key='basePage' path={`/app/matches/upcoming`} render={() => (<UpcomingMatches {...props} />)} />
            <Route key='basePage' path={`/app/matches/approved`} render={() => (<ApprovedMatches {...props} />)} />
        </Switch>
        <Switch>
            <Route key='assignTable' exact path={'/app/matches/running/:id/assignTable'}
                   component={AssignTable} />
            <Route key='assignTable' exact path={'/app/matches/upcoming/:id/assignTable'}
                   component={AssignTable} />
            <Route key='editActiveMatch' exact path={'/app/matches/running/:id/editMatch'}
                   component={EditActiveMatchModal} />
            <Route key='editApprovedMatch' exact path={'/app/matches/approved/:id/editMatch'}
                   component={EditApprovedMatchModal} />
            <Route key='editUpcomingMatch' exact path={'/app/matches/upcoming/:id/editMatch'}
                   component={EditUpcomingMatchModal} />
            <Route key='editUpcomingMatchQualifier' exact path={'/app/matches/upcoming/:id/:side/editQualifierMatch'}
                   component={EditUpcomingQualifierMatchModal} />
            <Route key='matchHistory' exact path={'/app/matches/:type/:id/showHistory'} component={MatchHistoryModal} />
        </Switch>
    </>
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
