import React from 'react'
import { NavLink } from 'react-router-dom'
import { Alert, Checkbox, Form, Input } from 'antd'
import ThemeButton from '../../../ui/components/ThemeButton/TheameButton'
import { LockOutlined, UserOutlined } from '@ant-design/icons/lib'

export interface ILoginFormProps {
  loginError: boolean;
  handleLogin: Function;
}

class SingInForm extends React.Component<ILoginFormProps> {

  handleSubmit = values => {
    const { username, password, rememberMe = false } = values
    const { handleLogin } = this.props
    handleLogin(username, password, rememberMe)
  }

  render() {
    const { loginError } = this.props

    return (
      <Form style={{ width: '100%' }} onFinish={this.handleSubmit}>
        {loginError ? (
          <Form.Item>
            <Alert message="Wrong login data." type="error"/>
          </Form.Item>
        ) : null}
        <Form.Item name='username'
                   rules={[{ required: true, message: 'Please input your username!', whitespace: true }]}>
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
            placeholder='Login'
          />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}/>
        </Form.Item>
        <Form.Item name='rememberMe' valuePropName='checked'>
          <Checkbox>
            Remember me
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <div className='span d-flex justify-content-between'>
            <NavLink to='/public/forgot-password' className='link pl-sm-0'>
              Forgot password ?
            </NavLink>
            <ThemeButton htmlType='submit'>
              Sign In
            </ThemeButton>
          </div>
        </Form.Item>
      </Form>
    )
  }
}

export default SingInForm
