export enum EventStatus {
  CREATED = 'CREATED',

  ACTIVE = 'ACTIVE',

  RUNNING = 'RUNNING',

  APPROVED = 'APPROVED',

  CANCELED = 'CANCELED',

  POSTPONED = 'POSTPONED',
}
