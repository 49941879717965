import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { connect } from 'react-redux'

import { history, IAppState } from '../../../redux/store'
import { IPaginationBaseState } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'

import { Button, Col, Input, Row, Space, Table } from 'antd'
import { EditTwoTone, SearchOutlined } from '@ant-design/icons'
import { getEntities, getSearchEntities } from '../../../shared/reducers/model/hotel.reducer'
import { IHotel } from '../../../shared/model/hotel.model'
import { ISponsor } from '../../../shared/model/sponsor.model'

export interface IHotelsListProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const HotelsList = (props: IHotelsListProps) => {

  const { match, loading, onSetPage, entities } = props

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'name',
    order: 'asc',
  })
  const [tableFilters, setTableFilters] = useState({})

  const pageData: IPageData = {
    title: 'Hotels',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Hotels',
      },
    ],
  }

  useEffect(() => {
    // console.log('hotels effect []')
    onSetPage(pageData)
    fetchHotels()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('hotels effect [location]')
    onSetPage(pageData)
    fetchHotels()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('hotels effect [loading]')
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    // console.log('hotels effect [paginationState, tableFilters]')
    fetchHotels()
  }, [paginationState, tableFilters])

  const fetchHotels = () => {
    // console.log('load hotels', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters(filters)
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Hotel</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table<IHotel> id='hotelsTable' rowKey='id' loading={loading}
                       size='small'
                       onChange={handleTableChanges}
                       showSorterTooltip
                       dataSource={entities.flat()}
                       pagination={{
                         defaultPageSize: paginationState.itemsPerPage,
                         total: props.totalItems,
                         pageSize: paginationState.itemsPerPage,
                       }}
        >
          <Table.Column<IHotel> key='hotelName' dataIndex='name' title='Name'
                                {...getColumnSearchProps('hotelName')}
          />
          <Table.Column<IHotel> key='hotelShortName' dataIndex='shortName' title='ShortName'
                                {...getColumnSearchProps('hotelShortName')}
          />
          <Table.Column<IHotel> key='hotelStreet' dataIndex='street' title='Street'/>
          <Table.Column<IHotel> key='hotelPostcode' dataIndex='postcode' title='Postcode'/>
          <Table.Column<IHotel> key='hotelCity' dataIndex='city' title='City'/>
          <Table.Column<IHotel> key='hotelCountry' dataIndex='countryName' title='Country'/>
          <Table.Column<ISponsor> key='hotelActions' dataIndex='id' title=''
                                  width='100px'
                                  align='right'
                                  render={(value) => <Space>
                                    <EditTwoTone onClick={() => history.push(`${match.url}/${value}`)}/>
                                  </Space>}
          />
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ hotel }: IAppState) => ({
  entities: hotel.entities,
  loading: hotel.loading,
  totalItems: hotel.totalItems,
})

const mapDispatchToProps = {
  getEntities,
  getSearchEntities,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HotelsList))