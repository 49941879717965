export const SETTINGS = {
  boxed: false, // boolean
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#00aeff', // string
  sidebarBg2: '#0034ff', // string
  sidebarColor: '#ffffff', // string
  sidebarAccentColor: '#ffec00', // string
  sidebarAccentContrastColor: '#8b572a', // string
  sidebarOpened: false, // boolean
  topbarBg: '#ffffff', // string
  topbarColor: '#4a505c' // string
};
