import { IEvent } from './event.model';
import { HotelType } from './enumerations/hotel-type.model';

export interface IHotel {
  id?: number;
  name?: string;
  shortName?: string;
  type?: HotelType;
  street?: string;
  postcode?: string;
  city?: string;
  phone1?: string;
  phone2?: string;
  fax?: string;
  website?: string;
  email?: string;
  priceSingle?: number;
  priceDouble?: number;
  priceTriple?: number;
  rates?: any;
  enabled?: boolean;
  airport?: string;
  countryName?: string;
  countryId?: number;
  events?: IEvent[];
}

export const defaultValue: Readonly<IHotel> = {
  enabled: false,
};
