export interface IEventPoster {
  id?: number;
  name?: string;
  posterContentType?: string;
  poster?: any;
  eventId?: number;
  tournamentId?: number;
}

export const defaultValue: Readonly<IEventPoster> = {};
