export enum InvoiceStatus {
  NONE = 'NONE',

  CREATED = 'CREATED',

  SENT = 'SENT',

  CANCELLED = 'CANCELLED',

  PAID = 'PAID',
}
