import axios from 'axios'
import { ICrudGetAllExtendedAction } from '../../../../utils/table-utils'

import { IChartTemplateTimeSession } from '../../../model/chart-template-time-session.model'
import { ACTION_TYPES, apiUrl } from '../chart-template-time-session-matches.reducer'
import { IParticipant } from '../../../model/participant.model'
import { ICrudGetAllAction } from 'react-jhipster'
import { IChartTemplateTimeSessionMatches } from '../../../model/chart-template-time-session-matches.model'

export const getEntities: ICrudGetAllAction<IChartTemplateTimeSessionMatches> = (page, size, sort, filters?: {}) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
  if (filters) {
    if (filters['chartTemplateTimeSessionId']) {
      requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}chartTemplateTimeSessionId.equals=${filters['chartTemplateTimeSessionId']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_CHARTTEMPLATETIMESESSIONMATCHES_LIST,
    payload: axios.get<IChartTemplateTimeSessionMatches>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  }
}
