import { IWebsiteContent } from './website-content.model';
import { INewsPost } from './news-post.model';

export interface ITag {
  id?: number;
  name?: string;
  code?: string;
  websiteContents?: IWebsiteContent[];
  newsPosts?: INewsPost[];
}

export const defaultValue: Readonly<ITag> = {};
