export enum MatchStatus {
  NONE = 'NONE',

  CREATED = 'CREATED',

  SCHEDULED = 'SCHEDULED',

  READY = 'READY',

  RUNNING = 'RUNNING',

  TIMEOUT = 'TIMEOUT',

  FINISHED = 'FINISHED',

  APPROVED = 'APPROVED',
}
