import { deleteEntity, getEntity } from '../../../../shared/reducers/model/website-content.reducer'
import { message, Modal, Typography } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { IAppState } from '../../../../redux/store'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'

const { Paragraph, Text } = Typography

export interface IWebsiteContentDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const WebsiteContentDeleteDialog = (props: IWebsiteContentDeleteDialogProps) => {

  useEffect(() => {
    // console.log('load: ', props.match.params.id)
    props.getEntity(props.match.params.id)
  }, [])

  const handleClose = () => {
    props.history.push('/app/public/website/content')
  }

  const confirmDelete = () => {
    props.deleteEntity(props.entity.id)
    message.success('Website-Content deleted')
  }

  useEffect(() => {
    if (props.updateSuccess)
      handleClose()
  }, [props.updateSuccess])

  return (
    <Modal visible={true} title='Delete Website-Content'
           onOk={confirmDelete}
           okText='Yes, delete it'
           okType={'primary'}
           okButtonProps={{ danger: true }}
           onCancel={handleClose}
           cancelText='No, back to List'
    >
      <Paragraph>Are you sure you want to delete the following Content?</Paragraph>
      <Paragraph>
        <Text strong>
          {props.entity.title}
        </Text>
      </Paragraph>
    </Modal>
  )
}

const mapStateToProps = ({ websiteContent }: IAppState) => ({
  entity: websiteContent.entity,
  loading: websiteContent.loading,
  updateSuccess: websiteContent.updateSuccess,
})

const mapDispatchToProps = { getEntity, deleteEntity }

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteContentDeleteDialog)