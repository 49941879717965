import axios from 'axios'

import { IPersonalBalanceHistory, defaultValue } from '../../../model/personal-balance-history.model';
import { ACTION_TYPES, apiUrl } from '../personal-balance-history.reducer'
import { ICrudGetAllAction } from 'react-jhipster'

export const getPersonalBalanceHistoryForPlayerProfile: ICrudGetAllAction<IPersonalBalanceHistory> = (playerProfileId: number) => {
  let requestUrl = `${apiUrl}?playerProfileId.equals=${playerProfileId}&sort=entryDate,desc`
  return {
    type: ACTION_TYPES.FETCH_PERSONALBALANCEHISTORY_LIST,
    payload: axios.get<IPersonalBalanceHistory>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  };
};
