import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { IPageProps } from '../../../../interfaces/page-data'
import { connect } from 'react-redux'

import WebsiteContent from './website-content'
import WebsiteContentUpdate from './website-content-update'
import WebsiteContentDeleteDialog from './website-content-delete-dialog'

export interface IWebsiteContentIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: IWebsiteContentIndexProps) => {

  return <>
    <Switch>
      <Route key='nCreate' exact path={`/app/public/website/content/new`}
             render={() => (<WebsiteContentUpdate {...props}  />)}/>
      <Route key='nEdit' exact path={`/app/public/website/content/:id`}
             render={() => (<WebsiteContentUpdate {...props}  />)}/>
      <Route key='basePage' path={`/app/public/website/content`} render={() => (<WebsiteContent {...props}  />)}/>
    </Switch>
    <Route key='deleteDialog' exact path={`/app/public/website/content/:id/delete`}
           component={WebsiteContentDeleteDialog}/>
  </>
}

export default withRouter(connect()(Routes))
