import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { IPageProps } from '../../../interfaces/page-data'
import { connect } from 'react-redux'

import TeamProfiles from './team-profiles'
import TeamProfileUpdate from './team-profile-update'

export interface ITeamProfilesProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: ITeamProfilesProps) => {
  return <>
    <Switch>
      <Route key='nCreate' exact path={`/app/management/team/profile/new`}
             render={() => (<TeamProfileUpdate {...props}  />)}/>
      <Route key='nEdit' exact path={`/app/management/team/profile/:id`}
             render={() => (<TeamProfileUpdate {...props}  />)}/>
      <Route key='listPage' path={`/app/management/team/profile`} render={() => <TeamProfiles {...props}/>}/>
    </Switch>
    {/*
    <Route key='deleteDialog' exact path={`/app/management/team/profile/:id/delete`}
           component={TeamProfileDeleteDialog}/>
*/}
  </>
}

export default withRouter(connect()(Routes))