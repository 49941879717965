import { ICrudGetAllExtendedAction } from '../../../../utils/table-utils'
import { IRanking } from '../../../model/ranking.model'
import axios from 'axios'
import { ACTION_TYPES } from '../ranking.reducer'
import { apiUrl } from '../ranking.reducer'

export const getPossibleRankingSystem: ICrudGetAllExtendedAction<IRanking> = (filters: {}) => {
  let requestUrl = `${apiUrl}?extended=false`
  if (filters) {
    if (filters['numberOfParticipants'] !== undefined) {
      requestUrl += `&maxNumbersOfParticipants.greaterThanOrEqual=${filters['numberOfParticipants']}`
    }
    if (filters['rankingCategory'] !== undefined) {
      requestUrl += `&rankingCategory.equals=${filters['rankingCategory']}`
    }
  }
  return {
    type: ACTION_TYPES.FETCH_RANKING_LIST,
    payload: axios.get<IRanking>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  }

}