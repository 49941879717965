import axios from 'axios'
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster'

import { cleanEntity } from '../../../utils/entity-utils'
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util'

import { defaultValue, IWebsiteContent } from '../../model/website-content.model'

export const ACTION_TYPES = {
  SEARCH_WEBSITECONTENTS: 'websiteContent/SEARCH_WEBSITECONTENTS',
  FETCH_WEBSITECONTENT_LIST: 'websiteContent/FETCH_WEBSITECONTENT_LIST',
  FETCH_WEBSITECONTENT: 'websiteContent/FETCH_WEBSITECONTENT',
  CREATE_WEBSITECONTENT: 'websiteContent/CREATE_WEBSITECONTENT',
  UPDATE_WEBSITECONTENT: 'websiteContent/UPDATE_WEBSITECONTENT',
  DELETE_WEBSITECONTENT: 'websiteContent/DELETE_WEBSITECONTENT',
  SET_BLOB: 'websiteContent/SET_BLOB',
  RESET: 'websiteContent/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IWebsiteContent>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type WebsiteContentState = Readonly<typeof initialState>;

// Reducer

export default (state: WebsiteContentState = initialState, action): WebsiteContentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_WEBSITECONTENTS):
    case REQUEST(ACTION_TYPES.FETCH_WEBSITECONTENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_WEBSITECONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_WEBSITECONTENT):
    case REQUEST(ACTION_TYPES.UPDATE_WEBSITECONTENT):
    case REQUEST(ACTION_TYPES.DELETE_WEBSITECONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_WEBSITECONTENTS):
    case FAILURE(ACTION_TYPES.FETCH_WEBSITECONTENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_WEBSITECONTENT):
    case FAILURE(ACTION_TYPES.CREATE_WEBSITECONTENT):
    case FAILURE(ACTION_TYPES.UPDATE_WEBSITECONTENT):
    case FAILURE(ACTION_TYPES.DELETE_WEBSITECONTENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_WEBSITECONTENTS):
    case SUCCESS(ACTION_TYPES.FETCH_WEBSITECONTENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_WEBSITECONTENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_WEBSITECONTENT):
    case SUCCESS(ACTION_TYPES.UPDATE_WEBSITECONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_WEBSITECONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/website-contents';
const apiSearchUrl = 'api/_search/website-contents';

// Actions

export const getSearchEntities: ICrudSearchAction<IWebsiteContent> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_WEBSITECONTENTS,
  payload: axios.get<IWebsiteContent>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IWebsiteContent> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_WEBSITECONTENT_LIST,
    payload: axios.get<IWebsiteContent>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IWebsiteContent> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_WEBSITECONTENT,
    payload: axios.get<IWebsiteContent>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IWebsiteContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_WEBSITECONTENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IWebsiteContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_WEBSITECONTENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IWebsiteContent> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_WEBSITECONTENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
