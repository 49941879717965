import { ICountry } from './country.model';

export interface IContinent {
  id?: number;
  name?: string;
  subregion?: string;
  enabled?: boolean;
  countries?: ICountry[];
}

export const defaultValue: Readonly<IContinent> = {
  enabled: false,
};
