import { ICalendarItem } from './calendar-item.model';
import { ITournament } from './tournament.model';

export interface IPlayerCategory {
  id?: number;
  name?: string;
  mnemonic?: string;
  ageFrom?: number;
  ageTo?: number;
  calendarItems?: ICalendarItem[];
  tournaments?: ITournament[];
}

export const defaultValue: Readonly<IPlayerCategory> = {};
