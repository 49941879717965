import axios from 'axios'
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudSearchAction} from 'react-jhipster'

import {cleanEntity} from '../../../utils/entity-utils'
import {FAILURE, REQUEST, SUCCESS} from '../action-type.util'

import {defaultValue, IBilliardTable} from '../../model/billiard-table.model'

export const ACTION_TYPES = {
    SEARCH_BILLIARDTABLES: 'billiardTable/SEARCH_BILLIARDTABLES',
    FETCH_BILLIARDTABLE_LIST: 'billiardTable/FETCH_BILLIARDTABLE_LIST',
    FETCH_BILLIARDTABLE: 'billiardTable/FETCH_BILLIARDTABLE',
    CREATE_BILLIARDTABLE: 'billiardTable/CREATE_BILLIARDTABLE',
    UPDATE_BILLIARDTABLE: 'billiardTable/UPDATE_BILLIARDTABLE',
    DELETE_BILLIARDTABLE: 'billiardTable/DELETE_BILLIARDTABLE',
    RESET: 'billiardTable/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IBilliardTable>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type BilliardTableState = Readonly<typeof initialState>;

// Reducer

export default (state: BilliardTableState = initialState, action): BilliardTableState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.SEARCH_BILLIARDTABLES):
        case REQUEST(ACTION_TYPES.FETCH_BILLIARDTABLE_LIST):
        case REQUEST(ACTION_TYPES.FETCH_BILLIARDTABLE):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_BILLIARDTABLE):
        case REQUEST(ACTION_TYPES.UPDATE_BILLIARDTABLE):
        case REQUEST(ACTION_TYPES.DELETE_BILLIARDTABLE):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.SEARCH_BILLIARDTABLES):
        case FAILURE(ACTION_TYPES.FETCH_BILLIARDTABLE_LIST):
        case FAILURE(ACTION_TYPES.FETCH_BILLIARDTABLE):
        case FAILURE(ACTION_TYPES.CREATE_BILLIARDTABLE):
        case FAILURE(ACTION_TYPES.UPDATE_BILLIARDTABLE):
        case FAILURE(ACTION_TYPES.DELETE_BILLIARDTABLE):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.SEARCH_BILLIARDTABLES):
        case SUCCESS(ACTION_TYPES.FETCH_BILLIARDTABLE_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_BILLIARDTABLE):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_BILLIARDTABLE):
        case SUCCESS(ACTION_TYPES.UPDATE_BILLIARDTABLE):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_BILLIARDTABLE):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/billiard-tables';
const apiSearchUrl = 'api/_search/billiard-tables';

// Actions

export const getSearchEntities: ICrudSearchAction<IBilliardTable> = (query, page, size, sort) => ({
    type: ACTION_TYPES.SEARCH_BILLIARDTABLES,
    payload: axios.get<IBilliardTable>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IBilliardTable> = (page, size, sort, filters?: {}) => {
    let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    if (filters) {
        if (filters['tableStatus']) {
            requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}tableStatus.in=${filters['tableStatus'].join('&tableStatus.in=')}`
        }
        if (filters['eventId'] && typeof filters['eventId'] == "number") {
            requestUrl += `${requestUrl.indexOf('?') >= 0 ? '&' : '?'}eventId.equals=${filters['eventId']}`
        }
    }
    return {
        type: ACTION_TYPES.FETCH_BILLIARDTABLE_LIST,
        payload: axios.get<IBilliardTable>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
    };
};

export const getEntity: ICrudGetAction<IBilliardTable> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_BILLIARDTABLE,
        payload: axios.get<IBilliardTable>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IBilliardTable> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_BILLIARDTABLE,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<IBilliardTable> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_BILLIARDTABLE,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<IBilliardTable> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_BILLIARDTABLE,
        payload: axios.delete(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
