import React, { useEffect, useState } from 'react'
import { IAppState } from '../../redux/store'
import { connect } from 'react-redux'
import { Checkbox, Form, Input, message, Modal, Select, Table, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import { IPageData, IPageProps } from '../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { createEntity, getEntity, reset, setBlob, updateEntity } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { getEntitiesByCategory as getOverallRankings } from '../../shared/reducers/model/overall-ranking.reducer'
import { getPossibleRankingSystem } from '../../shared/reducers/model/extended/ranking.extended.reducer'
import { getSeededPlayers } from '../../shared/reducers/model/extended/participants.extended.reducer'
import { TournamentStatus } from '../../shared/model/enumerations/tournament-status.model'
import { getPossibleTimeTables } from '../../shared/reducers/model/extended/chart-template-time-session.extended.reducer'
import { OrderedListOutlined } from '@ant-design/icons'

import './tournaments-running-make-draw.modal.scss'
import { logError } from 'react-jhipster'
import axios from 'axios'
import moment from 'moment'
import { APP_DATETIME_FORMAT_EU, APP_LOCAL_DATE_FORMAT_EU } from '../../redux/settings/constants'
import { RankingCategory } from '../../shared/model/enumerations/ranking-category.model'
import { EventType } from '../../shared/model/enumerations/event-type.model'

export interface IMakeDrawModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const MakeDrawModal: React.FC<IMakeDrawModalProps> = (props: IMakeDrawModalProps) => {

  const [visible, showModal] = useState(false)
  const [submitLoading, setLoadingSubmit] = useState(false)

  const tournamentId = Number(props.match.params.id)
  const {
    tournamentEntity,
    loading,
    updating,
    overallRankings,
    seededPlayers,
    seededPlayersLoading,
  } = props
  const notFound = tournamentEntity.id !== tournamentId

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form
  const { onSetPage } = props

  const [maxNumberOfPlaceholders, setMaxNumberOfPlaceholders] = useState(0)
  const [overallRankingData, setOverallRankingData] = useState([])
  const [seedingTournamentsData, setSeedingTournamentsData] = useState()
  const [timeTables, setTimeTables] = useState([])
  const [rankings, setRankings] = useState([])
  const [numberOfSeededPlayers, setNumberOfSeededPlayers] = useState(0)

  const [forceOverrideConfirmationVisible, setForceOverrideConfirmationVisible] = useState(false)
  const [forceOverrideConfirmationCondition, setForceOverrideConfirmationCondition] = useState(true)

  useEffect(() => {
    props.getEntity(tournamentId)
    return () => {
      props.reset()
    }
  }, [])

  const pageData: IPageData = {
    title: 'Tournaments',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'matches',
      },
      { title: 'Tournament' },
      { title: tournamentEntity.name },
      { title: 'Make Draw' },
    ],
  }

  useEffect(() => {
    console.log('tournamentEntity:', tournamentEntity)
    if (!notFound && tournamentEntity && tournamentEntity.id > 0) {
      if (tournamentEntity.tournamentStatus !== TournamentStatus.CREATED) {
        message.warn('Make draw for this tournament not allowed')
        handleClose()
      }
      switch (RankingCategory[tournamentEntity.rankingCategory]) {
        case RankingCategory.EC:
        case RankingCategory.WC:
          axios.get(`api/management/tournaments-for-ec-seeding?tournamentId=${tournamentId}&page=0&size=10&sort=tournamentStartDate,desc`)
            .then(response => {
              console.log(response.data)
              setSeedingTournamentsData(response.data.map(tournament => ({
                label: `${tournament.name} ${moment(tournament.tournamentStartDate).format(APP_LOCAL_DATE_FORMAT_EU)}`,
                value: tournament.id,
              })))
            })
          break
        case RankingCategory.ETM:
        case RankingCategory.ETW:
          // @ts-ignore
          props.getOverallRankings(0, 10, 'dateOfGeneration,desc', tournamentEntity.rankingCategory)
          break
      }
      /*
            // @ts-ignore
            props.getOverallRankings(0, 10, 'dateOfGeneration,desc', tournamentEntity.rankingCategory)
      */

      refreshTimeSessionSelect()
      refreshRankingSelect()
      showModal(true)
      onSetPage(pageData)

      const maxNumberOfPlaceholders = tournamentEntity.entryLimit - tournamentEntity.numberOfParticipants
      setMaxNumberOfPlaceholders(maxNumberOfPlaceholders)
      setNumberOfSeededPlayers(tournamentEntity.numberOfPlayerSeeded)
      setFieldsValue({
        numberOfPlaceholders: 0,
        ...tournamentEntity,
      })
      console.log('TEST: ', tournamentEntity.numberOfTeamMatches == 0)
    }
  }, [tournamentEntity, loading])

  useEffect(() => {
    if (overallRankings && overallRankings.length > 0) {
      setOverallRankingData(overallRankings.map(ranking => ({
        label: `Overall Ranking created at ${moment(ranking.dateOfGeneration).format(APP_DATETIME_FORMAT_EU)}`,
        value: ranking.id,
      })))
      /*
            if (!isNumeric(tournamentEntity.overallRankingId))
              setFieldsValue({
                overallRankingId: overallRankings[0].id,
              })
      */
    } else {
      setOverallRankingData([])
    }
  }, [overallRankings])

  useEffect(() => {
    if (props.chartTemplateTimeSessions && props.chartTemplateTimeSessions.length > 0) {
      setTimeTables(props.chartTemplateTimeSessions.map(timeSession => ({
        label: timeSession.name,
        value: timeSession.id,
      })))
      setFieldsValue({
        chartTemplateTimeSessionId: props.chartTemplateTimeSessions[0].id,
      })
    } else {
      setTimeTables([])
    }
  }, [props.chartTemplateTimeSessions])

  useEffect(() => {
    if (props.rankings && props.rankings.length > 0) {
      setRankings(props.rankings.map(ranking => ({
        label: ranking.name,
        value: ranking.id,
      })))
      setFieldsValue({
        rankingId: props.rankings[0].id,
      })
    } else {
      setRankings([])
    }
  }, [props.rankings])

  const handleClose = () => {
    showModal(false)
    props.history.push('/app/tournaments/upcoming')
    props.reset()
  }

  const handleSubmit = () => {
    setLoadingSubmit(true)
    validateFields()
      .then(values => {
        axios
          .put(`/api/management/tournaments/${tournamentId}/generate-matches`, { ...values })
          .then(() => {
            setTimeout(() => {
              setLoadingSubmit(false)
              showModal(false)
              props.history.goBack()
              //props.history.push(`/app/tournaments/upcoming`)
              props.reset()
            }, 1000)
          })
          .catch(reason => {
            logError(reason)
            setTimeout(() => {
              setLoadingSubmit(false)
            }, 1000)
          })
      })
      .catch(reason => {
        setLoadingSubmit(false)
      })
  }

  const overallRankingPlayersColumns = [
    {
      title: 'Spot',
      width: '20%',
      render: (value, row, index) => index + 1,
    },
    {
      title: 'Player',
      dataIndex: 'playerProfileDisplayName',
    },
  ]

  const showSeededPlayersModal = () => {
    const seedingId = getFieldValue('seedingOverallRankingId') || getFieldValue('seedingTournamentId')
    const numberOfPlayerSeeded = getFieldValue('numberOfPlayerSeeded')
    if (seedingId > 0 && numberOfPlayerSeeded > 0) {
      props.getSeededPlayers({
        tournamentId,
        seedingId,
        numberOfSeededPlayers: numberOfPlayerSeeded,
      })
    } else {
      message.warning('No seeding source selected or number of seeded players is 0', 10)
    }
  }

  useEffect(() => {
    const seedingId = getFieldValue('seedingOverallRankingId') || getFieldValue('seedingTournamentId')
    const numberOfPlayerSeeded = getFieldValue('numberOfPlayerSeeded')
    if (seedingId > 0 && numberOfPlayerSeeded >= 0 && !seededPlayersLoading && seededPlayers)
      Modal.info({
        title: 'List of seeded Players with their spot',
        content: <Table id="makeDrawSeededPlayers"
                        size={'small'}
                        scroll={{ y: 500 }}
                        pagination={false}
                        loading={seededPlayersLoading}
                        rowKey={'id'}
                        dataSource={seededPlayers.flat()}
                        locale={{ emptyText: 'No matching players found for seeding' }}
                        columns={overallRankingPlayersColumns}/>,
        width: 550,
      })
  }, [seededPlayers, seededPlayersLoading])

  {/*<OrderedListOutlined onClick={() => showSeededPlayers(true)}/>*/
  }
  const btnShowSeededPlayers = (
    <Tooltip title="Preview seeded players">
      <OrderedListOutlined onClick={() => showSeededPlayersModal()}/>
    </Tooltip>
  )

  const refreshTimeSessionSelect = () => {
    const numberOfParticipants = tournamentEntity.numberOfParticipants
    const numberOfTables = tournamentEntity.numberOfTables | 0
    const numberOfPlaceholders = form.getFieldValue('numberOfPlaceholders') | 0
    let eventType = 'EURO_TOUR'
    if (tournamentEntity && tournamentEntity.rankingCategory) {
      console.log(tournamentEntity.rankingCategory)
      switch (RankingCategory[tournamentEntity.rankingCategory]) {
        case RankingCategory.ORA:
          eventType = 'ORA'
          break
        case RankingCategory.EC:
          eventType = 'EUROPEAN_CHAMPIONSHIP'
          break
        case RankingCategory.WC:
          eventType = 'WORLD_CHAMPIONSHIP'
          break
      }
    }
    props.getPossibleTimeTables({ numberOfParticipants, numberOfTables, numberOfPlaceholders, eventType })
  }

  const refreshRankingSelect = () => {
    const numberOfParticipants = tournamentEntity.numberOfParticipants
    const rankingCategory = tournamentEntity.rankingCategory
    props.getPossibleRankingSystem({ numberOfParticipants, rankingCategory })
  }

  const onChangeNumberOfSeeding = (value) => {
    setNumberOfSeededPlayers(value)
  }

  return (
    <>
      <Modal visible={visible}
             width={700}
             title={`Make draw for ${tournamentEntity.name} (${tournamentEntity.eventName})`}
             destroyOnClose={true}
             onCancel={handleClose}
             onOk={handleSubmit}
             okButtonProps={{ loading: submitLoading, type: 'primary' }}
      >
        <Form form={form} id="makeDrawForm" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
          <Form.Item name="id" hidden>
            <Input type={'hidden'}/>
          </Form.Item>
          <Form.Item name="numberOfParticipants" label="Participants">
            <Text>{tournamentEntity.numberOfParticipants}/{tournamentEntity.entryLimit}</Text>
          </Form.Item>
          <Form.Item name="chartTemplateId" label="Tournament system">
            <Text>{tournamentEntity.chartTemplateName}</Text>
          </Form.Item>
          {RankingCategory[tournamentEntity.rankingCategory] !== RankingCategory.EC ?
            <Form.Item name="seedingOverallRankingId" label="Seeding overall ranking" required rules={[{
              required: numberOfSeededPlayers > 0,
            }]}>
              <Select options={overallRankingData}/>
            </Form.Item>
            :
            <Form.Item name="seedingTournamentId" label="Seeding from Tournament" required={numberOfSeededPlayers > 0 && tournamentEntity.numberOfTeamMatches == 0} rules={[{
              required: numberOfSeededPlayers > 0 && tournamentEntity.numberOfTeamMatches == 0,
            }]}>
              <Select options={seedingTournamentsData}/>
            </Form.Item>
          }
          <Form.Item name="chartTemplateTimeSessionId" label="Time schedule" required rules={[{
            required: true,
          }]}>
            <Select options={timeTables} placeholder="Please select a times table"/>
          </Form.Item>
          {RankingCategory[tournamentEntity.rankingCategory] !== RankingCategory.EC ?
            <Form.Item name="rankingId" label="Point system" required rules={[{
              required: true,
            }]}>
              <Select options={rankings} placeholder="Please select a point system"/>
            </Form.Item>
            : null
          }
          <Form.Item name="numberOfPlayerSeeded" label="Players seeded" required
                     initialValue={tournamentEntity.numberOfPlayerSeeded}
                     getValueFromEvent={e => {
                       const convertedValue = Number(e.currentTarget.value)
                       if (isNaN(convertedValue)) {
                         return Number(form.getFieldValue('numberOfPlayerSeeded'))
                       } else {
                         return convertedValue
                       }
                     }}
                     rules={[
                       { type: 'number', min: 0, max: tournamentEntity.entryLimit, required: true },
                     ]}
          >
            <Input type="number" addonAfter={btnShowSeededPlayers} onChange={onChangeNumberOfSeeding}/>
          </Form.Item>
          <Form.Item name="numberOfPlaceholders" label="Qualifier/Placeholder" required={tournamentEntity.numberOfTeamMatches == 0}
                     getValueFromEvent={e => {
                       const convertedValue = Number(e.currentTarget.value)
                       if (isNaN(convertedValue)) {
                         return Number(form.getFieldValue('numberOfPlaceholders'))
                       } else {
                         return convertedValue
                       }
                     }}
                     rules={[
                       {
                         type: 'number',
                         min: 0,
                         max: tournamentEntity.numberOfTeamMatches == 0 ? maxNumberOfPlaceholders : 0,
                       }, {
                         required: tournamentEntity.numberOfTeamMatches == 0,
                       },
                     ]}
          >
            <Input type="number" onChange={refreshTimeSessionSelect}/>
          </Form.Item>
          <Form.Item name="confirmation" valuePropName="checked" wrapperCol={{ span: 24 }}
                     rules={[{ required: true, message: 'Please confirm' }]}
                     style={{ textAlign: 'center' }}
          >
            <Checkbox>Yes, I want to make the draw. This cannot be undone.</Checkbox>
          </Form.Item>
          <Form.Item name={'forceOverride'} hidden initialValue={false}>
            <input type={'hidden'}/>
          </Form.Item>
          {/*
          <Form.Item name="forceOverride" valuePropName="checked" wrapperCol={{ span: 24 }}
                     initialValue={false}
                     style={{ textAlign: 'center' }}
          >
            <Checkbox style={{ color: 'red' }}>
              Overwrite previous draw. All matches will be deleted.
            </Checkbox>
          </Form.Item>
        */}
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  overallRankings: storeState.overallRanking.entities,
  chartTemplateTimeSessions: storeState.chartTemplateTimeSession.entities,
  chartTemplateTimeSessionsLoading: storeState.chartTemplateTimeSession.loading,
  seededPlayers: storeState.participant.entities,
  seededPlayersLoading: storeState.participant.loading,
  rankings: storeState.ranking.entities,
  rankingsLoading: storeState.ranking.loading,
  tournamentEntity: storeState.tournamentExtended.entity,
  loading: storeState.tournamentExtended.loading,
  updating: storeState.tournamentExtended.updating,
  updateSuccess: storeState.tournamentExtended.updateSuccess,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
  getOverallRankings,
  getSeededPlayers,
  getPossibleTimeTables,
  getPossibleRankingSystem,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MakeDrawModal))
