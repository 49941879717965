export interface IInvoiceItem {
  id?: number;
  title?: string;
  description?: any;
  amount?: number;
  price?: number;
  sorting?: number;
  invoiceInvoiceNumber?: string;
  invoiceId?: number;
}

export const defaultValue: Readonly<IInvoiceItem> = {};
