import { ITournamentMatch } from '../tournament-match.model'
import { PlaytimeStatus } from './playtime-status.model'

export interface ITournamentMatchExtended extends ITournamentMatch {
  playtime?: any;
  playtimeStatus?: PlaytimeStatus;
  roundName?: string;
  roundMnemonic?: string;
  disciplineMnemonic?: string;
  participantAFlagImg?: string;
  participantAFlagContentType?: string;
  participantBFlagImg?: string;
  participantBFlagContentType?: string;
  tournamentMnemonic?: string 
}

export const defaultValue: Readonly<ITournamentMatchExtended> = {
  playtimeStatus: PlaytimeStatus.NORMAL,
}