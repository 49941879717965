import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { IPageProps } from '../../../interfaces/page-data'
import SponsorsList from './sponsors'
import SponsorUpdate from './sponsor-update'

export interface ISponsorsIndexProps extends IPageProps, RouteComponentProps<{ url: string }> {
}

const Routes = (props: ISponsorsIndexProps) => {

  return <>
    <Switch>
      <Route key='sUpdate' path={'/app/management/sponsors/new'} render={() => (<SponsorUpdate {...props}/>)}/>
      <Route key='sUpdate' path={`/app/management/sponsors/:id`} render={() => (<SponsorUpdate {...props}/>)}/>
      <Route key='sList' path={'/app/management/sponsors'} render={() => (<SponsorsList {...props}/>)}/>
    </Switch>
  </>
}
export default withRouter(connect()(Routes))
