import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { connect } from 'react-redux'

import { history, IAppState } from '../../../redux/store'
import { IPaginationBaseState } from 'react-jhipster'
import { ITEMS_PER_PAGE } from '../../../utils/pagination.constants'

import { Button, Col, Input, Row, Space, Table } from 'antd'
import { EditTwoTone, SearchOutlined } from '@ant-design/icons'
import { getEntities, getSearchEntities } from '../../../shared/reducers/model/venue.reducer'
import { IVenue } from '../../../shared/model/venue.model'
import { ISponsor } from '../../../shared/model/sponsor.model'

export interface IVenuesListProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const VenuesList = (props: IVenuesListProps) => {

  const { match, loading, onSetPage, entities } = props

  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: ITEMS_PER_PAGE,
    activePage: 1,
    sort: 'name',
    order: 'asc',
  })
  const [tableFilters, setTableFilters] = useState({})

  const pageData: IPageData = {
    title: 'Venues',
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Venues',
      },
    ],
  }

  useEffect(() => {
    // console.log('venues effect []')
    onSetPage(pageData)
    fetchVenues()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('venues effect [location]')
    onSetPage(pageData)
    fetchVenues()
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('venues effect [loading]')
    if (!loading)
      onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    // console.log('venues effect [paginationState, tableFilters]')
    fetchVenues()
  }, [paginationState, tableFilters])

  const fetchVenues = () => {
    // console.log('load venues', tableFilters)
    // @ts-ignore
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, tableFilters)
  }

  let searchInput
  const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={node => {
            searchInput = node
          }}
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size='small'
                    style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
    }
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log('handleSearch', selectedKeys)
    confirm()
    setTableFilters({
      ...tableFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = clearFields => {
    clearFields()
  }

  const handleTableChanges = (pagination, filters, sorter) => {
    // console.log('handleTableChanges:', pagination, filters, sorter)
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      sort: sorter?.order !== undefined ? sorter.field : '',
      order: sorter?.order !== undefined ? sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null : '',
    })
    setTableFilters(filters)
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <Link to={`${match.url}/new`} style={{ float: 'right' }}>
          <Button type={'primary'}>Create new Venue</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Table<IVenue> id='venuesTable' rowKey='id' loading={loading}
                       size='small'
                       onChange={handleTableChanges}
                       showSorterTooltip
                       dataSource={entities.flat()}
                       pagination={{
                         defaultPageSize: paginationState.itemsPerPage,
                         total: props.totalItems,
                         pageSize: paginationState.itemsPerPage,
                       }}
        >
          <Table.Column<IVenue> key='venueName' dataIndex='name' title='Venue-Name'
                                {...getColumnSearchProps('venueName')}
          />
          <Table.Column<IVenue> key='venueStreet' dataIndex='street' title='Street'/>
          <Table.Column<IVenue> key='venuePostcode' dataIndex='postcode' title='Postcode'/>
          <Table.Column<IVenue> key='venueCity' dataIndex='city' title='City'/>
          <Table.Column<IVenue> key='venueCountry' dataIndex='countryName' title='Country'/>
          <Table.Column<ISponsor> key='venueActions' dataIndex='id' title=''
                                  width='100px'
                                  align='right'
                                  render={(value) => <Space>
                                    <EditTwoTone onClick={() => history.push(`${match.url}/${value}`)}/>
                                  </Space>}
          />
        </Table>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ venue }: IAppState) => ({
  entities: venue.entities,
  loading: venue.loading,
  totalItems: venue.totalItems,
})

const mapDispatchToProps = {
  getEntities,
  getSearchEntities,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VenuesList))